// GoogleLogin.jsx

import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { ROUTES } from "../../../router/routes";
import { useDispatch } from 'react-redux';
import { parseToken } from "../../../api/tokenValidation"
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import LinearProgress from '@mui/material/LinearProgress';

export const GoogleLogin = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        checkCurrentSession();
    }, []);


    const postData = async (action, token) => {
        try {
            const response = await fetch(process.env.REACT_APP_API_URL, {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(action),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok' + response.statusText);
            }
            return await response.json();
        } catch (error) {
            console.error("There was an error with the fetch call:", error);
        }
        return { postData };
    };


    const checkCurrentSession = async () => {
        try {
            const session = await Auth.currentSession();
            const idToken = session.getIdToken().getJwtToken();
            const accessToken = session.getAccessToken().getJwtToken();
            const refreshToken = session.getRefreshToken().getToken();

            const userInfo = parseToken(idToken);


            //check to see if user's email is already registered; if it is, authenticate as that existing account
            const action = {
                "action": "getObjects",
                "attributeName": "userName",
                "attributeValue": userInfo.email,
                "class": "scoop.user.User"
            };
            const { objects: result } = await postData(action, idToken);

            const wasRecordFound = result.length === 1 ? true : false;


            if (wasRecordFound) {
                userInfo.userID = result[0].userID;
                userInfo.firstName = result[0].firstName;
            }


            dispatch({
                type: 'SET_AUTHENTICATED',
                isAuthenticated: true,
                user: {
                    signInUserSession: {
                        idToken: { jwtToken: idToken },
                        refreshToken: { token: refreshToken },
                    },
                    username: userInfo.userID,
                    userFirstName: userInfo.firstName,
                    attributes: {
                        email: userInfo.email,
                    },
                },
            });

            // record user login in db
            const body = {
                "action": "login",
                "userID": userInfo.userID,
                "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
            };
            fetch('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/ui_information', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${idToken}`
                },
                body: JSON.stringify(body),
            })
                .then(response => {
                    if (!response.ok) {
                        throw new Error('Network response was not ok');
                    }
                    return response.json(); // Or handle the response in the way you need if you care about it
                })
                .catch(error => {
                    console.error('Error recording login', error);
                });

            // check if user exists in the db, otherwise create the user
            if (!wasRecordFound) {
                setIsLoading(true);
                provisionNewUser(userInfo.email, userInfo.userID, userInfo.firstName, userInfo.lastName);
            } else {
                navigate(ROUTES.CANVAS_DASHBOARD);
            }


        } catch (err) {
            console.error("Session check error:", err);
            localStorage.removeItem('isGoogleFlow');
            // Handle additional error logic if needed
        }
    };

    const provisionNewUser = async (email, username, firstName, lastName) => {

        const body = {
            userName: email,
            userID: username,
            firstName: firstName,
            lastName: lastName,
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev'
        };

        try {
            const response = await fetch('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/provisionnewuser', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json"
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(body),
            });

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            // Handle the response here (optional, depending on what the API returns)
            const data = await response.json();

            navigate(ROUTES.CANVAS_DASHBOARD);
        } catch (error) {
            console.error("There was an error with the fetch call:", error);
        }
    }

    return (
        isLoading && (
            <Dialog open={isLoading}>
                <DialogContent>
                    <DialogContentText>
                        Please wait while we configure Scoop for you
                    </DialogContentText>
                    <LinearProgress />
                </DialogContent>
            </Dialog>
        )
    );

};


