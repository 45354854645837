import {useState} from 'react';
import {confirmSignUp, login, signUp} from "../../../../api/auth";
import {useDispatch} from "react-redux";
import {setAuthenticated} from "../../../../store/actions/authActions";

const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-“!@#%&\/,><\’:;|_~`])\S{8,99}$/;

function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        const r = Math.random() * 16 | 0;
        const v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}

const useSignUp = (step, setStep, setShowSnackbar, setIsLogin) => {

    const dispatch = useDispatch();

    const [signUpUser, setSignUpUser] = useState({fullName: '', username: '', email: '', password: ''});
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const handleSignUpInputChange = (fieldName, value) => {
        setSignUpUser(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
    };

    const resetSignUpForm = () => {
        setSignUpUser({fullName: '', email: '', password: ''});
    };

    const validateSignUp = () => {
        if (!emailRegex.test(signUpUser.email)) {
            setError("Please enter a valid email address.");
            return false;
        }
        if (!passwordRegex.test(signUpUser.password)) {
            setError("Password must be at least 8 characters long, and have a capital letter, a lowercase letter, a number and a special character.");
            return false;
        }
        if (signUpUser.fullName.length < 3) {
            setError("Full name must be at least 3 characters long.");
            return false;
        }
        setError(null);
        return true;
    };

    const handleSignUp = () => {
        signUpUser.username = generateUUID();
        if (validateSignUp()) {
            setIsLoading(true);
            signUp({
                username: signUpUser.username,
                email: signUpUser.email,
                password: signUpUser.password
            }).then(() => {
                setStep(step + 1);
            }).catch((error) => {
                setError(error.message);
                setShowSnackbar(error.message, "error");
            }).finally(() => {
                setIsLoading(false);
            })
        } else {
            setShowSnackbar({msg: error, severity: "error"})
        }
    }

    const handleConfirmSignup = (verificationCode) => {
        setIsLoading(true);
        confirmSignUp({
            username: signUpUser.username,
            code: verificationCode,
        })
            .then(async (res) => {
                setShowSnackbar({
                    msg: "Account confirmed! Please wait a few moment while we provision a space for you.",
                    severity: "success"
                });
                // TO-DO: uncomment this!!!!
                await provisionNewUser();
            })
            .catch((error) => {
                setShowSnackbar({msg: error.message, severity: 'error'});
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getFirstName = () => { return signUpUser.fullName.split(' ')[0] }

    const getLastName = () => { return signUpUser.fullName.split(' ').slice(1).join(' ') }

    const provisionNewUser = async () => {
        setIsLoading(true);
        const body = {
            userName: signUpUser.email,
            userID: signUpUser.username,
            firstName: getFirstName(),
            lastName: getLastName(),
            isDev: process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        try {
            const response = await fetch('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/provisionnewuser', {
                method: "POST",
                mode: "cors",
                cache: "no-cache",
                credentials: "same-origin",
                headers: {
                    "Content-Type": "application/json"
                },
                redirect: "follow",
                referrerPolicy: "no-referrer",
                body: JSON.stringify(body),
            });
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
        } catch (error) {
            console.error("There was an error with the fetch call:", error);
        } finally {
            setIsLoading(false);
            setIsLogin(true);
            localStorage.setItem('redirectTour', 'true')
            handleGoToCanvas();
        }
    }

    const handleGoToCanvas = () => {
        setIsLoading(true);
        login({
            username: signUpUser.email,
            password: signUpUser.password
        }).then((res) => {
            dispatch(setAuthenticated(true, res));
        }).catch((error) => {
            setError(error.message);
            setShowSnackbar({msg: error.message, severity: "error"});
        }).finally(() => {
            setIsLoading(false);
            setShowSnackbar({msg: "Loading new Workspace...", severity: "success"});
        })
    }

    return {
        signUpUser,
        handleSignUpInputChange,
        handleSignUp,
        isSignUpLoading: isLoading,
        handleConfirmSignup,
        handleGoToCanvas
    };
};

export default useSignUp;
