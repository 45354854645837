import React from "react";
import './AuthLayout.css';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {ROUTES} from "../../../router/routes";
import {Box} from "@mui/material";
import NavBar from "../../NavBar/NavBar";

export const AuthLayout = () => {

    const location = useLocation();
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const workspaceName = useSelector((state) => state.auth.workspaceName);

    const queryParams = new URLSearchParams(window.location.search);
    const inviteCode = queryParams.get('invite');

    if (location.pathname === '/' && (isAuthenticated || inviteCode)) {
        return <Navigate to={ROUTES.CANVAS_DASHBOARD} />
    }

    return (
        isAuthenticated || inviteCode?
            <Box className={'app-container'}>
                <NavBar workspaceName={workspaceName} />
                <Outlet />
            </Box> :
            <Navigate to={ROUTES.LOGIN} />
    )
}
