import {Box} from "@mui/material";
import ScoopLogo from "../../../assets/logo/NewLogo.svg";
import Typography from "@mui/material/Typography";
import PlusIconRed from "../../../assets/icons/PlusRed.svg";
import ShapesRed from "../../../assets/icons/ShapesRed.svg";

export const CreateWorkspace = ({ selectOption }) => {

    const handleOptionClick = (option) => {
        if (option === 0) localStorage.setItem('redirectTour', 'true');
        selectOption(option);
    }

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            gap: '64px'
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                gap: '32px',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <img src={ScoopLogo} alt="Scoop Logo" style={{width: '61px', height: '33px'}}/>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Typography sx={{
                        fontStyle: 'normal',
                        fontFamily: 'Inter',
                        color: '#201024',
                        textAlign: 'center',
                        fontSize: '32px',
                        fontWeight: 400,
                        lineHeight: '35.2px',
                        letterSpacing: '-0.64px'
                    }}>
                        Great, how would you like to begin?
                    </Typography>
                    {/*                        <Typography sx={{
                            color: '#635566',
                            textAlign: 'center',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '19.6px',
                            letterSpacing: '-0.28px'
                        }}>
                            Workspaces are where your Datasets, Canvases, Live Worksheets and more live.<br/>
                            You can invite your team to collaborate in a Workspace too.
                        </Typography>*/}
                </Box>
            </Box>
            <Box sx={{display: 'flex', gap: '24px', alignItems: 'center', justifyContent: 'center'}}>
                <WorkspaceBox
                    onClick={() => handleOptionClick(0)}
                    icon={PlusIconRed}
                    title={'Start working freely'}
                    subtitle={'Create a blank Workspace and explore your data in your own way'}
                />
                <WorkspaceBox
                    onClick={() => handleOptionClick(1)}
                    icon={ShapesRed}
                    title={'Start with a recipe'}
                    subtitle={'Get inspired with one of our recipes. Simply connect your data and see it come to life in pre-made canvases'}
                />
            </Box>
        </Box>
    )

}

const WorkspaceBox = ({icon, title, subtitle, onClick, selectOption, option}) => {

    return (
        <Box onClick={onClick}
             sx={{
                 cursor: 'pointer',
                 minHeight: '240px',
                 display: 'flex',
                 width: '367px',
                 padding: '48px',
                 flexDirection: 'column',
                 justifyContent: 'center',
                 alignItems: 'center',
                 gap: '16px',
                 borderRadius: '5px',
                 border: '1px solid #E6E4E6',
             }}>
            <img alt={'plus-icon'} src={icon}/>
            <Typography sx={{
                color: '#2B1630',
                textAlign: 'center',
                fontSize: '20px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '22px',
                fontFamily: 'Inter',
                letterSpacing: '-0.4px'
            }}>
                {title}
            </Typography>
            <Typography sx={{
                flex: 1,
                color: '#635566',
                textAlign: 'center',
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: '19.6px',
                letterSpacing: '-0.28px'
            }}>
                {subtitle}
            </Typography>
        </Box>
    )

}
