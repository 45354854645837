import {cloneDeep, merge} from "lodash";
import {
    AXIS_TEXT_DEFAULT_VALUES,
    DEFAULT_CHART_PREFERENCES,
    LEGEND_DEFAULT_VALUES,
    TITLE_DEFAULT_VALUES
} from "../Style/styleConsts";

export const fixScalingProps = (config) => {
    const overrides = merge(cloneDeep(config.styleOverrides), DEFAULT_CHART_PREFERENCES)
    if (!overrides.title.textStyle.fontSize) {
        overrides.title.textStyle.fontSize = TITLE_DEFAULT_VALUES.textStyle.fontSize
        overrides.title.textStyle.fontScaleFactor = TITLE_DEFAULT_VALUES.textStyle.fontScaleFactor
    }
    if (!overrides.legend.textStyle.fontSize) {
        overrides.legend.textStyle.fontSize = LEGEND_DEFAULT_VALUES.textStyle.fontSize
        overrides.legend.textStyle.fontScaleFactor = LEGEND_DEFAULT_VALUES.textStyle.fontScaleFactor
    }
    ['xAxis', 'yAxis'].forEach(ax => {
        if (Array.isArray(overrides[ax])) {
            overrides[ax].forEach(axis => {
                if (!axis.axisLabel.fontSize) {
                    axis.axisLabel.fontSize = AXIS_TEXT_DEFAULT_VALUES.fontSize
                    axis.axisLabel.fontScaleFactor = AXIS_TEXT_DEFAULT_VALUES.fontScaleFactor
                }
                if (!axis.nameTextStyle.fontSize) {
                    axis.nameTextStyle.fontSize = AXIS_TEXT_DEFAULT_VALUES.fontSize
                    axis.nameTextStyle.fontScaleFactor = AXIS_TEXT_DEFAULT_VALUES.fontScaleFactor
                }
            })
        } else {
            if (!overrides[ax].axisLabel.fontSize) {
                overrides[ax].axisLabel.fontSize = AXIS_TEXT_DEFAULT_VALUES.fontSize
                overrides[ax].axisLabel.fontScaleFactor = AXIS_TEXT_DEFAULT_VALUES.fontScaleFactor
            }
            if (!overrides[ax]?.nameTextStyle?.fontSize) {
                overrides[ax].nameTextStyle.fontSize = AXIS_TEXT_DEFAULT_VALUES.fontSize
                overrides[ax].nameTextStyle.fontScaleFactor = AXIS_TEXT_DEFAULT_VALUES.fontScaleFactor
            }
        }
    })
    return overrides
}
