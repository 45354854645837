import {useEffect} from "react";
import {Box, Typography} from "@mui/material";
import Button from "../../../common/Button/Button";
import {DataGrid} from "../../../common/DataGrid/DataGrid";
import {ScoopLoader} from "../../../common/Spinner/ScoopLoader";
import React from "react";

export const PreviewTab = ({
                               value,
                               server,
                               inboxID,
                               singleQuery,
}) => {
    const [previewData, setPreviewData] = React.useState(null);
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        updatePreviewData();
    }, [value]);

    const updatePreviewData = () => {
        if (!server) return;
        setLoading(true);
        if (value === 4) {
            server.sheetPostData({
                "action": "getCFWPreviewData",
                "inboxID": inboxID
            }, processPreviewData);
        } else {
            server.sheetPostData({
                "action": "getCSWPreviewData",
                "inboxID": inboxID,
                "singleQuery": singleQuery,
            }, processPreviewData);
        }
    }

    const processPreviewData = (results) => {
        if (results && results.rows) {
            setPreviewData(results);
        } else {
            setPreviewData(null);
        }
        setLoading(false);
    }

    return (
        <Box sx={{
            mb: 1,
            color: 'text.secondary',
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            height: '65vh',
            gap: '16px',
            flexDirection: 'column'
        }}>
            {
                value === 4 &&
                    <Button
                        className={'button-purple small'}
                        onClick={updatePreviewData}
                    >
                        Update Preview
                    </Button>
            }
            <Box sx={{display: 'flex', flexDirection: 'column', width: '100%', height: 'calc(65vh - 51px)'}}>
                {previewData && !loading &&
                    <Box sx={{height: '100%'}}>
                        <DataGrid
                            rowHeight={20}
                            rows={previewData.rows}
                            columns={previewData.columns}
                            disableColumnMenu={true}
                            pagination={false}
                            hideFooter
                            sx={{
                                height: '100%',
                                color: "text.secondary",
                                '& div.MuiListItemIcon-root': {color: "#000"},
                            }}
                        />
                    </Box>
                }
                {loading &&
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                        <ScoopLoader size={72} />
                    </Box>
                }
                {!previewData && !loading &&
                    <Box sx={{display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center', height: '100%'}}>
                        <Typography sx={{color: 'text.secondary', fontSize: '14px'}}>
                            No preview data available
                        </Typography>
                    </Box>
                }
            </Box>
        </Box>
    )

}
