import {Box, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {Switch} from "../../../../../common/Switch/Switch";
import {propExistsOnObject, renderColorPicker, renderSelector} from "../utils";
import {FONT_FAMILIES, FONT_WEIGHTS, GAUGE_DEFAULT_VALUES, PIE_ORIENTATIONS} from "../styleConsts";
import {Slider} from "../../../../../common/Slider/Slider";
import {debounce, cloneDeep} from "lodash";
import Input from "../../../../../common/Input/Input";

export const GaugeConfig = ({config, setConfig, chartState, chartPreferences}) => {

    const [selectedValue, setSelectedValue] = useState(
        config.styleOverrides?.gauge?.data?.[0]?.name
        || chartState?.categoryAxisValues?.[0]
        || null
    );
    const [valueFontSize, setValueFontSize] = useState(GAUGE_DEFAULT_VALUES.detail.fontSize)
    const [valueLeft, setValueLeft] = useState(GAUGE_DEFAULT_VALUES.detail.offsetCenter[0])
    const [valueTop, setValueTop] = useState(GAUGE_DEFAULT_VALUES.detail.offsetCenter[1])

    const [labelFontSize, setLabelFontSize] = useState(GAUGE_DEFAULT_VALUES.title.fontSize)
    const [labelLeft, setLabelLeft] = useState(GAUGE_DEFAULT_VALUES.title.offsetCenter[0])
    const [labelTop, setLabelTop] = useState(GAUGE_DEFAULT_VALUES.title.offsetCenter[1])
    const [axisLineWidth, setAxisLineWidth] = useState(GAUGE_DEFAULT_VALUES.axisLine.lineStyle.width)
    const [splitNumber, setSplitNumber] = useState(GAUGE_DEFAULT_VALUES.axisTick.splitNumber)
    const [tickSplitNumber, setTickSplitNumber] = useState(GAUGE_DEFAULT_VALUES.axisTick.splitNumber)
    const [axisTickDistance, setAxisTickDistance] = useState(GAUGE_DEFAULT_VALUES.axisTick.distance)
    const [axisTickWidth, setAxisTickWidth] = useState(GAUGE_DEFAULT_VALUES.axisTick.lineStyle.width)
    const [splitLineLength, setSplitLineLength] = useState(GAUGE_DEFAULT_VALUES.splitLine.length)
    const [splitLineWidth, setSplitLineWidth] = useState(GAUGE_DEFAULT_VALUES.splitLine.lineStyle.width)

    useEffect(() => {
        setSplitNumber(getRootConfig('splitNumber'))
        setAxisLineWidth(getLineStyle('width'))
        setValueFontSize(getDetailConfig('fontSize'))
        setValueLeft(getDetailConfig('offsetCenter')[0])
        setValueTop(getDetailConfig('offsetCenter')[1])
        setLabelFontSize(getLabelConfig('fontSize'))
        setLabelLeft(getLabelConfig('offsetCenter')[0])
        setLabelTop(getLabelConfig('offsetCenter')[1])
        setTickSplitNumber(getAxisTickConfig('splitNumber'))
        setAxisTickDistance(getAxisTickConfig('distance'))
        setAxisTickWidth(getAxisTickConfig('width', true))
        setSplitLineLength(getSplitLineConfig('length'))
        setSplitLineWidth(getSplitLineConfig('width', true))
/*
        */
    }, [config.styleOverrides.gauge]);

    const debouncedFunc = useCallback(debounce((target, prop, value) => {
        const newConfig = cloneDeep(config);
        if (target) {
            newConfig.styleOverrides.gauge[target][prop] = value;
        } else {
            newConfig.styleOverrides.gauge[prop] = value
        }
        setConfig(newConfig);
    }, 200), [config]);

    const debouncedLineStyleFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.gauge.axisLine.lineStyle[prop] = value;
        setConfig(newConfig);
    }, 200), [config]);

    const debouncedAxisTickFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.gauge.axisTick.lineStyle[prop] = value;
        setConfig(newConfig);
    }, 200), [config]);

    const debouncedSplitLineFunc = useCallback(debounce((prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.gauge.splitLine.lineStyle[prop] = value;
        setConfig(newConfig);
    }, 200), [config]);

    const getNewNumericValue = (value) => {
        const index = chartState?.categoryAxisValues?.findIndex(v => v === value)
        if (!index) return 0;
        const data = chartState?.series[0].data;
        return parseFloat((data[index] * 100 / data.reduce((a, b) => a + b, 0)).toFixed(3));
    }

    const handleValueChange = (value) => {
        setSelectedValue(value)
        const newConfig = cloneDeep(config)
        if (!newConfig.styleOverrides.gauge.data || newConfig.styleOverrides.gauge.data.length === 0) {
            newConfig.styleOverrides.gauge.data = [{name: '', value: 0}]
        }
        newConfig.styleOverrides.gauge.data[0].value = getNewNumericValue(value)
        newConfig.styleOverrides.gauge.data[0].name = value
        console.log(typeof getNewNumericValue(value))
        console.log(newConfig.styleOverrides.gauge.data)
        setConfig(newConfig)
    }
    console.log(config.styleOverrides.gauge.data)
    const setProgressConfig = (prop, value) => {
        const newConfig = cloneDeep(config)
        if (prop === 'color') {
            newConfig.styleOverrides.gauge.progress.itemStyle[prop] = value
        }
        else {
            newConfig.styleOverrides.gauge.progress[prop] = value
        }
        setConfig(newConfig)
    }

    const getRootConfig = (prop) => {
        if (propExistsOnObject(config.styleOverrides.gauge, prop)) return config.styleOverrides.gauge[prop]
        if (propExistsOnObject(chartPreferences.gauge, prop)) return chartPreferences.gauge[prop]
        return GAUGE_DEFAULT_VALUES[prop]
    }

    const setRootConfig = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.gauge[prop] = value
        setConfig(newConfig)
    }

    const handleDebouncedRootConfig = (prop, value, setter) => {
        debouncedFunc(false, prop, value)
        setter(value)
    }

    const getProgressConfig = (prop) => {
        if (prop === 'color') {
            if (propExistsOnObject(config.styleOverrides.gauge.progress.itemStyle, prop)) return config.styleOverrides.gauge.progress.itemStyle[prop]
            if (propExistsOnObject(chartPreferences.gauge?.progress?.itemStyle, prop)) return chartPreferences.gauge.progress.itemStyle[prop]
            return GAUGE_DEFAULT_VALUES.progress.itemStyle[prop]
        } else {
            if (propExistsOnObject(config.styleOverrides.gauge.progress, prop)) return config.styleOverrides.gauge.progress[prop]
            if (propExistsOnObject(chartPreferences.gauge?.progress, prop)) return chartPreferences.gauge.progress[prop]
            return GAUGE_DEFAULT_VALUES.progress[prop]
        }
    }

    const setPointerConfig = (prop, value) => {
        const newConfig = cloneDeep(config)
        if (prop === 'color') {
            newConfig.styleOverrides.gauge.pointer.itemStyle[prop] = value
        } else {
            newConfig.styleOverrides.gauge.pointer[prop] = value
        }
        setConfig(newConfig)
    }

    const getPointerConfig = (prop) => {
        if (prop === 'color') {
            if (propExistsOnObject(config.styleOverrides.gauge.pointer.itemStyle, prop)) return config.styleOverrides.gauge.pointer.itemStyle[prop]
            if (propExistsOnObject(chartPreferences.gauge?.pointer?.itemStyle, prop)) return chartPreferences.gauge.pointer.itemStyle[prop]
            return GAUGE_DEFAULT_VALUES.pointer.itemStyle[prop]
        } else {
            if (propExistsOnObject(config.styleOverrides.gauge.pointer, prop)) return config.styleOverrides.gauge.pointer[prop]
            if (propExistsOnObject(chartPreferences.gauge?.pointer, prop)) return chartPreferences.gauge.pointer[prop]
            return GAUGE_DEFAULT_VALUES.pointer[prop]
        }
    }

    const setEmphasis = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.gauge.emphasis[prop] = value
        setConfig(newConfig)
    }

    const getEmphasis = (prop) => {
        if (propExistsOnObject(config.styleOverrides.gauge.emphasis, prop)) return config.styleOverrides.gauge.emphasis[prop]
        if (propExistsOnObject(chartPreferences.gauge?.emphasis, prop)) return chartPreferences.gauge.emphasis[prop]
        return GAUGE_DEFAULT_VALUES.emphasis[prop]
    }

    const getPieOrientationValue = () => {
        const startAngle = getRootConfig('startAngle')
        const endAngle = getRootConfig('endAngle')
        if (startAngle === 90 && endAngle === 270) {
            return 'right'
        } else if (startAngle === 360 && endAngle === 180) {
            return 'bottom'
        } else if (startAngle === 180 && endAngle === 360) {
            return 'top'
        } else if (startAngle === 225 && endAngle === -45) {
            return 'full'
        } else {
            return 'left'
        }
    }

    const setPieOrientationValue = (value) => {
        const newConfig = cloneDeep(config)
        switch (value) {
            case 'top':
                newConfig.styleOverrides.gauge.startAngle = 180
                newConfig.styleOverrides.gauge.endAngle = 360
                break
            case 'full':
                newConfig.styleOverrides.gauge.startAngle = 225
                newConfig.styleOverrides.gauge.endAngle = -45
                break
            case 'right':
                newConfig.styleOverrides.gauge.startAngle = 90
                newConfig.styleOverrides.gauge.endAngle = 270
                break
            case 'bottom':
                newConfig.styleOverrides.gauge.startAngle = 360
                newConfig.styleOverrides.gauge.endAngle = 180
                break
            case 'left':
                newConfig.styleOverrides.gauge.startAngle = 270
                newConfig.styleOverrides.gauge.endAngle = 90
                break
        }
        setConfig(newConfig)
    }

    const setDetailConfig = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.gauge.detail[prop] = value
        setConfig(newConfig)
    }

    const handleDebouncedSetDetailConfig = (prop, value, setter) => {
        debouncedFunc('detail', prop, value)
        setter(value)
    }

    const handleDebouncedValuePosition = (axis, value) => {
        debouncedFunc('detail', 'offsetCenter', value)
        if (axis === 'left') setValueLeft(value[0])
        else setValueTop(value[1])
    }

    const getDetailConfig = (prop) => {
        if (propExistsOnObject(config.styleOverrides.gauge.detail, prop)) return config.styleOverrides.gauge.detail[prop]
        if (propExistsOnObject(chartPreferences.gauge?.detail, prop)) return chartPreferences.gauge.detail[prop]
        return GAUGE_DEFAULT_VALUES.detail[prop]
    }

    const setLineStyle = (prop, value) => {
        const newConfig = cloneDeep(config)
        if (prop === 'color') {
            newConfig.styleOverrides.gauge.axisLine.lineStyle[prop] = [[1, value]]
        }
        else newConfig.styleOverrides.gauge.axisLine.lineStyle[prop] = value
        setConfig(newConfig)
    }

    const handleDebouncedLineStyle = (prop, value, setter) => {
        debouncedLineStyleFunc(prop, value)
        setter(value)
    }

    const getLineStyle = (prop) => {
        if (prop === 'color') {
            if (propExistsOnObject(config.styleOverrides.gauge.axisLine.lineStyle, prop)) return config.styleOverrides.gauge.axisLine.lineStyle[prop][1]
            if (propExistsOnObject(chartPreferences.gauge?.axisLine?.lineStyle, prop)) return chartPreferences.gauge.axisLine.lineStyle[prop][1]
            return GAUGE_DEFAULT_VALUES.axisLine.lineStyle[prop][1]
        }
        if (propExistsOnObject(config.styleOverrides.gauge.axisLine.lineStyle, prop)) return config.styleOverrides.gauge.axisLine.lineStyle[prop]
        if (propExistsOnObject(chartPreferences.gauge?.axisLine?.lineStyle, prop)) return chartPreferences.gauge.axisLine.lineStyle[prop]
        return GAUGE_DEFAULT_VALUES.axisLine.lineStyle[prop]
    }

    const getLabelConfig = (prop) => {
        if (propExistsOnObject(config.styleOverrides.gauge.title, prop)) return config.styleOverrides.gauge.title[prop]
        if (propExistsOnObject(chartPreferences.gauge?.title, prop)) return chartPreferences.gauge.title[prop]
        return GAUGE_DEFAULT_VALUES.title[prop]
    }

    const getAxisTickConfig = (prop, lineStyle) => {
        if (lineStyle) {
            if (propExistsOnObject(config.styleOverrides.gauge.axisTick.lineStyle, prop)) return config.styleOverrides.gauge.axisTick.lineStyle[prop]
            if (propExistsOnObject(chartPreferences.gauge?.axisTick?.lineStyle, prop)) return chartPreferences.gauge.axisTick.lineStyle[prop]
            return GAUGE_DEFAULT_VALUES.axisTick.lineStyle[prop]
        }
        if (propExistsOnObject(config.styleOverrides.gauge.axisTick, prop)) return config.styleOverrides.gauge.axisTick[prop]
        if (propExistsOnObject(chartPreferences.gauge?.axisTick, prop)) return chartPreferences.gauge.axisTick[prop]
        return GAUGE_DEFAULT_VALUES.axisTick[prop]
    }

    const setSplitLineConfig = (prop, value, lineStyle) => {
        const newConfig = cloneDeep(config)
        if (lineStyle) {
            newConfig.styleOverrides.gauge.splitLine.lineStyle[prop] = value
        } else {
            newConfig.styleOverrides.gauge.splitLine[prop] = value
        }
        setConfig(newConfig)
    }

    const handleDebouncedSplitLineConfig = (prop, value, setter, lineStyle) => {
        if (lineStyle) {
            debouncedSplitLineFunc(prop, value)
        } else {
            debouncedFunc('splitLine', prop, value)
        }
        setter(value)
    }

    const setLabelConfig = (prop, value) => {
        const newConfig = cloneDeep(config)
        newConfig.styleOverrides.gauge.title[prop] = value
        setConfig(newConfig)
    }

    const setAxisTickConfig = (prop, value, lineStyle) => {
        const newConfig = cloneDeep(config)
        if (lineStyle) {
            newConfig.styleOverrides.gauge.axisTick.lineStyle[prop] = value
        } else {
            newConfig.styleOverrides.gauge.axisTick[prop] = value
        }
        setConfig(newConfig)
    }

    const handleDebouncedTickConfig = (prop, value, setter, lineStyle) => {
        if (lineStyle) {
            debouncedAxisTickFunc(prop, value)
        } else {
            debouncedFunc('axisTick', prop, value)
        }
        setter(value)
    }

    const getSplitLineConfig = (prop, lineStyle) => {
        if (lineStyle) {
            if (propExistsOnObject(config.styleOverrides.gauge.splitLine.lineStyle, prop)) return config.styleOverrides.gauge.splitLine.lineStyle[prop]
            if (propExistsOnObject(chartPreferences.gauge?.splitLine?.lineStyle, prop)) return chartPreferences.gauge.splitLine.lineStyle[prop]
            return GAUGE_DEFAULT_VALUES.splitLine.lineStyle[prop]
        }
        if (propExistsOnObject(config.styleOverrides.gauge.splitLine, prop)) return config.styleOverrides.gauge.splitLine[prop]
        if (propExistsOnObject(chartPreferences.gauge?.splitLine, prop)) return chartPreferences.gauge.splitLine[prop]
        return GAUGE_DEFAULT_VALUES.splitLine[prop]
    }

    const handleDebouncedLabelConfig = (prop, value, setter) => {
        debouncedFunc('title', prop, value)
        setter(value)
    }

    const handleDebouncedLabelPosition = (axis, value) => {
        debouncedFunc('title', 'offsetCenter', value)
        if (axis === 'left') setLabelLeft(value[0])
        else setLabelTop(value[1])
    }

    const showProgress = getProgressConfig('show')
    const roundCap = getProgressConfig('roundCap')
    const emphasisDisabled = getEmphasis('disabled')
    const showPointer = getPointerConfig('show')
    const showDetail = getDetailConfig('show')
    const showLabel = getLabelConfig('show')
    const showAxisTick = getAxisTickConfig('show')
    const showSplitLine = getSplitLineConfig('show')

    return(
        <Box className={'column'} sx={{gap: '16px'}}>
            {chartState?.categoryAxisValues &&
                renderSelector(
                   'Select an option',
                    selectedValue,
                    handleValueChange,
                    chartState?.categoryAxisValues
                )
            }
            {
                renderSelector(
                    'Gauge orientation',
                    getPieOrientationValue(),
                    (v) => setPieOrientationValue(v),
                    PIE_ORIENTATIONS
                )
            }
            {
                renderColorPicker(
                    'Background color',
                    getLineStyle('color'),
                    (v) => setLineStyle('color', v)
                )
            }
            <Slider
                label={`Split number: ${splitNumber}`}
                value={splitNumber}
                min={1}
                max={25}
                step={1}
                onChange={(v) => handleDebouncedRootConfig('splitNumber', v, setSplitNumber)}
            />
            <Box className={'row'} sx={{gap: '8px', alignItems: 'center'}}>
                <Input
                    disableMargin
                    type={'number'}
                    label={'Min'}
                    value={getRootConfig('min')}
                    onChange={(e) => setRootConfig('min', e.target.value)}

                />
                <Input
                    disableMargin
                    type={'number'}
                    label={'Max'}
                    value={getRootConfig('max')}
                    onChange={(e) => setRootConfig('max', e.target.value)}
                />
            </Box>
            <Slider
                label={`Axis width: ${axisLineWidth}px`}
                value={axisLineWidth}
                min={0}
                max={50}
                step={1}
                onChange={(v) => handleDebouncedLineStyle('width', v, setAxisLineWidth)}
            />
            <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                <Switch
                    checked={!emphasisDisabled}
                    onClick={() => setEmphasis('disabled', !emphasisDisabled)}
                />
                <Typography className={'inter style-config-label'}>Emphasis</Typography>
            </Box>
            <Box className={'column'} sx={{gap: '16px'}}>
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showProgress}
                        onClick={() => setProgressConfig('show', !showProgress)}
                    />
                    <Typography className={'inter style-config-label'}>Show Progress</Typography>
                </Box>
                {showProgress &&
                    <Box className={'column'} sx={{padding: '0 16px 16px 16px', gap: '16px', borderBottom: `${showProgress ? '1px solid #E6E4E6' : '' }`}}>
                        {
                            renderColorPicker(
                                'Progress color',
                                getProgressConfig('color'),
                                (v) => setProgressConfig('color', v)
                            )
                        }
                        <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                            <Switch
                                checked={roundCap}
                                onClick={() => setProgressConfig('roundCap', !roundCap)}
                            />
                            <Typography className={'inter style-config-label'}>Round cap</Typography>
                        </Box>
                    </Box>
                }
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showPointer}
                        onClick={() => setPointerConfig('show', !showPointer)}
                    />
                    <Typography className={'inter style-config-label'}>Show Pointer</Typography>
                </Box>
                {showPointer &&
                    <Box className={'column'} sx={{padding: '0 16px 16px 16px', gap: '16px', borderBottom: `${showPointer ? '1px solid #E6E4E6' : '' }`}}>
                        {
                            renderColorPicker(
                                'Pointer color',
                                getPointerConfig('color'),
                                (v) => setPointerConfig('color', v)
                            )
                        }
                    </Box>
                }
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showDetail}
                        onClick={() => setDetailConfig('show', !showDetail)}
                    />
                    <Typography className={'inter style-config-label'}>Show Value</Typography>
                </Box>
                {showDetail &&
                    <Box className={'column'} sx={{padding: '0 16px 16px 16px', gap: '8px', borderBottom: `${showDetail ? '1px solid #E6E4E6' : '' }`}}>
                        {
                            renderColorPicker(
                                'Value color',
                                getDetailConfig('color'),
                                (v) => setDetailConfig('color', v)
                            )
                        }
                        <Slider
                            label={`Font size: ${valueFontSize}px`}
                            value={valueFontSize}
                            min={8}
                            max={64}
                            step={1}
                            onChange={(v) => handleDebouncedSetDetailConfig('fontSize', v, setValueFontSize)}
                        />
                        <Slider
                            label={`Horizontal offset: ${valueLeft}`}
                            value={parseInt(valueLeft?.replace('%', ''))}
                            onChange={(v) => handleDebouncedValuePosition('left', [v + '%', valueTop])}
                            min={-100}
                            max={100}
                            step={1}
                        />
                        <Slider
                            label={`Vertical offset: ${valueTop}`}
                            value={parseInt(valueTop?.replace('%', ''))}
                            onChange={(v) => handleDebouncedValuePosition('top', [valueLeft, v + '%'])}
                            min={-100}
                            max={100}
                            step={1}
                        />
                        {
                            renderSelector(
                                'Font family',
                                getDetailConfig('fontFamily'),
                                (v) => setDetailConfig('fontFamily', v),
                                FONT_FAMILIES
                            )
                        }
                        {
                            renderSelector(
                                'Font weight',
                                getDetailConfig('fontWeight'),
                                (v) => setDetailConfig('fontWeight', v),
                                FONT_WEIGHTS
                            )
                        }
                    </Box>
                }
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showLabel}
                        onClick={() => setLabelConfig('show', !showLabel)}
                    />
                    <Typography className={'inter style-config-label'}>Show Label</Typography>
                </Box>
                {showLabel &&
                    <Box className={'column'} sx={{padding: '0 16px 16px 16px', gap: '8px', borderBottom: `${showLabel ? '1px solid #E6E4E6' : '' }`}}>
                        {
                            renderColorPicker(
                                'Label color',
                                getLabelConfig('color'),
                                (v) => setLabelConfig('color', v)
                            )
                        }
                        <Slider
                            label={`Font size: ${labelFontSize}px`}
                            value={labelFontSize}
                            min={8}
                            max={64}
                            step={1}
                            onChange={(v) => handleDebouncedLabelConfig('fontSize', v, setLabelFontSize)}
                        />
                        <Slider
                            label={`Horizontal offset: ${labelLeft}`}
                            value={parseInt(labelLeft?.replace('%', ''))}
                            onChange={(v) => handleDebouncedLabelPosition('left', [v + '%', labelTop])}
                            min={-100}
                            max={100}
                            step={1}
                        />
                        <Slider
                            label={`Vertical offset: ${labelTop}`}
                            value={parseInt(labelTop?.replace('%', ''))}
                            onChange={(v) => handleDebouncedLabelPosition('top', [labelLeft, v + '%'])}
                            min={-100}
                            max={100}
                            step={1}
                        />
                        {
                            renderSelector(
                                'Font family',
                                getLabelConfig('fontFamily'),
                                (v) => setLabelConfig('fontFamily', v),
                                FONT_FAMILIES
                            )
                        }
                        {
                            renderSelector(
                                'Font weight',
                                getLabelConfig('fontWeight'),
                                (v) => setLabelConfig('fontWeight', v),
                                FONT_WEIGHTS
                            )
                        }
                    </Box>
                }
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showPointer}
                        onClick={() => setPointerConfig('show', !showPointer)}
                    />
                    <Typography className={'inter style-config-label'}>Show Pointer</Typography>
                </Box>
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showAxisTick}
                        onClick={() => setAxisTickConfig('show', !showAxisTick)}
                    />
                    <Typography className={'inter style-config-label'}>Show Tick</Typography>
                </Box>
                {showAxisTick &&
                    <Box className={'column'} sx={{padding: '0 16px 16px 16px', gap: '8px', borderBottom: `${showAxisTick ? '1px solid #E6E4E6' : '' }`}}>
                        <Slider
                            label={`Split number: ${tickSplitNumber}`}
                            value={tickSplitNumber}
                            min={1}
                            max={25}
                            step={1}
                            onChange={(v) => handleDebouncedTickConfig('splitNumber', v, setTickSplitNumber)}
                        />
                        <Slider
                            label={`Tick distance: ${axisTickDistance}`}
                            value={axisTickDistance}
                            min={0}
                            max={50}
                            step={1}
                            onChange={(v) => handleDebouncedTickConfig('distance', v, setAxisTickDistance)}
                        />
                        {
                            renderColorPicker(
                                'Tick color',
                                getAxisTickConfig('color'),
                                (v) => setAxisTickConfig('color', v, true)
                            )
                        }
                        <Slider
                            label={`Tick width: ${axisTickWidth}px`}
                            value={axisTickWidth}
                            min={0}
                            max={20}
                            step={1}
                            onChange={(v) => handleDebouncedTickConfig('width', v, setAxisTickWidth, true)}
                        />
                    </Box>

                }
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showSplitLine}
                        onClick={() => setSplitLineConfig('show', !showSplitLine)}
                    />
                    <Typography className={'inter style-config-label'}>Show split line</Typography>
                </Box>
                {showSplitLine &&
                    <Box className={'column'} sx={{padding: '0 16px 16px 16px', gap: '8px', borderBottom: `${showSplitLine ? '1px solid #E6E4E6' : '' }`}}>
                        <Slider
                            label={`Length: ${splitLineLength}px`}
                            value={splitLineLength}
                            min={1}
                            max={50}
                            step={1}
                            onChange={(v) => handleDebouncedSplitLineConfig('length', v, setSplitLineLength)}
                        />
                        <Slider
                            label={`Width: ${splitLineWidth}px`}
                            value={splitLineWidth}
                            min={0}
                            max={50}
                            step={1}
                            onChange={(v) => handleDebouncedSplitLineConfig('width', v, setSplitLineWidth, true)}
                        />
                        {
                            renderColorPicker(
                                'Split line color',
                                getSplitLineConfig('color', true),
                                (v) => setSplitLineConfig('color', v, true)
                            )
                        }
                    </Box>

                }
            </Box>
        </Box>
    )
}

