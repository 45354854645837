import {Box, Link, Tooltip} from "@mui/material";
import ScoopLogo from "../../../assets/logo/NewLogo.svg";
import Typography from "@mui/material/Typography";
import Input from "../../common/Input/Input";
import React, {useEffect, useState} from "react";
import "./Login.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from "../../common/Button/Button";
import GoogleIcon from "../../../assets/icons/Google.svg";
import LoginLogos from "../../../assets/images/login-logos.svg";
import Sheets from "../../../assets/images/sheets.svg";
import Charts from "../../../assets/images/charts.svg";
import LoginEmailIcon from "../../../assets/icons/LoginEmail.svg";
import OrganizationIcon from "../../../assets/icons/Organization.svg";
import PlusIcon from "../../../assets/icons/Plus.svg";
import Trash from "../../../assets/icons/Trash.svg";
import {Carousel} from "react-responsive-carousel";
import useSignUp from "./hooks/useSignUp";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import useSignIn from "./hooks/useSignIn";
import {federatedSignIn} from "../../../api/auth";
import {useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../router/routes";
import {ScoopLoader} from "../../common/Spinner/ScoopLoader";
import {CreateWorkspace} from "./CreateWorkspace";
import {RecipeNavbar} from "../Recipe/Recipe";
import {useRecoverPassword} from "./hooks/useRecoverPassword";

const blackColor = '#201024';
const redColor = '#E50B54';

const images = [LoginLogos, Sheets, Charts]

const carouselLabels = [
    'Scoop data from anywhere',
    'Blend & manipulate spreadsheets',
    'Tell compelling data stories'
]

const emails = [
    'john.doe@example.com',
    'jane.smith@example.com',
    'michael.johnson@example.com',
    'emily.davis@example.com'
];

const renderIndicator = (onClickHandler, isSelected) => {
    const indicatorStyle = {
        background: isSelected ? redColor : 'rgba(255, 255, 255, 0.15)',
        width: 10,
        height: 10,
        borderRadius: '50%',
        display: 'inline-block',
        margin: '0 8px',
        cursor: 'pointer',
        strokeWidth: '6px',
        stroke: 'rgba(255, 24, 101, 0.20)'
    };

    return (
        <div
            style={indicatorStyle}
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
            role="button"
        />
    );
};

export const Login = () => {

    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const navigate = useNavigate();
    const [step, setStep] = useState(0)
    const [recoverPasswordStep, setRecoverPasswordStep] = useState(0)
    const [isLogin, setIsLogin] = useState(true)
    const [showSnackbar, setShowSnackbar] = useState(null)
    const [verificationCode, setVerificationCode] = useState(null)

    const {
        signUpUser,
        handleSignUpInputChange,
        handleSignUp,
        isSignUpLoading,
        handleConfirmSignup,
        handleGoToCanvas
    } = useSignUp(step, setStep, setShowSnackbar, setIsLogin);

    const {
        signInUser,
        handleSignInInputChange,
        handleSignIn,
        isSignInLoading
    } = useSignIn(setShowSnackbar);

    const {
        email,
        newPassword,
        handleEmailChange,
        handleRecoverPasswordChange,
        handleStartPWDReset,
        handleFinishPWDReset
    } = useRecoverPassword(setShowSnackbar, setRecoverPasswordStep, () => {
        setIsLogin(true)
        setStep(0)
    });

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search);
        const recipeMapping = searchParams.get('recipeMapping');

        if (recipeMapping) localStorage.setItem('recipeMapping', recipeMapping);

        if (isAuthenticated && isLogin) navigate(ROUTES.CANVAS_DASHBOARD)

    }, [isAuthenticated, isLogin]);

    const signInWithGoogle = () => {
        federatedSignIn()
    }

    const selectOption = (option) => {
        if (option === 0) {
            handleGoToCanvas()
        } else if (option === 1) {
            navigate(ROUTES.RECIPE)
        }
    }

    const renderStep = () => {
        switch (step) {
            case 0:
                return renderSignUp()
            case 1:
                return renderVerifyEmail()
            case 2:
                return renderRecoverPassword()
            default:
                return renderSignUp(); //Should never reach here
        }
    }


    const renderSignUp = () => {
        return (
            <Box sx={{width: '440px'}}>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                    paddingBottom: '40px'
                }}>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img alt={'ScoopLogo'} src={ScoopLogo}/>
                    </Box>
                    <Typography sx={{
                        color: blackColor,
                        fontFamily: 'Inter',
                        textAlign: 'center',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '110%',
                        letterSpacing: '-0.64px'
                    }}>
                        {!isLogin ? 'Start your 30 day free trial' : 'Login to Scoop'}
                    </Typography>
                </Box>
                <Box
                    id={'login-input-container'}
                    sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}
                >
                    {!isLogin &&
                        <Box className={'loginInput-no-margin'} sx={{display: 'flex', flexDirection: 'column'}}>
                            <Input
                                type={'text'}
                                value={signUpUser.fullName}
                                onChange={(e) => handleSignUpInputChange('fullName', e.target.value)}
                                sx={{"& .MuiTextField-root input": {margin: '0 !important'}}}
                                label={'Full name'}
                            />
                        </Box>}
                    <Box className={'loginInput-no-margin'} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Input
                            type={'email'}
                            value={isLogin ? signInUser.email : signUpUser.email}
                            onChange={(e) => isLogin ?
                                handleSignInInputChange('email', e.target.value)
                                : handleSignUpInputChange('email', e.target.value)}
                            sx={{"& .MuiTextField-root input": {margin: '0 !important'}}}
                            label={'Email address'}
                        />
                    </Box>
                    <Box className={'loginInput-no-margin'} sx={{display: 'flex', flexDirection: 'column'}}>
                        <Input
                            type="password"
                            value={isLogin ? signInUser.password : signUpUser.password}
                            onChange={(e) => isLogin ?
                                handleSignInInputChange('password', e.target.value)
                                : handleSignUpInputChange('password', e.target.value)}
                            sx={{"& .MuiTextField-root input": {margin: '0 !important'}}}
                            label={'Password'}
                        />
                    </Box>
                </Box>
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    <Box sx={{width: '100%'}}>
                        <Button
                            onClick={() => !isLogin ? handleSignUp() : handleSignIn()}
                            sx={{marginTop: '24px'}}
                            className={'button-purple small'}
                            fullWidth
                            disabled={isSignUpLoading || isSignInLoading || (isLogin ? !signInUser.email || !signInUser.password : !signUpUser.fullName || !signUpUser.email || !signUpUser.password)}
                        >
                            {(isSignUpLoading || isSignInLoading) ?
                                <ScoopLoader/> : !isLogin ? 'Create account' : 'Login'}
                        </Button>
                    </Box>
                    <Box sx={{display: 'flex', alignItems: 'center'}}>
                        <Box sx={{width: '45.6%', borderBottom: '1px solid #E6E4E6'}}/>
                        <Typography sx={{padding: '0 12px', fontSize: '14px', fontWeight: 400, color: blackColor}}>
                            or
                        </Typography>
                        <Box sx={{width: '45.6%', borderBottom: '1px solid #E6E4E6'}}/>
                    </Box>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Button
                            onClick={signInWithGoogle}
                            className={'button-grey small'}
                            fullWidth
                        >
                            <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '8px'}}>
                                <img alt={'GoogleIcon'} src={GoogleIcon}/>
                                <Typography sx={{fontSize: '14px', fontWeight: 500, color: blackColor}}>
                                    Login with Google
                                </Typography>
                            </Box>
                        </Button>
                    </Box>
                </Box>

                <Box sx={{
                    width: '100%',
                    marginTop: '40px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <Typography sx={{fontSize: '12px', fontWeight: 400, color: blackColor}}>
                        {isLogin ? 'Do not have an account?' : 'Already have a Scoop account?'}
                        <span onClick={() => setIsLogin(!isLogin)} style={{color: redColor, cursor: 'pointer'}}>
                            {isLogin ? ' Sign up here' : ' Login here'}
                        </span>
                    </Typography>
                    <Typography sx={{fontSize: '12px', fontWeight: 400, color: blackColor}}>
                        Forgot your password?
                        <span onClick={() => setStep(2)} style={{color: redColor, cursor: 'pointer'}}>
                            {' Recover it here'}
                        </span>
                    </Typography>
                </Box>
            </Box>
        );
    }


    const renderVerifyEmail = () => {
        return (<Box sx={{width: '440px'}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '32px'
            }}>
                <Box>
                    <Box sx={{
                        position: 'relative',
                        left: '7px',
                        top: '48px',
                        background: 'linear-gradient(180deg, rgba(65, 39, 71, 0.00) 0%, rgba(65, 39, 71, 0.15) 100%)',
                        width: '98px',
                        height: '98px',
                        zIndex: -10,
                    }}/>
                    <img alt={'email-icon'} src={LoginEmailIcon}/>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px'
                }}>
                    <Typography sx={{
                        textAlign: 'center',
                        color: blackColor,
                        fontSize: '28px',
                        fontWeight: '400',
                        lineHeight: '110%',
                        letterSpacing: '-0.56px'
                    }}>
                        Verify your email
                    </Typography>
                    <Typography sx={{
                        textAlign: 'center',
                        color: '#4A3C4E',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '110%',
                        letterSpacing: '-0.28px'
                    }}>
                        We sent a code to <span
                        style={{fontWeight: 'bold'}}>{signUpUser.email}</span><br/>
                        Enter below to verify your account
                    </Typography>
                </Box>
                <Box className={'loginInput-no-margin'}
                     sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '8px'}}>
                    <Input
                        value={verificationCode}
                        onChange={(e) => setVerificationCode(e.target.value)}
                        padding={'12px'}
                        fullWidth
                        placeholder={'Enter 6 digit code'}
                    />
                    <Button
                        disabled={verificationCode === null || verificationCode.length !== 6 || isSignUpLoading}
                        onClick={() => handleConfirmSignup(verificationCode)}
                        fullWidth
                        className={'button-purple small'}
                    >
                        {isSignUpLoading ? <ScoopLoader /> : 'Submit code'}
                    </Button>
                </Box>
                <Box>
                    <Typography sx={{
                        textAlign: 'center',
                        color: '#4A3C4E',
                        fontSize: '14px',
                        fontWeight: '400',
                        lineHeight: '110%',
                        letterSpacing: '-0.24px'
                    }}>
                        Don't see an email?<br/>
                        Check your spam folder or
                        <span style={{color: redColor, cursor: 'pointer'}}> request new code</span>
                    </Typography>
                </Box>
            </Box>

        </Box>)
    }

    const renderOrganization = () => {
        return (<Box sx={{width: '440px'}}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: '48px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '32px'
                }}>
                    <Box>
                        <Box sx={{
                            position: 'relative',
                            left: '7px',
                            top: '48px',
                            background: 'linear-gradient(180deg, rgba(65, 39, 71, 0.00) 0%, rgba(65, 39, 71, 0.15) 100%)',
                            width: '98px',
                            height: '98px',
                            zIndex: -10,
                        }}/>
                        <img alt={'organization-icon'} src={OrganizationIcon}/>
                    </Box>
                    <Typography sx={{
                        textAlign: 'center',
                        color: blackColor,
                        fontSize: '32px',
                        fontWeight: '400',
                        lineHeight: '110%',
                        letterSpacing: '-0.56px'
                    }}>
                        Let’s set up your Organisation
                    </Typography>
                </Box>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '16px',
                    width: '100%'
                }}>
                    <Box className={'loginInput-no-margin'}
                         sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '8px'}}>
                        <Input
                            label={'Organisation name'}
                            padding={'8px'}
                            fullWidth
                            placeholder={'Your business'}
                        />

                    </Box>
                    <Box className={'loginInput-no-margin'}
                         sx={{width: '100%', display: 'flex', gap: '8px'}}>
                        <Input
                            label={'Invite members (optional)'}
                            padding={'8px'}
                            fullWidth
                            placeholder={'Add email'}
                        />
                        <Box sx={{minHeight: '100%', display: 'flex', alignItems: 'end'}}>
                            <Box sx={{
                                cursor: 'pointer',
                                borderRadius: '5px',
                                background: '#F2F2F2',
                                display: 'flex',
                                height: '43px',
                                width: '43px',
                                padding: '10px',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '8px',
                                float: 'bottom',
                            }}>
                                <img alt={'sum-icon'} src={PlusIcon}/>
                            </Box>
                        </Box>
                    </Box>
                    <Box sx={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        gap: '8px',
                        padding: '12px',
                        backgroundColor: '#F9F9F9',
                        borderRadius: '8px',
                        maxHeight: '140px',
                        overflowY: 'scroll'
                    }}>
                        {emails.map((email, index) => (
                            <EmailRow key={index} email={email}/>
                        ))}
                    </Box>
                </Box>
                <Button
                    fullWidth
                    className={'button-purple small'}
                >
                    Set up and continue
                </Button>
            </Box>

        </Box>)
    }

    const renderRecoverPassword = () => {

        return (
            <Box sx={{width: '440px'}}>
                <Box sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '32px',
                    paddingBottom: '40px'
                }}>
                    <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <img alt={'ScoopLogo'} src={ScoopLogo}/>
                    </Box>
                    <Typography sx={{
                        color: blackColor,
                        fontFamily: 'Inter',
                        textAlign: 'center',
                        fontSize: '32px',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '110%',
                        letterSpacing: '-0.64px'
                    }}>
                        Recover your password
                    </Typography>
                </Box>
                {recoverPasswordStep === 0 && <>
                    <Box
                        id={'login-input-container'}
                        sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}
                    >
                        <Box className={'loginInput-no-margin'} sx={{display: 'flex', flexDirection: 'column'}}>
                            <Input
                                type={'email'}
                                value={email}
                                onChange={(e) => handleEmailChange(e.target.value)}
                                sx={{"& .MuiTextField-root input": {margin: '0 !important'}}}
                                label={'Email'}
                            />
                        </Box>
                    </Box>
                </>
                }
                {recoverPasswordStep === 1 && <>
                    <Box
                        id={'login-input-container'}
                        sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '16px'}}
                    >
                        <Box className={'loginInput-no-margin'} sx={{display: 'flex', flexDirection: 'column'}}>
                            <Input
                                type={'text'}
                                value={newPassword.code}
                                onChange={(e) => handleRecoverPasswordChange('code', e.target.value)}
                                sx={{"& .MuiTextField-root input": {margin: '0 !important'}}}
                                label={'Verification Code'}
                            />
                        </Box>
                        <Box className={'loginInput-no-margin'} sx={{display: 'flex', flexDirection: 'column'}}>
                            <Input
                                type={'password'}
                                value={newPassword.password}
                                onChange={(e) => handleRecoverPasswordChange('password', e.target.value)}
                                sx={{"& .MuiTextField-root input": {margin: '0 !important'}}}
                                label={'New Password'}
                            />
                        </Box>
                        <Box className={'loginInput-no-margin'} sx={{display: 'flex', flexDirection: 'column'}}>
                            <Input
                                type="password"
                                value={newPassword.confirmPassword}
                                onChange={(e) => handleRecoverPasswordChange('confirmPassword', e.target.value)}
                                sx={{"& .MuiTextField-root input": {margin: '0 !important'}}}
                                label={'Confirm Password'}
                            />
                        </Box>
                    </Box>
                </>
                }
                <Box sx={{width: '100%', display: 'flex', flexDirection: 'column', gap: '24px'}}>
                    <Box sx={{width: '100%'}}>
                        <Button
                            onClick={() => recoverPasswordStep === 0 ? handleStartPWDReset() : handleFinishPWDReset()}
                            sx={{marginTop: '24px'}}
                            className={'button-purple small'}
                            fullWidth
                            disabled={recoverPasswordStep === 0 ? !email : (!newPassword.code || !newPassword.password || !newPassword.confirmPassword)}
                        >
                            {(isSignUpLoading || isSignInLoading) ?
                                <ScoopLoader/> : (recoverPasswordStep === 0 ? 'Send email' : 'Recover Password')}
                        </Button>
                    </Box>
                </Box>
                <Box sx={{width: '100%', marginTop: '24px', display: 'flex', justifyContent: 'center'}}>
                    <Typography sx={{fontSize: '12px', fontWeight: 400, color: blackColor}}>
                        Already have a Scoop account?
                        <span onClick={() => {
                            setStep(0)
                            setIsLogin(true)
                        }} style={{color: redColor, cursor: 'pointer'}}>
                            {' Login here'}
                        </span>
                    </Typography>
                </Box>
            </Box>
        )
    }

    return (
        step < 3 ? (
            <Box sx={{width: '100vw', height: '100vh', display: 'flex', maxWidth: '100vw', maxHeight: '100vh'}}>
                <Box sx={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    {renderStep()}
                    <Box sx={{
                        position: 'fixed',
                        bottom: 0,
                        left: '5%',
                        width: '40%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        p: 1,
                        bgcolor: 'background.paper',
                    }}>

                        <Tooltip title="Watch a demo video of Scoop in use">
                            <Link href="https://www.scoopanalytics.com/demo-video" target="_blank"
                                  sx={{textDecoration: 'none', fontSize: '14px', color: 'text.primary'}}>
                                Demo
                            </Link>
                        </Tooltip>
                        <Tooltip title="Read about Scoop and what it can do for you">
                            <Link href="https://docs.scoopanalytics.com/docs/what-is-scoop" target="_blank"
                                  sx={{textDecoration: 'none', fontSize: '14px', color: 'text.primary'}}>
                                What is Scoop
                            </Link>
                        </Tooltip>
                        <Tooltip title="Read about how to get started with Scoop">
                            <Link href="https://docs.scoopanalytics.com/docs/scoop-quickstart-guide" target="_blank"
                                  sx={{textDecoration: 'none', fontSize: '14px', color: 'text.primary'}}>
                                Getting Started
                            </Link>
                        </Tooltip>
                        <Tooltip title="Scoop's website">
                            <Link href="https://www.scoopanalytics.com" target="_blank"
                                  sx={{textDecoration: 'none', fontSize: '14px', color: 'text.primary'}}>
                                Home
                            </Link>
                        </Tooltip>
                    </Box>
                </Box>
                <Box sx={{
                    width: '50%',
                    height: '100%',
                    backgroundColor: '#130417',
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    alignItems: 'center'
                }}>
                    <Box sx={{width: '470px'}}>
                        <Carousel
                            showStatus={false}
                            showArrows={false}
                            autoPlay={true}
                            showThumbs={false}
                            infiniteLoop={true}
                            interval={5000}
                            transitionTime={800}
                            renderIndicator={renderIndicator}
                        >
                            {images.map((image, index) => (
                                <div
                                    key={index}
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        justifyContent: 'space-between'
                                    }}>
                                    <img style={{height: '315px'}} alt={index} src={image}/>
                                    <p style={{
                                        fontSize: '40px',
                                        fontWeight: 400,
                                        color: '#F9F9F9',
                                        marginTop: '24px'
                                    }}>
                                        {carouselLabels[index]}
                                    </p>
                                </div>
                            ))}
                        </Carousel>
                    </Box>
                </Box>
                <Snackbar
                    open={!!showSnackbar}
                    autoHideDuration={5000}
                    onClose={() => setShowSnackbar(null)}
                    anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                >
                    <Alert onClose={() => setShowSnackbar(null)} severity={showSnackbar?.severity} variant="filled">
                        {showSnackbar?.msg}
                    </Alert>
                </Snackbar>
            </Box>
        ) : (
            <Box
                sx={{
                    height: '100vh',
                    maxHeight: '100vh',
                    width: '100vw',
                    maxWidth: '100vw',
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px 64px 64px 64px'
                }}
            >
                <RecipeNavbar goBack={() => setStep(step - 1)} showIcon={true}/>
                <CreateWorkspace selectOption={selectOption} />
            </Box>
        )
    );

}


const EmailRow = ({email}) => {

    const initialLetter = email.charAt(0).toUpperCase()

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
            <Box sx={{display: 'flex', gap: '8px', justifyContent: 'space-between', alignItems: 'center'}}>
                <Box sx={{
                    display: 'flex',
                    width: '24px',
                    height: '24px',
                    padding: '2px 7px',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '5px',
                    border: '1px solid rgba(164, 193, 252, 0.20)',
                    background: '#E8EFFC'
                }}>
                    <Typography sx={{
                        color: '#134CBF',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '125%',
                        letterSpacing: '-0.32px'
                    }}>
                        {initialLetter}
                    </Typography>
                </Box>
                <Typography sx={{fontSize: '14px', fontWeight: 400, color: blackColor}}>
                    {email}
                </Typography>
            </Box>
            <Box sx={{display: 'flex', gap: '8px', justifyContent: 'space-between', alignItems: 'center'}}>
                <img alt={'trash-icon'} src={Trash}/>
            </Box>
        </Box>
    )

}
