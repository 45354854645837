import {HotTable} from "@handsontable/react";
import React, {useRef, useState, useEffect, useMemo} from "react";
import Handsontable from "handsontable";
import {textRenderer} from "handsontable/renderers/textRenderer";
import {Box} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {forceExplorerDataRefresh} from '../../store/actions/uiActions';
import './Source.css'
import {ScoopLoader} from "../common/Spinner/ScoopLoader";
import {packFilter} from "../Insights/Filter";
import {setUpdatedWorksheet} from "../../store/actions/promptActions";

export default function Sheetlet({
                                     sheetletId,
                                     serverContext,
                                     sheetRange,
                                     locked,
                                     cornerHTML,
                                     addNew,
                                     shouldHideGrid,
                                     embeddedSizeProps,
                                     shouldHideHeaders,
                                     isBlending,
                                     activePrompts,
                                     setError,
                                     setWorksheetID,
                                     presentationScale,
                                     controlledHeight
                                 }) {
    const [data, setData] = useState([]);
    const [colHeaders, setColHeaders] = useState(null);
    const [colWidths, setColWidths] = useState(null);
    const [rowHeaders, setRowHeaders] = useState(null);
    const [hiddenRows, setHiddenRows] = useState(null);
    const [loading, setLoading] = useState(true);
    const hotTableComponent = useRef(null);
    const lastEditedPrompt = useSelector(state => state.prompt.lastEditedPrompt)
    const activeMode = useSelector(state => state.ui.activeMode)

    const [suppressGrid, setSuppressGrid] = useState(shouldHideGrid || false);
    const [suppressHeaders, setSuppressHeaders] = useState(shouldHideHeaders || false);

    const objects = useSelector(state => state.objects);
    const showElementWrapper = useSelector(state => state.ui.showEditElementWrapper);

    const emptyCellBackground = useMemo(() => {
        const id = parseFloat(sheetletId?.split('-')[1]);
        return objects?.find(obj => obj.id === id)?.wrapperStyles?.cellColor;
    }, [showElementWrapper])

    const dispatch = useDispatch();

    useEffect(() => {
        setSuppressGrid(shouldHideGrid);
        setSuppressHeaders(shouldHideHeaders);
    }, [shouldHideGrid, shouldHideHeaders]);

    useEffect(() => {
        if (lastEditedPrompt?.objects.includes(sheetletId)) {
            setLoading(true)
            const action = {
                "action": "getSheet",
                "sheetRange": sheetRange
            }
            if (activePrompts?.length > 0) {
                const validPrompts = []
                activePrompts.forEach(prompt => {
                    if (Array.isArray(prompt)) {
                        prompt.forEach(p => {
                            if (p.filterValue.values.length > 0 && prompt.filterValue.values[0] !== 'All') validPrompts.push(p)
                        })
                    } else {
                        if (prompt.filterValue.values.length > 0 && prompt.filterValue.values[0] !== 'All') validPrompts.push(prompt)
                    }
                })
                action.prompts = packFilter(validPrompts)
            }
            serverContext.server.sheetPostData(action, (data) => {
                getResults(data)
                dispatch(setUpdatedWorksheet(serverContext?.serverData?.worksheetID))
                setTimeout(() => dispatch(setUpdatedWorksheet('')), 1000)
                setLoading(false)
            })
        }
    }, [lastEditedPrompt])

    class ScoopEditor extends Handsontable.editors.TextEditor {

        finishEditing(restoreOriginalValue = false, ctrlDown = false, callback) {
            super.finishEditing(restoreOriginalValue, ctrlDown, callback);
            if (!serverContext.serverData || !this.TEXTAREA.initialized)
                return;
            let value = this.getValue();
            serverContext.enterValueInCell(sheetRange, value, this.row, this.col, serverContext.applyChanges, this.hot, serverContext.serverData.cells[this.row][this.col]?.t,
                () => {
                    dispatch(forceExplorerDataRefresh(sheetRange.worksheetID));
                });
            // Get the formatted results from the server
            if (serverContext.serverData.cells[this.row][this.col]?.t && serverContext.serverData.cells[this.row][this.col]?.t.length > 0 && !serverContext.serverData.cells[this.row][this.col]?.f) {
                let cell = serverContext.serverData.cells[this.row][this.col];
                serverContext.getFormattedData(this.getValue(), cell.t, this.row, this.col, cell.b, (result, obj) => {
                    this.hot.setDataAtCell(obj.row, obj.col, result.result);
                    let worksheetID = serverContext?.serverData?.worksheetID;
                });
                //console.log("formatting cell");
            }
            this.TEXTAREA.initialized = false;
        }

        beginEditing(initialValue = null, event) {
            super.beginEditing(initialValue, event);
            this.TEXTAREA.style.color = "#222222";
            this.TEXTAREA.style.backgroundColor = "#FFFFFF";
            var curCell = serverContext.serverData.cells[this.row][this.col];
            if (curCell) {
                if (curCell.f) {
                    this.setValue('=' + curCell.f);
                } else if (curCell.b) {
                    // Boolean
                    this.setValue(curCell.s);
                } else if (curCell.d) {
                    // Date
                    this.setValue(curCell.s);
                } else if (curCell.r) {
                    this.setValue(curCell.r);
                } else if (curCell.s) {
                    this.setValue(curCell.s);
                }
/*                if (curCell.si) {
                    let style = serverContext.serverData.styles[curCell.si];
                    if (style) {
                        let s = this.TEXTAREA.style;
                        if (style.fontIndex !== null) {
                            setFontStyle(s, style.fontIndex);
                        }
                    }
                }*/
            }
            this.TEXTAREA.initialized = true;
        }

        open() {
            super.open();
            this.addHook('beforeOnCellMouseDown', (event, coords, TD, controller) => this.beforeOnCellMouseDown(event, coords, TD, controller));
        }

        close() {
            super.close();
            this.clearHooks();
        }

        getSheetColumn(colNumber) {
            let result = "";
            let numCharacters = 0;
            let remainder = colNumber;
            do {
                result += String.fromCharCode(65 + remainder % 26);
                remainder = ~~(remainder / 26) - 1;
                numCharacters++;
            } while (remainder >= 0);
            let reverse = "";
            for (let i = 0; i < numCharacters; i++) {
                reverse += result[numCharacters - i - 1];
            }
            return reverse;
        }


        beforeOnCellMouseDown(event, coords, TD, controller) {
            let val = this.getValue();
            if (val && val.startsWith("=")) {
                val += this.getSheetColumn(serverContext.serverData.startPos?.col + coords.col) + (coords.row + serverContext.serverData.startPos?.row + 1);
                this.setValue(val);
                event.stopImmediatePropagation();
                event.preventDefault();
            }
        }

        createElements() {
            super.createElements();
            this.TEXTAREA.setAttribute('data-hot-input', true); // Makes the element recognizable by HOT as its own component's element.
            this.TEXTAREA_PARENT.innerText = '';
            this.TEXTAREA_PARENT.appendChild(this.TEXTAREA);
            this.TEXTAREA.initialized = false;
        }
    }

    function handleClickIncludeColumn(event) {
        var row = event.target.row;
        var col = event.target.col;
        var cell = serverContext.serverData.cells[row][col];
        if (cell.r === 0) {
            cell.r = 1;
        } else {
            cell.r = 0;
        }
    }

    function setFontStyle(s, fontIndex) {
        let font = serverContext.serverData.fonts[fontIndex];
        if (font) {
            if (font.bold) {
                s.fontWeight = "bold";
            }
            if (font.italic) {
                s.fontStyle = "italic";
            }
            if (font.height) {
                s.fontSize = font.height + 'pt';
            }
            if (font.fcolor) {
                s.color = '#' + font.fcolor;
            }
            if (font.family) {
                s.fontFamily = font.family;
            }
        }
    }

    function afterColumnChange(event) {
    }

    function handleColumnChange(event) {
        serverContext.serverData.cells[event.target.row][event.currentTarget.col].sheetObject.address = event.currentTarget.value;
        var cell1 = serverContext.serverData.cells[event.target.row][1];
        var cell2 = serverContext.serverData.cells[event.target.row][3];
        cell1.f = cell1.sheetObject.address + "=" + cell2.sheetObject.address;
        serverContext.updateCell(sheetRange, event.target.row, 1, cell1, afterColumnChange, hotTableComponent, serverContext);
    }

    function scoopTextRenderer(instance, TD, row, col, prop, value, cellProperties) {
        if (serverContext.serverData && Object.keys(serverContext.serverData).length > 0) {
            textRenderer(instance, TD, row, col, prop, value, cellProperties);
            let cell = serverContext.serverData.cells[row][col];
            if (cell === null) TD.style.backgroundColor = emptyCellBackground;
            if (!serverContext.settings.gridLines || suppressGrid) {
                TD.style.borderBottom = 0;
                TD.style.borderBottom = 'none';
                TD.style.borderTop = 0;
                TD.style.borderTop = 'none';
                TD.style.borderLeft = 0;
                TD.style.borderLeft = 'none';
                TD.style.borderRight = 0;
                TD.style.borderRight = 'none';
            }
            if (cell && cell.cb) {
                TD.innerText = "";
                const checkBox = document.createElement('INPUT');
                checkBox.type = 'checkbox';
                checkBox.checked = cell.r === 1;
                checkBox.row = row;
                checkBox.col = col;
                checkBox.onclick = handleClickIncludeColumn;
                checkBox.style.accentColor = '#E50B54';
                TD.style.textAlign = 'center';
                TD.appendChild(checkBox)
            } else if (cell && cell.si !== undefined) {
                let style = serverContext.serverData.styles[cell.si];
                TD.style.textAlign = (cell.r !== undefined) && (typeof cell.r === 'number') ? 'right' : 'left';
                if (style) {
                    let s = TD.style;
                    if (style.fontIndex !== null) {
                        setFontStyle(s, style.fontIndex);
                    }
                    if (style.hAlign) {
                        switch (style.hAlign) {
                            case "CENTER":
                                s.textAlign = 'center';
                                break;
                            case "LEFT":
                                s.textAlign = 'left';
                                break;
                            case "RIGHT":
                                s.textAlign = 'right';
                                break;
                        }
                    }
                    if (style.vAlign) {
                        switch (style.vAlign) {
                            case "CENTER":
                                s.textAlign = 'middle';
                                break;
                            case "TOP":
                                s.textAlign = 'top';
                                break;
                            case "BOTTOM":
                                s.textAlign = 'bottom';
                                break;
                        }
                    }
                    TD.parentNode.style.backgroundColor = emptyCellBackground;
                    if (style.bgcolor) {
                            s.backgroundColor = '#' + style.bgcolor;
                    } else {
                        s.backgroundColor = emptyCellBackground;
                    }

                    if (style.bb) {
                        s.borderBottom = 1;
                        s.borderBottom = 'solid';
                    }
                    if (style.bt) {
                        s.borderTop = 1;
                        s.borderTop = 'solid';
                    }
                    if (style.bl) {
                        s.borderLeft = 1;
                        s.borderLeft = 'solid';
                    }
                    if (style.br) {
                        s.borderRight = 1;
                        s.borderRight = 'solid';
                    }
                }
            }
            if (cell && cell.elementID) {
                TD.id = cell.elementID;
            }
            if (cell && cell.sheetObject) {
                if (cell.sheetObject.sheetObjectType === "refreshInput") {
                    TD.innerText = null;
                    TD.style.lineHeight = '17px';
                    TD.style.paddingBottom = '4px';
                    const ntd = document.createElement('button');
                    ntd.style.textAlign = 'center';
                    ntd.style.border = 'none';
                    ntd.style.backgroundColor = '#FCE7EE';
                    ntd.style.color = '#E50B54';
                    ntd.style.fontSize = '16px';
                    ntd.style.height = '25px';
                    ntd.style.width = '100px';
                    ntd.style.borderRadius = '5px';
                    ntd.style.cursor = 'pointer';
                    ntd.onclick = () => {
                        serverContext.server.sheetPostData({
                            action: 'addOn',
                            addOnAction: 'refreshInputQuery',
                            noFlush: true,
                            sheetRange: sheetRange,
                            sheetID: sheetRange.worksheetID
                        }, serverContext.applyChanges, {
                            hotComponent: hotTableComponent.current.hotInstance,
                            serverContext: serverContext,
                            handler: () => {
                                dispatch(forceExplorerDataRefresh(sheetRange.worksheetID));
                            }
                        });
                    };
                    ntd.innerHTML = '<span>Refresh</span>';
                    TD.appendChild(ntd);
                } else if (locked && (cell.sheetObject.sheetObjectType === "column1Select" || cell.sheetObject.sheetObjectType === "column2Select")) {
                    TD.innerText = "";
                    TD.style.borderBottom = null;
                    TD.style.borderTop = null;
                    TD.style.borderLeft = null;
                    TD.style.borderRight = null;
                    TD.style.backgroundColor = null;
                    const selectBox = document.createElement('SELECT');
                    selectBox.name = cell.sheetObject.sheetObjectType;
                    selectBox.id = cell.sheetObject.sheetObjectType;
                    selectBox.row = row;
                    selectBox.col = col;
                    selectBox.style.color = '#FFFFFF';
                    selectBox.style.backgroundColor = '#E50B54';
                    selectBox.style.borderRadius = '7px';
                    selectBox.style.height = '90%';
                    selectBox.style.width = '100%';
                    selectBox.onchange = handleColumnChange;
                    let columns = cell.sheetObject.columns;
                    let addresses = cell.sheetObject.addresses;
                    if (columns) {
                        for (let i = 0; i < columns.length; i++) {
                            let option = document.createElement("OPTION");
                            option.value = addresses[i];
                            option.text = columns[i];
                            if (option.value === cell.sheetObject.address) {
                                option.selected = true;
                            }
                            selectBox.appendChild(option);
                        }
                    }
                    TD.style.textAlign = 'center';
                    TD.appendChild(selectBox);
                    let style = serverContext.serverData.styles[cell.si];
                    if (cell.sheetObject.sheetObjectType === "column1Select") {
                        let headerCell = document.getElementById("queryColumn1Header");
                        if (headerCell) {
                            headerCell.innerText = "Query Column 1";
                            headerCell.style.textAlign = 'center';
                            headerCell.style.backgroundColor = "#E6E4E6"
                            if (style) {
                                setFontStyle(headerCell.style, style.fontIndex);
                            }
                        }
                    } else {
                        let headerCell = document.getElementById("queryColumn2Header");
                        if (headerCell) {
                            headerCell.innerText = "Query Column 2";
                            headerCell.style.textAlign = 'center';
                            headerCell.style.backgroundColor = "#E6E4E6";
                            if (style) {
                                setFontStyle(headerCell.style, style.fontIndex);
                            }
                        }
                    }
                } else if (locked && cell.sheetObject.sheetObjectType === "blendingOperator") {
                    TD.innerText = "=";
                    TD.style.textAlign = 'center';
                }
            } else if (locked) {
                if (cell && cell.editable) {
                    const divElement = document.createElement('div');
                    if (cell.cb) {
                        const curChild = TD.children[0];
                        divElement.appendChild(curChild);
                    } else {
                        const innert = TD.innerText;
                        divElement.innerText = innert;
                    }
                    TD.innerText = null;
                    TD.style.lineHeight = '17px';
                    TD.style.paddingBottom = '4px';
                    TD.appendChild(divElement)
                    divElement.style.borderStyle = 'solid';
                    divElement.style.width = '100%';
                    divElement.style.height = '100%';
                    divElement.style.borderWidth = '1px';
                    divElement.style.borderRadius = '3px';
                    divElement.style.borderColor = '#E50B54';
                    divElement.style.paddingBottom = '0px';
                    divElement.style.margin = '0px';
                } else if (cell && cell.addNewButton) {
                    TD.innerText = null;
                    TD.style.lineHeight = '17px';
                    TD.style.paddingBottom = '4px';
                    const divElement = document.createElement('button');
                    divElement.style.border = 'none';
                    divElement.style.backgroundColor = '#FCE7EE';
                    divElement.style.color = '#E50B54';
                    divElement.style.fontSize = '16px';
                    divElement.style.height = '25px';
                    divElement.style.width = '25px';
                    divElement.style.borderRadius = '5px';
                    divElement.style.cursor = 'pointer';
                    divElement.onclick = addNew;
                    divElement.style.textAlign = 'center';
                    divElement.innerHTML = '+'
                    TD.appendChild(divElement);
                } else {
                    cellProperties.readOnly = true;
                }
            }
        }
    }

    function afterChange(changes) {
        if (!changes)
            return;
        let coordinates = [];
        for (let i = 0; i < changes.length; i++) {
            if (changes[i][2] !== null && changes[i][3] === null) {
                coordinates.push([changes[i][0], changes[i][1]]);
            }
        }
        if (coordinates.length > 0) {
            for (let i = 0; i < coordinates.length; i++) {
                serverContext.serverData.cells[coordinates[i][0]][coordinates[i][1]] = undefined;
            }
            serverContext.deleteCells(sheetRange, coordinates);
        }
    }

    function afterPaste(valueArray, targetCells) {
        for (let x = targetCells[0].startCol; x <= targetCells[0].endCol; x++) {
            for (let y = targetCells[0].startRow; y <= targetCells[0].endRow; y++) {
                let cell = this.serverContext.serverData.cells[this.serverContext.lastCopySelection.row + y - targetCells[0].startRow][this.serverContext.lastCopySelection.column + x - targetCells[0].startCol];
                this.enterValueInCell(sheetRange, cell.r ? cell.r : cell.s, y, x, this.applyChanges, hotTableComponent.current.hotInstance, cell.t,
                    () => {
                        dispatch(forceExplorerDataRefresh(sheetRange.worksheetID));
                    });
            }
        }
    }

    function afterSelection(row, column, row2, column2, preventScrolling, selectionLayerLevel) {
        serverContext.lastSelectionData = {row: row, column: column, row2: row2, column2: column2};
    }

    function getResults(result) {
        serverContext.serverData = result;
        let data = [];
        for (let r = 0; r < result.cells?.length; r++) {
            let row = [];
            for (let c = 0; c < result.cells[r].length; c++) {
                if (result.cells[r][c]) {
                    if (result.cells[r][c].si) {
                        let style = result.styles[result.cells[r][c].si];
                        if (style && style.format) {
                            result.cells[r][c].t = style.format;
                        }
                    }
                    if (result.cells[r][c].s) {
                        row.push(result.cells[r][c].s);
                    } else {
                        row.push(null);
                    }
                } else {
                    row.push(null);
                }
            }
            data.push(row);
        }
        setData(data);
        if (result.colHeaders) {
            setColHeaders(result.colHeaders);
        }
        if (result.rowHeaders) {
            setRowHeaders(result.rowHeaders);
        }
        if (result.hiddenRows) {
            var firstRow = result.rowHeaders ? result.rowHeaders[0] : 0;
            var hidden = [];
            for (var i = 0; i < result.hiddenRows.length; i++) {
                hidden.push(result.hiddenRows[i] - firstRow);
            }
            setHiddenRows({rows: hidden});
        } else {
            setHiddenRows(null);
        }
        if (result.colWidths) {
            setColWidths(result.colWidths);
        }
        setWorksheetID && setWorksheetID(result.worksheetID);
    }

    useEffect(() => {
        // Overwrite what is being copied to the clipboard.
        document.addEventListener('copy', function (e) {
            serverContext.lastCopySelection = serverContext.lastSelectionData;
        });
        const getSheet = async () => {
            setLoading(true);
            try {
                const action = {
                    "action": "getSheet",
                    "sheetRange": sheetRange,
                    "aggregation": isBlending === false && true
                }
                if (activePrompts?.length > 0) {
                    const validPrompts = []
                    activePrompts.forEach(prompt => {
                        if (Array.isArray(prompt)) {
                            prompt.forEach(p => {
                                if (p.filterValue.values.length > 0 && prompt.filterValue.values[0] !== 'All') validPrompts.push(p)
                            })
                        } else {
                            if (prompt.filterValue.values.length > 0 && prompt.filterValue.values[0] !== 'All') validPrompts.push(prompt)
                        }
                    })
                    action.prompts = packFilter(validPrompts)
                }
                await serverContext.server.sheetPostData(action, getResults);
            } catch (e) {
                setError && setError('An error occurred while trying to load the sheetlet. Please try again later.')
            } finally {
                setLoading(false);
            }
        }
        if (!serverContext.serverData || !serverContext.serverData.data) {
            getSheet();
        }
    }, [serverContext, sheetRange]);

    return (
        <>
            {!loading &&
                <div style={{
                    pointerEvents: activeMode === 'edit' ? 'none' : '',
                    transform: presentationScale ? `scale(${presentationScale})` : '',
                    transformOrigin: 'top left',
                    height: controlledHeight ? 'auto' : '100%',
                    width: '100%'
                }}>
                    <HotTable
                        ref={hotTableComponent}
                        data={data}
                        rowHeaders={suppressHeaders ? false : (rowHeaders ? rowHeaders : true)}
                        colHeaders={suppressHeaders ? false : (colHeaders ? colHeaders : true)}
                        width={'100%'}
                        colWidths={colWidths}
                        height={embeddedSizeProps?.height || '400px'}
                        licenseKey="4f426-71673-ae630-24549-4580d"
                        afterChange={afterChange}
                        afterPaste={afterPaste}
                        afterSelection={afterSelection}
                        renderer={scoopTextRenderer}
                        manualColumnResize={true}
                        manualRowResize={true}
                        hiddenRows={hiddenRows}
                        editor={ScoopEditor}
                        afterGetColHeader={(col, TH) => {
                            if (col === -1 && cornerHTML) {
                                TH.innerHTML = cornerHTML;
                            }
                        }}
                    />
                </div>
            }
            {
                loading &&
                <Box sx={{display: 'flex', width: '100%', justifyContent: 'center'}}>
                    <ScoopLoader size={56}/>
                </Box>
            }
        </>
    );
}
