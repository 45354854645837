import {Box, Tooltip, Typography} from "@mui/material";
import Button from "../../../common/Button/Button";
import Info from "../../../../assets/icons/Info.svg";
import React, { useState } from "react";
import { Database } from "../../../screens/Appconnect/Database/Database";
import { Hubspot } from "../../Appconnect/Hubspot/Hubspot";
import { Toast } from "../../../common/Toast/Toast";
import { useSelector } from "react-redux";
import { useApi } from '../../../../api/api';
import LinearProgress from '@mui/material/LinearProgress';
import { ConfigDialog } from "../ConfigDialog";

export const DatasetCard = ({ dataset, server, refreshInbox }) => {
    const [openConfigure, setOpenConfigure] = useState(false);
    const [openAdvanced, setOpenAdvanced] = useState(false);
    const [alert, setAlert] = useState(null);
    const userID = useSelector((state) => state.auth.userID);
    const { postData } = useApi("https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/botactions");
    const [isLoading, setIsLoading] = useState(false);

    if (!dataset) return null;

    const runBot = async () => {
        const action = {
            "action": "runBot",
            "inboxID": dataset.inboxID,
            "userID": userID,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        try {
            const result = await postData(action);
            return result;
        } catch (error) {
            console.error('RunBot error:', error); // Debug log
            setAlert({
                message: 'The process is taking some time, your new data will become available soon.',
                severity: 'info'
            })
            setIsLoading(false);
            return null;
        }
    };

    const handleRunBot = () => {
        setIsLoading(true);
        runBot().then((results) => {
            if (results?.status === 'success') {
                setAlert({ message: 'App Connector ran successfully!', severity: 'success' });
            } else if (results?.status === 'error') {
                setAlert({
                    message: 'The process is taking some time, your new data will become available soon.',
                    severity: 'info'
                });
            } else if (!results) {
                // Handle the case where the API call failed and returned null
                setAlert({
                    message: 'The process is taking some time, your new data will become available soon.',
                    severity: 'info'
                })
            }
            setIsLoading(false);
        });
    };

    const getDatasetType = () => {
        switch (dataset.inboxType) {
            case 'robot':
                return dataset.botService ? 'Data Connector' : 'File Upload'
            case 'calculated':
                return 'Blended'
            default:
                return 'Email'
        }
    }

    const items = [
        { label: 'Description', value: dataset?.description },
        { label: 'Source', value: getDatasetType() },
        { label: 'Type', value: dataset?.transactional ? 'Transactional' : 'Snapshot' },
        { label: 'Scoop Frequency', value: 'Daily' }
    ];

    return (
        <>
            <Box sx={{
                    backgroundColor: '#FFFFFF',
                    borderRadius: '5px',
                    padding: '4px 0',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <div style={{display: 'flex', gap: '64px', alignItems: 'center', padding: '12px'}}>
                    {items.map((item, index) => (
                        item.value &&
                            <div key={index} style={{display: 'flex', gap: '16px'}}>
                                <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
                                    <Typography sx={{fontSize: '12px', color: '#635566'}}>
                                        {item.label}
                                    </Typography>
                                    <Tooltip aria-label="info" title="">
                                        <img src={Info} alt="info"/>
                                    </Tooltip>
                                </div>
                                <Typography sx={{textAlign: 'center'}} variant="body2" component="p">
                                    {item.value}
                                </Typography>
                            </div>
                    ))}
                </div>
                <div style={{marginRight: '8px'}}>
                    {items[0].value === 'Data Connector' &&
                        <Button className={'small button-grey'} sx={{ backgroundColor: '#F2F2F2', marginLeft: '8px' }}
                            onClick={handleRunBot}>Extract data now</Button>
                    }
                    {!dataset.readOnly &&
                        <Button className={'small button-grey'} sx={{ backgroundColor: '#F2F2F2', marginLeft: '8px' }} onClick={() => {
                            setOpenConfigure(true);
                        }}>Configure</Button>
                    }
                    {isLoading &&
                        <LinearProgress variant="indeterminate" style={{ marginTop: '20px', height: '10px' }} />
                    }
                </div>
            </Box>
            {dataset.inboxType === 'robot' && dataset.botService === 'R19' && openAdvanced &&
                <Database
                    open={openAdvanced}
                    onClose={() => setOpenAdvanced(false)}
                    initialDatasetDetails={dataset}
                    afterSave={() => setOpenConfigure(false)}
                    setAlert={setAlert}
                    stepBack={() => {
                        setOpenAdvanced(false);
                        setOpenConfigure(true);
                    }}
                />
            }
            {dataset.inboxType !== 'robot' && openConfigure &&
                <ConfigDialog
                    isEmailSource={dataset.inboxType === 'email'}
                    isTransactional={dataset.transactional}
                    server={server}
                    isEditing={true}
                    inbox={dataset}
                    open={openConfigure}
                    onClose={() => setOpenConfigure(false)}
                    refreshInbox={refreshInbox}
                />
            }
            {items[0].value === 'Data Connector' && dataset.botService === 'R19' && openConfigure &&
                <ConfigDialog
                    isTransactional={dataset.transactional}
                    isEditing={true}
                    inbox={dataset}
                    open={openConfigure}
                    goToAdvance={() => {
                        setOpenAdvanced(true);
                        setOpenConfigure(false);
                    }}
                    onClose={() => setOpenConfigure(false)}
                    refreshInbox={refreshInbox}
                    server={server}
                />
            }
            {items[0].value === 'Data Connector' && dataset.botService === 'R17' &&
                <Hubspot
                    open={openConfigure}
                    onClose={() => setOpenConfigure(false)}
                    initialDatasetDetails={dataset}
                    afterSave={() => setOpenConfigure(false)}
                    setAlert={setAlert}
                />
            }
            {alert && alert.message &&
                <Toast
                    alert={alert}
                    onClose={() => setAlert(null)}
                />
            }
        </>
    );
};
