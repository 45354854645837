import {useNavigate, useParams} from "react-router-dom";
import {
    Box,
    Chip,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Typography
} from "@mui/material";
import ScoopHistory from "./ScoopHistory";
import React, {useEffect, useMemo, useState} from "react";
import './DatasetInsight.css';
import {ROUTES} from "../../../../router/routes";
import CaretRight from "../../../../assets/icons/CaretRight.svg";
import Button from "../../../common/Button/Button";
import Trash from "../../../../assets/icons/Trash.svg";
import {useSelector} from "react-redux";
import {Server} from "../../../../api/Server";
import {useWorkspaceMetadata} from "../../../../hooks/useGetWorkspaceMetadata";
import Dialog from "../../../common/Dialog/Dialog";
import {Toast} from "../../../common/Toast/Toast";
import {TablesInsight} from "./TablesInsight";
import {CalculatedColumns} from "./CalculatedColumns";
import EditCalculatedSource from "./EditCalculatedSource";
import {Tab as CustomTab} from "../../../common/Tab/Tab";
import {DatasetCard} from "./DatasetCard";
import {PreviewTab} from "./PreviewTab";

const styles = {
    tab: {
        textTransform: 'none',
        color: 'grey',
    },
    selectedTab: {
        textTransform: 'none',
        color: 'red',
    },
    unselectedTab: {
        textTransform: 'none',
        color: 'grey',
    },
    indicator: {
        backgroundColor: 'red',
    },
    chip: {
        marginLeft: 8,
        borderRadius: 4,
        height: 'fit-content',
        padding: '1px 0',
    },
};

const TabPanel = (props) => {
    const {children, value, index, ...other} = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tab-panel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={3}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export const DatasetInsight = () => {

    const {inboxID} = useParams();
    const [value, setValue] = useState(0);
    const navigate = useNavigate()
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);
    const server = new Server(workspaceID, userID, token);
    const [sourceContent, setSourceContent] = useState(null);
    const [sourceDetails, setSourceDetails] = useState({});
    const [openReprocess, setOpenReprocess] = useState(false);
    const {metadata, refresh: getMetadata} = useWorkspaceMetadata(userID, workspaceID, token);
    const [alert, setAlert] = useState(null);
    const [hasBeenLoading, setHasBeenLoading] = useState(false);
    const [processStatus, setProcessStatus] = useState(null);
    const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
    const [singleQuery, setSingleQuery] = useState(false);

    useEffect(() => {
        checkStatus()
    }, [])

    useEffect(() => {
        if (processStatus) {
            let status = Array.from(processStatus.values());
            let isLoading = false;
            status.forEach(s => {
                if (s.curStep === -1 && inboxID === s.inboxID)
                    isLoading = true;
            });

            if (isLoading) {
                if (!hasBeenLoading) {
                    setHasBeenLoading(true);
                }
                setAlert({message: "Processing started. Stay tuned.", severity: "info"});
                window.setTimeout(checkStatus, 10000);
            } else if (hasBeenLoading && status.length > 0) {
                setAlert({message: "Processing completed!", severity: "success"});
            }
        }
    }, [processStatus]);

    useEffect(() => {
        handleSourceContent(inboxID)
    }, [inboxID, userID, workspaceID, token]);

    useEffect(() => {
        metadata?.inboxes?.find((inbox) => {
            if (inbox.inboxID === inboxID) {
                setSourceDetails(inbox);
                if (inbox.readOnly) {
                    setValue(1);
                }
            }
        });
    }, [metadata, inboxID])

    const refreshInbox = async () => {
        const metadata = await getMetadata()
        metadata?.inboxes?.find((inbox) => {
            if (inbox.inboxID === inboxID) {
                setSourceDetails(inbox);
                if (inbox.readOnly) {
                    setValue(1);
                }
            }
        });
    }
    const handleDelete = () => {
        const server = new Server(workspaceID, userID, token)
        setConfirmDeleteOpen(false);
        server.postData({
                "action": "delete",
                "inboxID": inboxID,
                "deleteAll": true
            }, (response) => handleAfterDelete(response),
            {},
            (response) => handleError(response));
    }
    const handleAfterDelete = (response) => {
        setAlert({message: response.message ?? 'Dataset deleted successfully', severity: 'success'})
        setTimeout(() => navigate(ROUTES.SOURCES), 5000)
    }
    const handleError = (response) => {
        setAlert({message: response.message ?? 'There was an error deleting the Dataset', severity: 'error'})
    }
    const checkStatus = () => {
        const server = new Server(workspaceID, userID, token)
        if (server.token) {
            server.postData({
                "action": "getProcessStatus",
                "workspaceID": workspaceID,
            }, processStatusResult);
        } else {
            console.log("checkStatus() called, but server.token is null");
        }
    };
    const handleReprocess = () => {
        setOpenReprocess(false)
        const server = new Server(workspaceID, userID, token)
        server.sheetPostData({
            "action": "reProcess",
            "inboxID": inboxID
        }, () => {
            checkStatus();
        });
    };
    const processStatusResult = (result) => {
        const psMap = new Map();
        for (let i = 0; i < result.inboxStatus.length; i++) {
            psMap.set(result.inboxStatus[i].inboxID, result.inboxStatus[i]);
        }
        setProcessStatus(psMap);
    };
    const handleSourceContent = (inboxID) => {
        setTimeout(() => {}, 500);
        if (!inboxID || !workspaceID || !userID || !token) return;
        const server = new Server(workspaceID, userID, token);
        server.postData({
            "action": "getIngestionMetadata",
            "inboxID": inboxID,
        }, (res) => {
            setSourceContent(res)
        });
    }
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleBuildTabs = useMemo(() => {
        let tabs = [];
        tabs.push({key: 0, label: 'Scoop History'})
        tabs.push({
                key: 1,
                label:
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        Tables
                        <Chip label={sourceDetails?.tables?.length} size="small" style={styles.chip}/>
                    </div>
        })
        if (sourceDetails?.tables?.length > 0 && sourceDetails?.inboxType !== 'calculated') {
            tabs.push({key: 2, label: 'Calculated Columns'})
            tabs.push({key: 4, label: 'Preview'})
        }
        if (sourceDetails?.inboxType === 'calculated' && sourceDetails?.calculatedWorksheetID) {
            tabs.push({key: 3, label: 'Modify Calculated Source'})
            tabs.push({key: 5, label: 'Preview'})
        }
        return tabs;
    }, [sourceContent, sourceDetails])

    return (
        <>
            <div className={'dataset-insights-container'}>
                <div style={{width: '100%'}}>
                    <div className={'dataset-insights-topnav'}>
                        <Box className={'sub-nav-actions'} sx={{justifyContent: 'flex-start'}}>
                            <Typography
                                className={'sub-nav-canvas-name'}
                                onClick={() => navigate(ROUTES.SOURCES)}
                                sx={{color: '#979099', fontSize: '14px'}}
                            >
                                Datasets
                            </Typography>
                            <img src={CaretRight} alt={'CaretRight'}/>
                            <Typography
                                className={'sub-nav-canvas-name'}
                                sx={{color: '#2B1630', fontSize: '14px'}}
                            >
                                {sourceContent?.inboxLabel}
                            </Typography>
                        </Box>
                        <div>
                            {!sourceDetails.readOnly &&
                                <Button
                                    className={'button-purple small'}
                                    sx={{whiteSpace: 'noWrap', minWidth: 'fit-content'}}
                                    onClick={() => setOpenReprocess(true)}
                                >
                                    Reprocess Data
                                </Button>
                            }
                            {!sourceDetails.readOnly &&
                                <Button
                                    className={'icon-button'}
                                    onClick={() => setConfirmDeleteOpen(true)}
                                >
                                    <img style={{height: '20px'}} src={Trash} alt={'delete'} />
                                </Button>
                            }
                        </div>
                    </div>
                </div>
                <div style={{display: 'flex', gap: '64px', alignItems: 'center', padding: '8px 0', width: '100%'}}>
                    <DatasetCard server={server} dataset={sourceDetails} refreshInbox={refreshInbox}/>
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: '24px',
                    width: '100%',
                    flexGrow: 1,
                }}>
                    <div className={'dataset-tabs-container'}>
                        <Box sx={{width: '100%', height: '100%', overflow: 'auto'}}>
                            <Box sx={{width: '1000px', maxWidth: '90%'}}>
                                <CustomTab
                                        tabs={handleBuildTabs}
                                        value={value}
                                        onChange={(e, value) => handleChange(e, value)}
                                    />
                            </Box>
                            <Box sx={{maxHeight: '65vh'}}>
                                <TabPanel value={value} index={0}>
                                    {
                                        sourceContent?.ingestions &&
                                        <ScoopHistory
                                            logs={sourceContent?.ingestions}
                                            tableName={sourceContent?.inboxLabel}
                                            inboxID={inboxID}
                                            refreshLogs={handleSourceContent}
                                            tables={sourceDetails?.tables}
                                            setOpenReprocess={setOpenReprocess}
                                            dataset={sourceDetails}
                                        />
                                    }
                                </TabPanel>
                                <TabPanel value={value} index={1}>
                                    {sourceDetails?.tables &&
                                        <TablesInsight
                                            getMetadata={getMetadata}
                                            inboxID={inboxID}
                                            readOnly={sourceDetails?.readOnly}
                                            tables={sourceDetails?.tables}
                                            datasetName={sourceDetails?.label}
                                            setAlert={setAlert}
                                        ></TablesInsight>}
                                </TabPanel>
                                {
                                    sourceDetails?.tables &&
                                    <TabPanel value={value} index={2}>
                                        <CalculatedColumns inboxID={inboxID} setConfirm />
                                    </TabPanel>
                                }
                                {
                                    <TabPanel value={value} index={3}>
                                        <EditCalculatedSource inboxID={inboxID} singleQuery={singleQuery} setSingleQuery={setSingleQuery} />
                                    </TabPanel>
                                }
                                {
                                    <TabPanel value={value} index={4}>
                                        <PreviewTab value={value} inboxID={inboxID} server={server} singleQuery={singleQuery} />
                                    </TabPanel>
                                }
                                {
                                    <TabPanel value={value} index={5}>
                                        <PreviewTab value={value} inboxID={inboxID} server={server} singleQuery={singleQuery}/>
                                    </TabPanel>
                                }
                            </Box>
                        </Box>
                    </div>
                </div>
            </div>
            <Dialog onClose={() => setOpenReprocess(false)} open={openReprocess} title={'Reprocess Data'} actions={
                <>
                    <Button className={'button-grey small'} onClick={() => setOpenReprocess(false)}>Cancel</Button>
                    <Button className={'button-purple small'} onClick={handleReprocess}>Yes, Reprocess</Button>
                </>
            }>
                This may take some minutes and you won’t have access to your data in the meantime. Are you sure you want
                to reprocess this dataset?
            </Dialog>
            <Dialog open={confirmDeleteOpen} onClose={() => setConfirmDeleteOpen(false)} metadata={metadata}>
                <DialogTitle>Are you sure?</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {"Are you sure that you want to delete this dataset from Scoop?"}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        className={'small'}
                        onClick={() => setConfirmDeleteOpen(false)}>Cancel</Button>
                    <Button
                        className={'button-purple small'}
                        onClick={() => {
                            handleDelete();
                        }}>OK</Button>
                </DialogActions>
            </Dialog>
            <Toast alert={alert} onClose={() => setAlert(null)}></Toast>

        </>
    )
}
