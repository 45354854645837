import {useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {
    Box,
    FormControlLabel,
    FormGroup,
    Typography
} from "@mui/material";
import Button from "../../../common/Button/Button";
import Sheetlet from "../../../Source/Sheetlet";
import {Switch} from "../../../common/Switch/Switch";
import {renderToString} from "react-dom/server";
import {ServerContext} from "../../../Source/SheetState";
import {Server} from "../../../../api/Server";
import {EmptyCalculatedColumns} from "./EmptyCalculatedColumns";
import googleSheetsIcon from "../../../../assets/icons/GoogleSheets.svg";

export const CalculatedColumns = ({inboxID}) => {
    const userID = useSelector((state) => state.auth.userID);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const token = useSelector((state) => state.auth.token);
    const [previewData, setPreviewData] = useState(null);
    const [initialized, setInitialized] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentServerContext, setCurrentServerContext] = useState(null);
    const [worksheetID, setWorksheetID] = useState(null)
    const [sheetRange, setSheetRange] = useState({
        "sheetType": "CalculatedField",
        "advanced": false,
        "inboxID": inboxID
    });

    useEffect(() => {
        setCurrentServerContext(new ServerContext(new Server(workspaceID, userID, token)));
    }, [workspaceID, userID, token]);

    useEffect(() => {
        if (!initialized && currentServerContext?.server) {
            sheetRange.inboxID = inboxID;
            setSheetRange({...sheetRange});
            setLoading(true);
            setInitialized(true);
        }
    }, [initialized, currentServerContext]);

    const handleSave = () => {
        try {
            currentServerContext.server.sheetPostData({
                "action": "flushWorksheetCache",
                "sheetRange": {
                    "inboxID": inboxID
                }
            }, () => {
            });
        }catch (e) {
            console.error(e)
        }
        setPreviewData(null);
        setInitialized(false);
    }

    return (
        <Box sx={{width: '100%', height: '100%'}}>
            {!previewData && !loading ?
                    <EmptyCalculatedColumns inboxID={inboxID}/>
                    :
                    currentServerContext &&
                    <Box sx={{height: '100%'}}>
                         <div>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                width: '100%',
                                gap: '16px',
                                marginBottom: '16px'
                            }}>
                                <Typography sx={{flex: '2', color: '#635566', fontSize: '14px',}}>
                                    Create and edit custom calculated columns that can be added to your raw report source
                                    data. You can also choose to not include columns from your raw reports.
                                </Typography>
                                <Box sx={{display: 'flex', alignItems: 'center', gap: '16px'}}>
                                    <FormGroup>
                                        <FormControlLabel
                                            sx={{color: 'text.secondary', padding: '10px'}}
                                            control={
                                                <Switch
                                                    checked={sheetRange.advanced}
                                                    onChange={(event) => {
                                                            sheetRange.advanced = !sheetRange.advanced;
                                                            setSheetRange({...sheetRange});
                                                        }}/>
                                            }
                                            label="Advanced"
                                        />
                                    </FormGroup>
                                    <Box sx={{flex: '1'}}>
                                        <Button sx={{float: 'right'}} className={'button-purple small'}
                                                onClick={handleSave}>Save Changes</Button>
                                    </Box>
                                </Box>
                            </Box>
                            <Sheetlet
                                controlledHeight={false}
                                setWorksheetID={setWorksheetID}
                                serverContext={currentServerContext} sheetRange={sheetRange}
                                      locked={!sheetRange.advanced}
                                      embeddedSizeProps={{height: 'auto'}}
                                      cornerHTML={worksheetID ? '<a href="https://docs.google.com/spreadsheets/d/' + worksheetID + '" target="_blank">' +
                                          renderToString(<img alt={'Google sheets'} src={googleSheetsIcon}/>) + '</a>' : ''}
                            />
                        </div>
                    </Box>
            }
        </Box>
    );
}
