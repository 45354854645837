import React, {useRef, useState} from "react";
import Checkbox from "../common/Checkbox/Checkbox";
import {Box, IconButton, Menu, MenuItem, Typography} from "@mui/material";
import MoreHorizRoundedIcon from "@mui/icons-material/MoreHorizRounded";

export const WorksheetMetric = ({chartState, setConfig, config, metricCol, handleWorksheetMetricSelect, selected}) => {

    const [open, setOpen] = useState(false)
    const anchor = useRef()

    const setSelectedColumnDate = (dateCol) => {
        const newConfig = {...config}
        newConfig.selectedDates.set(config.worksheetID + ":" + metricCol.columnName, dateCol.columnName);
        chartState.getResults(newConfig);
        setConfig(newConfig);
    }

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: '0 2px 0 8px',
            borderRadius: '5px',
            height: 35,
            ':hover': {backgroundColor: '#F9F9F9'}
        }}>
            <Checkbox
                checked={config.selectedItems.some(it => it.measureName === metricCol.columnName)}
                onClick={() => handleWorksheetMetricSelect(metricCol)}
                size={"medium"}
            />
            <Typography sx={{
                fontSize: 14,
                width: 190,
                ml: 1,
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                flex: 1
            }}>{metricCol.columnName}</Typography>
            {
                selected && config.worksheetColumns.some(col => col.columnType === 'DateTime') &&
                <IconButton
                    ref={anchor}
                    onClick={() => setOpen(true)}
                    sx={{padding: '4px', borderRadius: '5px', border: '1px solid #E6E4E6', color: 'inherit'}}
                >
                    <MoreHorizRoundedIcon fontSize={'small'}/>
                </IconButton>
            }
            <Menu
                open={open}
                anchorEl={anchor.current}
                onClose={() => setOpen(false)}
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                transformOrigin={{vertical: 'top', horizontal: 'left'}}
                sx={{fontSize: '14px', width: '520px', transform: 'translateX(30px)'}}
            >
                {
                    config.worksheetColumns.filter(c => c.columnType === 'DateTime').map((dateCol) => {
                        const selectedDate = config.selectedDates?.get(config.worksheetID + ":" + metricCol.columnName)
                        return (
                            <MenuItem
                                key={dateCol.columnName}
                                sx={{backgroundColor: selectedDate === dateCol.columnName ? '#dad9d9' : ''}}
                                onClick={() => {
                                    setSelectedColumnDate(dateCol)
                                    setOpen(false)
                                }}
                            >
                                by {dateCol.columnName}
                            </MenuItem>
                        )
                    })
                }
            </Menu>
        </Box>
    )
}
