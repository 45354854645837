import React, {useEffect, useState, useCallback, useRef} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {switchWorkspace} from '../store/actions/authActions';
import {useApi} from '../api/api';
import {
    Box,
} from '@mui/material';
import {setResetTrigger} from '../store/actions/uiActions';
import Dialog from './common/Dialog/Dialog';
import Selector from "./common/Selector/Selector";
import Button from "./common/Button/Button";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../router/routes";

// these are hardcoded defaults for when there's a new user and we need to kick off the tour
const DEFAULT_WORKSPACE_NAME = 'Tour Scoop'

function SwitchWorkspaceDialog({shouldOpen, onClose}) {

    const userState = useSelector((state) => state.auth);
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const workspacesAccessible = useSelector((state) => state.auth.workspacesAccessible);
    const defaultTourWorkspace = useSelector((state) => state.auth.defaultTourWorkspace);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const apiPath = isGuestMode ? 'guest-canvasV2' : 'canvasV2';
    const {postData: postCanvasData} = useApi(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`);
    const [selectedWorkspace, setSelectedWorkspace] = useState(userState.workspaceID || '');
    const workspaceIDRef = useRef(selectedWorkspace);
    const selectedWorkspaceRef = useRef(selectedWorkspace);
    const disableClose = workspaceID === null ? true : false;


    const getSortedWorkspaces = () => {
        const temp = [...workspacesAccessible]
        temp.sort((a, b) => a.name.localeCompare(b.name))
        return temp
    }

    // If not detault workspace has been selected (i.e. new user)
    useEffect(() => {
        if (workspacesAccessible.length > 0 && selectedWorkspace === '') {
            const tour = workspacesAccessible.find(w => w.workspaceID === defaultTourWorkspace)
            const tourWorkspace = workspacesAccessible.find(workspace => workspace.name === DEFAULT_WORKSPACE_NAME)
            if (tour) {
                dispatch(switchWorkspace(tour.workspaceID, tour.name));
                setSelectedWorkspace(tour.workspaceID);
                loadObjects(tour.workspaceID);
            } else {
                dispatch(switchWorkspace(tourWorkspace?.workspaceID, DEFAULT_WORKSPACE_NAME));
                setSelectedWorkspace(tourWorkspace?.workspaceID);
                loadObjects(tourWorkspace?.workspaceID);
            }
            dispatch(setResetTrigger());
            onClose();
            navigate(ROUTES.CANVAS_DASHBOARD)
        }
    }, [workspacesAccessible]);

    // To-do figure out why need to code POST call here and can't use the one in useApi.js
    const loadObjects = useCallback(async () => {
        const action = {
            "action": "loadCanvas",
            "userID": userState.userID,
            "workspaceID": selectedWorkspace,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        }
        try {
            const response = await fetch(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`, {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${userState.token}`
                },
                body: JSON.stringify(action),
            });
            const result = await response.json();
            // console.log("loadObjects result: ", result);
            if (result) {
                // console.log("loadObjects result: ", result);
                if (result?.canvas?.canvasObjects?.length > 0) {
                    // console.log("dispatch this state: ", result.canvasObjects);
                    dispatch({
                        type: 'APPLY_LOADED_OBJECTS_STATE',
                        payload: JSON.parse(result.canvas.canvasObjects),
                    });
                } else {
                    dispatch({
                        type: 'APPLY_LOADED_OBJECTS_STATE',
                        payload: [],
                    });
                }
            }
            if (result?.canvas?.canvasID) {
                dispatch({
                    type: 'UPDATE_CANVAS',
                    canvasID: result.canvas.canvasID,
                    canvasName: result.canvas.canvasName || "",
                });
            }
        } catch (error) {
            console.error("Error during API call", error);
        }
    }, [selectedWorkspace, userState.userID, dispatch]);

    useEffect(() => {
        selectedWorkspaceRef.current = selectedWorkspace;
        workspaceIDRef.current = selectedWorkspace;
    }, [selectedWorkspace]);

    useEffect(() => {
        setSelectedWorkspace(userState.workspaceID);
    }, [userState.workspaceID]);

    const handleWorkspaceSelection = (event) => {
        setSelectedWorkspace(event.target.value);
    };

    const inferWorkspaceName = () => {
        const workspace = getSortedWorkspaces().find(ws => ws.workspaceID === selectedWorkspace);
        return workspace ? workspace.name : null;
    }

    const commitWorkspaceSwitch = () => {
        dispatch(switchWorkspace(selectedWorkspaceRef.current, inferWorkspaceName()));
        loadObjects(selectedWorkspaceRef.current);
        dispatch(setResetTrigger());
        onClose();
        navigate(ROUTES.CANVAS_DASHBOARD)
    }

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            {disableClose ? null : (
                <Button onClick={onClose} className={'primary-button'} text={'Cancel'} disabled={disableClose}>
                </Button>
            )}
            <Button onClick={commitWorkspaceSwitch} className={'primary-button button-purple'} text={'Load Workspace'}>
            </Button>
        </Box>
    )

    return (
        <Dialog
            onClose={disableClose ? undefined : onClose}
            open={shouldOpen}
            title="Select Workspace"
            actions={actions}
            fullWidth
            disableBackdropClick={disableClose}
            disableEscapeKeyDown={disableClose}
        >
            <Selector value={selectedWorkspace} onChange={handleWorkspaceSelection} label={'Workspaces'} native>
                {getSortedWorkspaces().map((workspace) => (
                    <option key={workspace.workspaceID} value={workspace.workspaceID}>
                        {workspace.name} ({workspace.description})
                    </option>
                ))}
            </Selector>
        </Dialog>
    );
}


export default SwitchWorkspaceDialog;
