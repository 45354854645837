import React, { useEffect, useState } from "react";
import './Explorer.css'
import { useSelector } from "react-redux";
import {
    Box,
    Drawer,
    IconButton,
    Tooltip,
    Typography
} from "@mui/material";
import FileOpenIcon from "@mui/icons-material/FileOpen";
import SaveIcon from "@mui/icons-material/Save";
import SaveAsIcon from "@mui/icons-material/SaveAs";
import { Server } from "../../../api/Server";
import ChartState, {backwardsFlag} from "../../Insights/ChartState.js";
import { DataTab } from "./DrawerTabs/DataTab";
import { ConfigTab } from "./DrawerTabs/ConfigTab";
import { Insight } from "../../Insights/Insight";
import { SaveDialog } from "../../Insights/SaveDialog";
import { OpenDialog } from "../../Insights/OpenDialog";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import {fixFontScaleFactors, saveInsight} from "../../Objects/Insight/InsightAPI";
import { useApi } from "../../../api/api";
import SaveChangesDialog from "../../common/Dialog/SaveChangesDialog";
import { FilterTab } from "./DrawerTabs/FilterTab";
import {Switch} from "../../common/Switch/Switch";
import ChartPlaceholder from '../../../assets/images/chart-placeholder.svg';
import {ScoopLoader} from "../../common/Spinner/ScoopLoader";
import Button from "../../common/Button/Button";
import Dialog from "../../common/Dialog/Dialog";
import {TimeRangeDialog} from "../../Insights/TimeRangeDialog";
import {StyleTab} from "./DrawerTabs/Style/StyleTab";
import {Tab} from "../../common/Tab/Tab";
import {DEFAULT_CHART_PREFERENCES} from "./DrawerTabs/Style/styleConsts";
import {SORTING} from "../../Insights/consts";
import {ScoopTheme} from "../../Insights/Style";
import {cloneDeep} from "lodash";
import {fixScalingProps} from "./DrawerTabs/FormattingElements/utils";

const drawerSx = {
    width: 300,
    '& .MuiDrawer-root': {
        position: 'absolute'
    },
    '& .MuiPaper-root': {
        position: 'absolute'
    },
    '& .MuiDrawer-paper': {
        width: 300
    }
}

const leftDrawerTabs = [
    { label: 'Data', key: 'data' },
    { label: 'Filters', key: 'filters' },
]

const rightDrawerTabs = [
    { label: 'Properties', key: 'properties' },
    { label: 'Style', key: 'style' },
]

export const Explorer = () => {

    const { state } = useLocation();
    const navigate = useNavigate();
    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const userID = useSelector((state) => state.auth.userID);
    const token = useSelector((state) => state.auth.token);
    const [selectedLeftTab, setSelectedLeftTab] = useState(leftDrawerTabs[0].key);
    const [selectedRightTab, setSelectedRightTab] = useState(rightDrawerTabs[0].key);
    const [server, setServer] = useState(new Server(workspaceID, userID, token));
    const [workspaceMetadata, setWorkspaceMetadata] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(true);
    const [loadOpen, setLoadOpen] = useState(false);
    const [insightToSave, setInsightToSave] = useState(null);
    const [insightResult, setInsightResult] = useState(null);
    const [saveOpen, setSaveOpen] = useState(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [loadingTableInsight, setLoadingTableInsight] = useState(false);
    const [loadingChartInsight, setLoadingChartInsight] = useState(false);
    const [config, setConfig] = useState(state?.editmodeConfig || ChartState.getDefaultConfig());
    const [chartState, setChartState] = useState(new ChartState(server, config, setConfig));
    const [advanced, setAdvanced] = useState(!!config.filter || config.categoryAxis !== 'Time');
    const [analyzeChanges, setAnalyzeChanges] = useState(false);
    const [filterSelectedColumn, setFilterSelectedColumn] = useState(undefined)
    const [filterOpen, setFilterOpen] = useState(undefined)
    const [columnLoading, setColumnLoading] = useState(false);
    const [showResetDialog, setShowResetDialog] = useState(false);
    const [timeRangeDialogOpen, setTimeRangeDialogOpen] = useState(false);
    const [dateFlag, setDateFlag] = useState(false);
    const [isExistingInsight, setIsExistingInsight] = useState(false);
    const [worksheetMetrics, setWorksheetMetrics] = useState([]);
    const { postData } = useApi();

    useEffect(() => {
        const newServer = new Server(workspaceID, userID, token)
        setServer(newServer);
        const newChartState = new ChartState(newServer, config, setConfig)
        if (workspaceMetadata === null && userID !== null) {
            getWorkspaceMetadata(newServer, newChartState);
        } else {
            setChartState(newChartState);
        }
    }, [userID, token, workspaceID]);

    useEffect(() => {
        if (state?.editmodeConfig && chartState.workspaceMetadata) loadFromSavedInsight(config)
    }, [chartState])

    useEffect(() => {
        // update metrics if open from saved insight
        if (config.worksheetID && worksheetMetrics.length === 0 ) {
            const action = {
                action: 'getTimeSeries',
                metrics: [],
                calendarType: config.calendarType,
                period: config.period,
                split: true,
                process: false,
                timeRange: config.timeRange,
                worksheetID: config.worksheetID
            }
            if (config.sheetName) action.sheetName = config.sheetName
            if (config.rangeName) action.rangeName = config.rangeName
            server.postData(action, (r) => {
                setWorksheetMetrics(r.sheetColumns.filter(col => col.isMeasure))
                const newConfig = {...config}
                newConfig.worksheetColumns = r.sheetColumns
                setConfig(newConfig);
            })
        }
    }, [config.worksheetID, server])

    const handleReset = () => {
        chartState.clear()
        setIsExistingInsight(false)
        setInsightToSave(null)
        if (state?.editmodeConfig) navigate('.', {replace: true})
        setShowResetDialog(false)
    }

    function handleSaveInsight() {
        const configToSave = cloneDeep(config)
        configToSave.selectedDates = Object.fromEntries(configToSave.selectedDates)
        if (configToSave.view === 'chart' && chartState.valueAxis) configToSave.metricsOrder = chartState.valueAxis.map(a => ({name: a.name, position: a.position}))
        configToSave.styleOverrides = fixScalingProps(configToSave)
        setInsightToSave({
            insightName: insightResult ? insightResult.insightName : '',
            insightKey: null,
            workspaceID: server.workspaceID,
            savedObject: JSON.stringify(configToSave)
        });
        setSaveOpen(true);
    }

    function handleAdvancedSwitch() {
        setAdvanced(!advanced);
        const newConfig = { ...config }
        newConfig.seriesTypeMap = new Map()
        setConfig(newConfig);
    }

    function getWorkspaceMetadata(server, chartState, callback) {
        server.postData({
            "action": "getWorkspaceMetadata",
        }, (result) => {
            chartState.workspaceMetadata = result;
            setChartState(chartState);
            setWorkspaceMetadata(result);
            callback && callback()
        });
    }

    const loadFromSavedInsight = (insight) => {
        if (!insight) {
            handleReset();
            return;
        }
        if (insight.view === 'chart') setLoadingChartInsight(true)
        if (!(insight.seriesTypeMap instanceof Map)) insight.seriesTypeMap = new Map();
        // START temp backwards stuff
        if (!insight.selectedTableKpis) {
            insight.selectedTableColumns = []
            insight.selectedTableKpis = []
            insight.selectedTables = []
            insight.displayRows = 50
            insight.tableTimeSeries = true
            if (insight.view === 'table') {
                insight.selectedItems.forEach((item) => {
                    if (!insight.selectedTables.includes(item.reportSeriesTableID)){
                        insight.selectedTables.push(item.reportSeriesTableID)
                    }
                    if (!insight.selectedTableColumns.includes(item.kpi) || !insight.selectedTableColumns.includes(item.columnName)) {
                        if (item.columnName) {
                            // Measure
                            workspaceMetadata?.inboxes?.forEach(inbox => {
                                inbox.tables.forEach(table => {
                                    if (table.reportSeriesTableID === item.reportSeriesTableID) {
                                        table.columns.forEach(col => {
                                            if (col.columnName === item.columnName || insight.tableDrillAttributes.includes(col.columnName)) {
                                                insight.selectedTableColumns.push({...col, reportSeriesTableID: table.reportSeriesTableID})
                                            }
                                        })
                                    }
                                })
                            })
                        } else {
                            // KPI
                            workspaceMetadata.kpis.forEach(kpi => {
                                if (kpi.metricName === item.kpi) insight.selectedTableKpis.push(kpi)
                                workspaceMetadata?.inboxes?.forEach(inbox => {
                                    inbox.tables.forEach(table => {
                                        if (table.reportSeriesTableID === kpi.reportSeriesTableID) {
                                            table.columns.forEach(col => {
                                                if (insight.tableDrillAttributes.includes(col.columnName)) {
                                                    insight.selectedTableColumns.push({...col, reportSeriesTableID: table.reportSeriesTableID})
                                                }
                                            })
                                        }
                                    })
                                })
                            })

                        }
                    }
                })
            }
        }
        if (!insight.worksheetColumns) insight.worksheetColumns = []
        if (!insight.styleOverrides) insight.styleOverrides = {...DEFAULT_CHART_PREFERENCES}
        Object.keys(DEFAULT_CHART_PREFERENCES).forEach(o => {
            if (!insight.styleOverrides[o]) insight.styleOverrides[o] = {...DEFAULT_CHART_PREFERENCES[o]}
        })
        if (!insight.styleOverrides.pie || !insight.styleOverrides.donut) {
            insight.styleOverrides.pie = {...DEFAULT_CHART_PREFERENCES.pie}
            insight.styleOverrides.donut = {...DEFAULT_CHART_PREFERENCES.donut}
        }
        if (!insight.styleOverrides.pie.itemStyle) {
            insight.styleOverrides.pie.itemStyle = {...DEFAULT_CHART_PREFERENCES.pie.itemStyle}
            insight.styleOverrides.donut.itemStyle = {...DEFAULT_CHART_PREFERENCES.donut.itemStyle}
        }
        if (!insight.styleOverrides.pie.label) {
            insight.styleOverrides.pie.label = {...DEFAULT_CHART_PREFERENCES.pie.label}
            insight.styleOverrides.donut.label = {...DEFAULT_CHART_PREFERENCES.donut.label}
        }
        if (insight.styleOverrides?.title?.textStyle && !insight.styleOverrides.title.textStyle.fontScaleFactor) fixFontScaleFactors(insight)
        if (!insight.sorting) insight.sorting = SORTING.NAT
        if (!insight.kpiCompareType) {
            insight.kpiCompareType = 'percentage'
            insight.kpiCompareTarget = undefined
        }
        if (insight.worksheetID && !insight.backwardsFlag) {
            insight.backwardsFlag = backwardsFlag
            insight.selectedTableColumns = insight.selectedTableColumns.map((c) => {
                if (c.isMeasure && c.reportSeriesTableID.includes('orphan')) return {...c, worksheetID: insight.worksheetID, rangeName: insight.rangeName}
                else return c
            })
            insight.selectedItems = insight.selectedItems.map((i) => ({
                measureName: i.measureName || i.columnName,
                worksheetID: insight.worksheetID,
                rangeName: insight.rangeName
            })).filter(i => i.measureName)
        }
        if (!insight.metricsOrder) insight.metricsOrder = []
        // END temp backwards stuff
        if (!(insight.selectedDates instanceof Map)) insight.selectedDates = new Map(Object.entries(insight.selectedDates));
        setConfig(insight);
        setIsExistingInsight(true);
        if (insight.view === 'chart') {
            chartState.getResults(
                { ...insight },
                () => {
                    setLoadingChartInsight(false)
                    setLoadingTableInsight(false)
                });
        }
    }

    const handleCreateFilter = (column) => {
        if (!advanced) handleAdvancedSwitch()
        setSelectedLeftTab('filters')
        setFilterOpen(true)
        setFilterSelectedColumn(column)
    }

    const resetFilterState = () => {
        setFilterOpen(undefined)
        setFilterSelectedColumn(undefined)
    }

    const shouldRenderInsight = () => {
        if (config.view === 'table')
            return config.selectedTableColumns.length > 0 || config.selectedTableKpis.length > 0 || config.worksheetID
        if (config.view === 'chart')
            return config.selectedItems.length > 0 && chartState.hasData();
        if (config.view === 'kpi')
            return config.selectedItems.length > 0
        return true
    }

    const getTheme = () => {
        if (config && config.themeID) {
            if (config.themeID === 'defaultTheme') {
                return {
                    themeID: 'defaultTheme',
                    themeName: 'Default theme',
                    colorScheme: {
                        backgroundColor: ScoopTheme.backgroundColor,
                        colors: ScoopTheme.color,
                        darkTheme: false
                    },
                }
            }
            let theme = chartState.getTheme(config.themeID);
            if (theme) return theme;
        }
        return undefined;
    }

    const getSummaryTitle = () => {
        if (config?.insightName && config?.insightName.length > 0) {
            return config.insightName;
        } else if (config.chartTitle && config?.chartTitle.length > 0) {
            return config.chartTitle;
        }
    }

    const getInsightBackgroundClass = () => {
        const theme = getTheme()
        if (shouldRenderInsight() && config.view === 'chart') {
            if (theme) {
                if (theme?.colorScheme?.darkTheme) {
                    return 'insight-container-dark'
                } else {
                    return 'insight-container-light'
                }
            }
        }
        return ''
    }

    const handleSaveInsightChanges = (goBack, config) => {
        const returnToCanvas = !!state?.editmodeConfig
        const configToSave = cloneDeep(config)
        if (configToSave.view === 'chart' && chartState.valueAxis) configToSave.metricsOrder = chartState.valueAxis.map(a => ({name: a.name, position: a.position}))
        configToSave.styleOverrides = fixScalingProps(configToSave)
        saveInsight(
            returnToCanvas ? state?.insightKey : configToSave.insightKey,
            returnToCanvas ? state?.insightName : configToSave.insightName,
            workspaceID,
            userID,
            configToSave,
            postData,
            () => {
                if (goBack) {
                    setConfirmDialogOpen(false);
                    if (returnToCanvas) navigate(ROUTES.CANVAS_DASHBOARD + '/' + state?.canvasID)
                }
            }
        )
    }

    const resetActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={() => setShowResetDialog(false)} className={'primary-button'} text={'Cancel'}/>
            <Button onClick={handleReset} className={'primary-button button-purple'} text={'Reset'}/>
        </Box>
    )

    return (
        <Box className={'screen-container'}>
            <Box className={'explorer-nav'}>
                <Typography className={'inter'} sx={{fontSize: '18px'}}>Explorer</Typography>
                <Typography className={'inter'} sx={{fontSize: '18px'}}>{getSummaryTitle()}</Typography>
                <Box className={'explorer-nav-actions'}>
                    <Tooltip title="Open Saved Insight">
                        <IconButton onClick={() => setLoadOpen(true)}>
                            <FileOpenIcon />
                        </IconButton>
                    </Tooltip>
                    {
                        (
                            config.selectedItems?.length > 0 ||
                            (config.worksheetID && config.rangeName) ||
                            config.selectedTableColumns.length > 0 ||
                            config.selectedTableKpis.length > 0
                        ) &&
                        <Tooltip title="Save Insight As">
                            <IconButton onClick={handleSaveInsight}>
                                <SaveAsIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    {
                        isExistingInsight &&
                        <Tooltip title="Save Insight">
                            <IconButton onClick={() => setConfirmDialogOpen(true)}>
                                <SaveIcon />
                            </IconButton>
                        </Tooltip>
                    }
                    <Tooltip title="Toggle Advanced Options" sx={{ ml: 3 }}>
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '8px',
                            justifyContent: 'space-between'
                        }}>
                            <Typography sx={{fontSize: '12px', fontWeight: 600, mr: '10px'}}>Advanced</Typography>
                            <Switch
                                checked={advanced}
                                onChange={handleAdvancedSwitch}
                                disabled={config.selectedItems?.length === 0 && config.selectedTableColumns.length === 0}
                            />
                        </Box>
                    </Tooltip>
                    {
                        (config.worksheetID || config.selectedItems.length > 0 || config.selectedTableColumns.length > 0 || config.selectedTableKpis.length > 0) &&
                        <Button className={'button-purple reset-insight'} onClick={() => setShowResetDialog(true)}>
                            <Typography className={'inter'} sx={{fontSize: '12px'}}>Reset</Typography>
                        </Button>
                    }
                </Box>
            </Box>
            <Box className={'explorer-content'}>
                <Drawer
                    open={drawerOpen}
                    anchor={"left"}
                    variant={"persistent"}
                    sx={drawerSx}
                >
                    <Tab
                        tabs={leftDrawerTabs}
                        value={selectedLeftTab}
                        onChange={(e, value) => setSelectedLeftTab(value)}
                    />
                    <Box className={'drawer-content'}>
                        {
                            selectedLeftTab === 'data' &&
                            <DataTab
                                config={config}
                                setConfig={setConfig}
                                workspaceMetadata={workspaceMetadata}
                                chartState={chartState}
                                server={server}
                                getWorkspaceMetadata={() => getWorkspaceMetadata(server, chartState)}
                                setWorkspaceMetadata={setWorkspaceMetadata}
                                workspaceID={workspaceID}
                                worksheetMetrics={worksheetMetrics}
                                setWorksheetMetrics={setWorksheetMetrics}
                            />
                        }
                        {
                            selectedLeftTab === 'filters' &&
                            <FilterTab
                                config={config}
                                setConfig={setConfig}
                                chartState={chartState}
                                advanced={advanced}
                                workspaceMetadata={workspaceMetadata}
                                server={server}
                                getWorkspaceMetadata={() => getWorkspaceMetadata(server, chartState)}
                                analyzeChanges={analyzeChanges}
                                initialOpen={filterOpen}
                                initialSelectedColumn={filterSelectedColumn}
                                resetFilterState={resetFilterState}
                                setTimeRangeDialogOpen={setTimeRangeDialogOpen}
                            />
                        }
                    </Box>
                </Drawer>
                <Drawer
                    open={drawerOpen}
                    anchor={"right"}
                    variant={"persistent"}
                    sx={drawerSx}
                >
                    <Tab
                        tabs={rightDrawerTabs}
                        value={selectedRightTab}
                        onChange={(e, value) => setSelectedRightTab(value)}
                    />
                    <Box className={'drawer-content'} sx={{height: 'calc(100% - 48)', paddingBottom: '16px'}}>
                        {
                            selectedRightTab === 'properties' &&
                            <ConfigTab
                                config={config}
                                setConfig={setConfig}
                                chartState={chartState}
                                advanced={advanced}
                                setAdvanced={setAdvanced}
                                analyzeChanges={analyzeChanges}
                                setAnalyzeChanges={setAnalyzeChanges}
                                workspaceMetadata={workspaceMetadata}
                                server={server}
                                getWorkspaceMetadata={() => getWorkspaceMetadata(server, chartState)}
                                columnLoading={columnLoading}
                                setColumnLoading={setColumnLoading}
                                dateFlag={dateFlag}
                                setDateFlag={setDateFlag}
                                setTimeRangeDialogOpen={setTimeRangeDialogOpen}
                            />
                        }
                        {
                            selectedRightTab === 'style' &&
                            <StyleTab
                                config={config}
                                setConfig={setConfig}
                                chartState={chartState}
                                setChartState={setChartState}
                                workspaceMetadata={workspaceMetadata}
                                getWorkspaceMetadata={(callback) => getWorkspaceMetadata(server, chartState, callback)}
                                server={server}
                                handleSaveInsight={(config) => handleSaveInsightChanges(false, config)}
                            />
                        }
                    </Box>
                </Drawer>
                <Box className={`insight-container ${getInsightBackgroundClass()}`}>
                    {
                        (loadingChartInsight || loadingTableInsight) ?
                            <Box sx={{ height: 500, display: 'grid', placeContent: 'center' }}>
                                <ScoopLoader size={56} />
                            </Box> :
                            (
                                shouldRenderInsight() ?
                                <Insight
                                    chartProperties={chartState}
                                    setChartProperties={setChartState}
                                    config={config}
                                    setConfig={setConfig}
                                    server={server}
                                    advanced={advanced}
                                    analyzeChanges={analyzeChanges}
                                    handleCreateFilter={handleCreateFilter}
                                    setColumnLoading={setColumnLoading}
                                    workspaceMetadata={workspaceMetadata}
                                    dateFlag={dateFlag}
                                    theme={getTheme()}
                                    clickable
                                /> :
                                <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%'}}>
                                    <img src={ChartPlaceholder} alt={'chart'} />
                                    <Typography sx={{marginTop: '40px'}}>No data input</Typography>
                                    <Typography sx={{color: '#979099'}}>Select a KPI or Source Metric to get started</Typography>
                                </Box>
                            )
                    }
                </Box>
            </Box>
            <SaveDialog
                open={saveOpen}
                setOpen={setSaveOpen}
                insight={insightToSave}
                setInsight={setInsightToSave}
                workspaceMetadata={workspaceMetadata}
                server={server}
            />
            <OpenDialog
                open={loadOpen}
                setOpen={setLoadOpen}
                setInsight={loadFromSavedInsight}
                workspaceMetadata={workspaceMetadata}
                server={server}
                window={"Explorer"}
                resetState={handleReset}
            />
            <SaveChangesDialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
                handleSave={() => {
                    if (!!state?.editmodeConfig || config.insightKey) handleSaveInsightChanges(true, config)
                }}
                handleDontSave={() => setConfirmDialogOpen(false)}
            />
            <TimeRangeDialog
                open={timeRangeDialogOpen}
                setOpen={setTimeRangeDialogOpen}
                config={config}
                setConfig={setConfig}
                chartProperties={chartState}
            />
            <Dialog
                open={showResetDialog}
                onClose={() => setShowResetDialog(false)}
                actions={resetActions}
                title={'Reset insight'}
            >
                <Typography className={'inter'}>Resetting this insight will delete previous progress</Typography>
            </Dialog>
        </Box>
    )
}
