import {Box, Typography} from "@mui/material";
import React, {useCallback, useEffect, useState} from "react";
import {propExistsOnObject, renderColorPicker, renderSelector} from "../utils";
import {
    AXIS_DEFAULT_VALUES,
    FONT_FAMILIES,
    FONT_WEIGHTS,
    AXIS_TEXT_DEFAULT_VALUES, DEFAULT_CHART_PREFERENCES,
} from "../styleConsts";
import {Slider} from "../../../../../common/Slider/Slider";
import {Switch} from "../../../../../common/Switch/Switch";
import { debounce, cloneDeep } from "lodash";
import Input from "../../../../../common/Input/Input";
import { merge } from "lodash";

export const AxisConfig = ({config, setConfig, axis, chartPreferences, chartState}) => {

    const [tickFontSize, setTickFontSize] = useState(AXIS_TEXT_DEFAULT_VALUES.fontSize);
    const [nameFontSize, setNameFontSize] = useState(AXIS_TEXT_DEFAULT_VALUES.fontSize);
    const [axisLabelRotation, setAxisLabelRotation] = useState(AXIS_TEXT_DEFAULT_VALUES.rotate);
    const [range, setRange] = useState([AXIS_DEFAULT_VALUES.min, AXIS_DEFAULT_VALUES.max])
    const [axisToApplyStyle, setAxisToApplyStyle] = useState(0)
    const [axisOptions, setAxisOptions] = useState([])
    const editingAxis = config.seriesType === 'bar' ? axis === 'xAxis' ? 'yAxis' : 'xAxis' : axis
    const isArray = Array.isArray(config.styleOverrides[editingAxis])

    useEffect(() => {
        if (config.selectedItems.length === 0) return;

        const option = chartState.getOption();
        const newConfig = cloneDeep(config);
        if (Array.isArray(option[axis])) {
            setAxisOptions(buildAxisOptions(option[axis]?.map(axis => axis.name)));
            const newAxis = [];
            option[axis].forEach((_, index) => {
                let axisStyle = cloneDeep(DEFAULT_CHART_PREFERENCES[axis]);
                if (newConfig.styleOverrides[editingAxis]?.[index]) {
                    const toPush = merge({}, axisStyle, newConfig.styleOverrides[editingAxis][index]);
                    newAxis.push(toPush)
                } else {
                    const toPush = merge({}, axisStyle, newConfig.styleOverrides[editingAxis]);
                    newAxis.push(toPush)
                }
            })
            newConfig.styleOverrides[editingAxis] = cloneDeep(newAxis);
            setConfig(newConfig);
        }
    }, [config.selectedItems.length]);

    useEffect(() => {
        setTickFontSize(getAxisTextStylePropValue('fontSize', editingAxis, 'tick'))
        setNameFontSize(getAxisTextStylePropValue('fontSize', editingAxis, 'name'))
        setAxisLabelRotation(getAxisTextStylePropValue('rotate', editingAxis, 'tick'))
    }, [config, axisToApplyStyle])


    const updateAxisStyle = (newConfig, axisName, prop, value, destination, index = 0) => {
        if (destination === 'tick') {
            newConfig.styleOverrides[axisName][index].axisLabel[prop] = value;
            if (prop === 'fontSize') {
                newConfig.styleOverrides[axisName][index].axisLabel.fontScaleFactor = {
                    x: value / (window.innerWidth - 600),
                    y: value / (window.innerHeight - 112)
                };
            }
        } else {
            newConfig.styleOverrides[axisName][index].nameTextStyle[prop] = value;
            if (prop === 'fontSize') {
                newConfig.styleOverrides[axisName][index].nameTextStyle.fontScaleFactor = {
                    x: value / (window.innerWidth - 600),
                    y: value / (window.innerHeight - 112)
                };
            }
        }
    };

    const updateAxisStyleForObject = (newConfig, axisName, prop, value, destination) => {
        if (destination === 'tick') {
            newConfig.styleOverrides[axisName].axisLabel[prop] = value;
            if (prop === 'fontSize') {
                newConfig.styleOverrides[axisName].axisLabel.fontScaleFactor = {
                    x: value / (window.innerWidth - 600),
                    y: value / (window.innerHeight - 112)
                };
            }
        } else {
            newConfig.styleOverrides[axisName].nameTextStyle[prop] = value;
            if (prop === 'fontSize') {
                newConfig.styleOverrides[axisName].nameTextStyle.fontScaleFactor = {
                    x: value / (window.innerWidth - 600),
                    y: value / (window.innerHeight - 112)
                };
            }
        }
    };

    const debouncedFunc = useCallback(
        debounce((prop, value, axisName, destination) => {
            const newConfig = cloneDeep(config)
            if (isArray) {
                if (axisToApplyStyle === 0) {
                    for (let i = 0; i < config.styleOverrides[axisName].length; i++) {
                        if (!destination) {
                            newConfig.styleOverrides[axisName][i][prop] = value;
                        } else {
                            updateAxisStyle(newConfig, axisName, prop, value, destination, i);
                        }
                    }
                } else {
                    if (!destination) {
                        newConfig.styleOverrides[axisName][axisToApplyStyle - 1][prop] = value;
                    } else {
                        updateAxisStyle(newConfig, axisName, prop, value, destination, axisToApplyStyle - 1);
                    }
                }
            } else {
                if (!destination) {
                    newConfig.styleOverrides[axisName][prop] = value;
                } else {
                    updateAxisStyleForObject(newConfig, axisName, prop, value, destination);
                }
            }
            setConfig(newConfig);
        }, 200),
        [config, axisToApplyStyle]
    );

    const buildAxisOptions = (options) => {
        const axisAll = "All";
        return [axisAll, ...options];
    };

    const getAxisPropValue = (prop, axisName) => {
        if (isArray) {
            let index = axisToApplyStyle - 1;
            if (axisToApplyStyle === 0) index = 0
            if (propExistsOnObject(config.styleOverrides[axisName]?.[index], prop)) {
                return config.styleOverrides[axisName][index][prop];
            }
            if (propExistsOnObject(chartPreferences[axisName]?.[index], prop)) {
                return chartPreferences[axisName][index][prop];
            } else if (propExistsOnObject(chartPreferences[axisName], prop)) {
                return chartPreferences[axisName][prop];
            }
            return AXIS_DEFAULT_VALUES[prop];
        } else {
            if (propExistsOnObject(config.styleOverrides[axisName], prop)) {
                return config.styleOverrides[axisName][prop];
            }
            if (propExistsOnObject(chartPreferences[axisName], prop)) {
                return chartPreferences[axisName][prop];
            }
        }
        if (propExistsOnObject(chartPreferences[axisName], prop)) {
            return chartPreferences[axisName][prop];
        }
        return AXIS_DEFAULT_VALUES[prop];
    };

    const handleAxisPropValueChange = (prop, value, axisName) => {
        const newConfig = cloneDeep(config)
        if (isArray) {
            if (axisToApplyStyle === 0) {
                for (let i = 0; i < config.styleOverrides[axisName].length; i++) {
                    newConfig.styleOverrides[axisName][i][prop] = value
                }
            } else {
                newConfig.styleOverrides[axisName][axisToApplyStyle - 1][prop] = value
            }
        }
        else newConfig.styleOverrides[axisName][prop] = value
        setConfig(newConfig)
    }

    const getAxisTextStylePropValue = (prop, axisName, destination) => {
        if (destination === 'tick') {
            if (isArray) {
                let index = axisToApplyStyle - 1;
                if (axisToApplyStyle === 0) index = 0
                if (propExistsOnObject(config.styleOverrides[axisName]?.[index]?.axisLabel, prop)) {
                    return config.styleOverrides[axisName][index].axisLabel[prop];
                }
                if (propExistsOnObject(chartPreferences[axisName]?.[index]?.axisLabel, prop)) {
                    return chartPreferences[axisName][index].axisLabel[prop];
                } else if (propExistsOnObject(chartPreferences[axisName]?.axisLabel, prop)) {
                    return chartPreferences[axisName].axisLabel[prop];
                }
            } else {
                if (propExistsOnObject(config.styleOverrides[axisName]?.axisLabel, prop)) {
                    return config.styleOverrides[axisName].axisLabel[prop];
                }
                if (propExistsOnObject(chartPreferences[axisName]?.axisLabel, prop)) {
                    return chartPreferences[axisName].axisLabel[prop];
                } else if (propExistsOnObject(chartPreferences[axisName]?.axisLabel, prop)) {
                    return chartPreferences[axisName].axisLabel[prop];
                }
            }
        } else {
            if (isArray) {
                let index = axisToApplyStyle - 1;
                if (axisToApplyStyle === 0) index = 0
                if (propExistsOnObject(config.styleOverrides[axisName]?.[index]?.nameTextStyle, prop)) {
                    return config.styleOverrides[axisName][index].nameTextStyle[prop];
                }
                if (propExistsOnObject(chartPreferences[axisName]?.[index]?.nameTextStyle, prop)) {
                    return chartPreferences[axisName][index].nameTextStyle[prop];
                } else if (propExistsOnObject(chartPreferences[axisName]?.nameTextStyle, prop)) {
                    return chartPreferences[axisName].nameTextStyle[prop];
                }
            } else {
                if (propExistsOnObject(config.styleOverrides[axisName]?.nameTextStyle, prop)) {
                    return config.styleOverrides[axisName].nameTextStyle[prop];
                }
                if (propExistsOnObject(chartPreferences[axisName]?.nameTextStyle, prop)) {
                    return chartPreferences[axisName].nameTextStyle[prop];
                }
            }
        }
        return AXIS_TEXT_DEFAULT_VALUES[prop];
    };

    const handleAxisTextStylePropChange = (prop, value, axisName, destination) => {
        const newConfig = cloneDeep(config);
        if (isArray) {
            if (axisToApplyStyle === 0) {
                for (let i = 0; i < config.styleOverrides[axisName].length; i++) {
                    if (destination === 'tick') {
                        newConfig.styleOverrides[axisName][i].axisLabel[prop] = value;
                    } else {
                        newConfig.styleOverrides[axisName][i].nameTextStyle[prop] = value;
                    }
                }
            } else {
                if (destination === 'tick') {
                    newConfig.styleOverrides[axisName][axisToApplyStyle - 1].axisLabel[prop] = value;
                } else {
                    newConfig.styleOverrides[axisName][axisToApplyStyle - 1].nameTextStyle[prop] = value;
                }
            }
        } else {
            if (destination === 'tick') {
                newConfig.styleOverrides[axisName].axisLabel[prop] = value;
            } else {
                newConfig.styleOverrides[axisName].nameTextStyle[prop] = value;
            }
        }
        setConfig(newConfig);
    };

    const handleDebouncedAxisTextStylePropChange = (prop, value, axisName, destination, setter) => {
        debouncedFunc(prop, value, axisName, destination)
        setter(value)
    }

    const handleDebouncedAxisStylePropChange = (prop, value, axisName) => {
        debouncedFunc(prop, value, axisName)
        if (prop === 'min') setRange([value, range[1]])
        else setRange([range[0], value])
    }

    const showAxis = getAxisPropValue('show', editingAxis)

    return (
        <Box className={'column'} sx={{gap: '16px'}}>
            {axisOptions.length > 2 &&
                renderSelector(
                    'Select axis to apply style to',
                    axisOptions[axisToApplyStyle],
                    (v) => setAxisToApplyStyle(axisOptions.indexOf(v)),
                    axisOptions
                )
            }
            <Box className={'column'} sx={{gap: '8px'}}>
                <Box className={'row'} sx={{gap: '12px', alignItems: 'center'}}>
                    <Switch
                        checked={showAxis}
                        onClick={() => handleAxisPropValueChange('show', !showAxis, editingAxis)}
                    />
                    <Typography className={'inter style-config-label'}>Show {axis}</Typography>
                </Box>
            </Box>
            <Box className={'row'} sx={{gap: '8px', alignItems: 'center'}}>
                <Input
                    disableMargin
                    type={'number'}
                    label={'Min'}
                    value={range[0]}
                    onChange={(e) => handleDebouncedAxisStylePropChange('min', e.target.value || undefined, editingAxis)}

                />
                <Input
                    disableMargin
                    type={'number'}
                    label={'Max'}
                    value={range[1]}
                    onChange={(e) => handleDebouncedAxisStylePropChange('max', e.target.value || undefined, editingAxis)}
                />
            </Box>
            {editingAxis !== 'xAxis' &&
                <Box className={'column'} sx={{borderTop: '1px solid #E6E4E6', gap: '16px', padding: '16px 0 0 0'}}>
                    <Typography className={'inter style-config-label'}>Axis name</Typography>
                    {
                        renderSelector(
                            'Font family',
                            getAxisTextStylePropValue('fontFamily', editingAxis, 'name'),
                            (v) => handleAxisTextStylePropChange('fontFamily', v, editingAxis, 'name'),
                            FONT_FAMILIES
                        )
                    }
                    {
                        renderSelector(
                            'Font weight',
                            getAxisTextStylePropValue('fontWeight', editingAxis, 'name'),
                            (v) => handleAxisTextStylePropChange('fontWeight', v, editingAxis, 'name'),
                            FONT_WEIGHTS
                        )
                    }
                    <Slider
                        label={`Font size: ${nameFontSize}px`}
                        value={nameFontSize}
                        min={8}
                        max={64}
                        step={1}
                        onChange={(v) => handleDebouncedAxisTextStylePropChange('fontSize', v, editingAxis, 'name', setNameFontSize)}
                    />
                    {
                        renderColorPicker(
                            'Font color',
                            getAxisTextStylePropValue('color', editingAxis, 'name'),
                            (v) => handleAxisTextStylePropChange('color', v, editingAxis, 'name'),
                        )
                    }
                    {
                        renderColorPicker(
                            'Background color',
                            getAxisTextStylePropValue('backgroundColor', editingAxis, 'name'),
                            (v) => handleAxisTextStylePropChange('backgroundColor', v, editingAxis, 'name'),
                        )
                    }
                </Box>
            }
            <Box className={'column'} sx={{borderTop: '1px solid #E6E4E6', gap: '16px', padding: '16px 0 0 0'}}>
                <Typography className={'inter style-config-label'}>Axis label</Typography>
                {
                    renderSelector(
                        'Font family',
                        getAxisTextStylePropValue('fontFamily', editingAxis, 'tick'),
                        (v) => handleAxisTextStylePropChange('fontFamily', v, editingAxis,'tick'),
                        FONT_FAMILIES
                    )
                }
                {
                    renderSelector(
                        'Font weight',
                        getAxisTextStylePropValue('fontWeight', editingAxis, 'tick'),
                        (v) => handleAxisTextStylePropChange('fontWeight', v, editingAxis,'tick'),
                        FONT_WEIGHTS
                    )
                }
                <Slider
                    label={`Font size: ${tickFontSize}px`}
                    value={tickFontSize}
                    min={8}
                    max={64}
                    step={1}
                    onChange={(v) => handleDebouncedAxisTextStylePropChange('fontSize', v, editingAxis,'tick', setTickFontSize)}
                />
                {
                    renderColorPicker(
                        'Font color',
                        getAxisTextStylePropValue('color', editingAxis,'tick'),
                        (v) => handleAxisTextStylePropChange('color', v, editingAxis, 'tick'),
                    )
                }
                <Slider
                    label={`Rotation: ${axisLabelRotation}°`}
                    value={axisLabelRotation}
                    min={-90}
                    max={90}
                    step={1}
                    onChange={(v) => handleDebouncedAxisTextStylePropChange('rotate', v, editingAxis, 'tick', setAxisLabelRotation)}
                />
            </Box>
        </Box>
    )
}
