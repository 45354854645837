import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    DialogContent,
    InputAdornment,
    Tooltip,
    Typography
} from "@mui/material";
import Input from "../../common/Input/Input";
import React, {useEffect, useState} from "react";
import Dialog from "../../common/Dialog/Dialog";
import Info from "../../../assets/icons/Info.svg";
import Checkbox from "../../common/Checkbox/Checkbox";
import Button from "../../common/Button/Button";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import {ScoopLoader} from "../../common/Spinner/ScoopLoader";
import Copy from "../../../assets/icons/Copy.svg";
import {Toast} from "../../common/Toast/Toast";

export const ConfigDialog = ({
                                 open,
                                 onClose,
                                 inbox,
                                 isEditing,
                                 goToAdvance,
                                 server,
                                 refreshInbox,
                                 isTransactional,
                                 isEmailSource
                             }) => {

    const [datasetName, setDatasetName] = useState('')
    const [datasetDescription, setDatasetDescription] = useState('')
    const [isMostRecent, setIsMostRecent] = useState(false)
    const [isIncremental, setIsIncremental] = useState(false)
    const [isMultipleLoads, setIsMultipleLoads] = useState(false)
    const [workbookRange, setWorkbookRange] = useState(false)
    const [expanded, setExpanded] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [generatedEmail, setGeneratedEmail] = useState('')
    const [alert, setAlert] = useState(null)

    useEffect(() => {
        if (!inbox) return;
        setDatasetName(inbox?.label)
        setDatasetDescription(inbox?.description)
        setIsMostRecent(inbox?.keepOnlyCurrent)
        setIsIncremental(inbox?.incremental)
        setIsMultipleLoads(inbox?.enableMultipleSameDayLoads)
        setWorkbookRange(inbox.workbookRange ? inbox.workbookRange : "")
        isEmailSource && setGeneratedEmail(extractEmailFromInboxName(inbox?.inboxName) || '')
    }, []);

    const handleSave = async () => {
        setIsLoading(true)
        const saveInbox = getToSaveInbox();
        try {
            await server.postData({
                "action": "putObject",
                "class": "scoop.ingest.ReportInbox",
                "value": saveInbox
            }, afterSaveCallback);
        } catch (e) {
            console.error(e)
        } finally {
            setIsLoading(false)
        }
    }

    const getToSaveInbox = () => {
        const toSaveInbox = {...inbox}
        toSaveInbox.tables = undefined;
        toSaveInbox.label = datasetName
        toSaveInbox.description = datasetDescription
        toSaveInbox.keepOnlyCurrent = isMostRecent
        toSaveInbox.incremental = isIncremental
        toSaveInbox.enableMultipleSameDayLoads = isMultipleLoads
        toSaveInbox.workbookRange = workbookRange;
        return toSaveInbox
    }

    const afterSaveCallback = async (result) => {
        if (result && result.result) {
            await refreshInbox();
            onClose();
            resetState();
        }
    }

    const resetState = () => {
        setDatasetName('')
        setDatasetDescription('')
        setIsMostRecent(false)
        setIsIncremental(false)
        setIsMultipleLoads(false)
    }

    const extractEmailFromInboxName = (inboxName) => {
        return inboxName + '@scoopup.report';
    }

    return (
        <>
            <Dialog
                style={{width: '600px'}}
                title={
                    <Typography sx={{color: '#201024', fontSize: '24px', fontWeight: 600}}>
                        {isEditing ? 'Scoop Dataset Properties' : 'Create New Dataset'}
                    </Typography>
                }
                open={open}
                onClose={onClose}
                actions={
                    <>
                        <Button className={'button-grey small'} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button disabled={isLoading} className={'button-purple small'} onClick={() => handleSave()}>
                            {isLoading ? <ScoopLoader size={24}/> : 'Save'}
                        </Button>
                    </>
                }
            >
                <DialogContent sx={{padding: '8px 24px !important'}}>
                    <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
                        <Input
                            value={datasetName}
                            onChange={(e) => setDatasetName(e.target.value)}
                            label={'Dataset name'}
                            size={'small'}
                            padding={'4px 14px'}
                        />
                        <Input
                            padding={'0px'}
                            value={datasetDescription}
                            label={'Dataset description (optional)'}
                            fullWidth
                            multiline
                            rows={4}
                            size={'small'}
                            onChange={(e) => setDatasetDescription(e.target.value)}
                        />
                        {isEmailSource &&
                            <Box>
                                <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 600}}>
                                    Forward the email to the following inbox
                                </Typography>
                                <Box sx={{
                                    display: 'flex',
                                    padding: ' 12px 16px',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    borderRadius: '5px',
                                    background: '#F9F9F9',
                                    fontSize: '14px'
                                }}
                                >
                                    {generatedEmail}
                                    <InputAdornment position="end"
                                                    sx={{cursor: 'pointer'}}
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(generatedEmail).then(r => {
                                                            setAlert({
                                                                message: 'Email copied to clipboard',
                                                                severity: 'success'
                                                            })
                                                        })
                                                    }}
                                    >
                                        <img src={Copy} alt={'copy'} style={{width: '20px'}}/>
                                    </InputAdornment>
                                </Box>
                            </Box>}
                        {isEditing && <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                            <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 600}}>
                                Dataset Type:
                            </Typography>
                            <Box sx={{display: 'flex', alignItems: 'center', gap: '4px'}}>
                                <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 400}}>
                                    {isTransactional ? 'Transactional' : 'Snapshot'}
                                </Typography>
                                <Tooltip title={isTransactional ?
                                    'Accept all new reports as transactions, i.e. as additional records to the ' +
                                    'existing database. All records are cumulative.'
                                    :
                                    'Treat each report as a new snapshot of an existing set of items, ' +
                                    'i.e. each report lists the current state of a set of objects. The unique key in each report ' +
                                    'is used to track changes for each item. Snapshots allow tracking of ' +
                                    'individual items and their state over time.'
                                }>
                                    <img src={Info} alt={'info'}/>
                                </Tooltip>
                            </Box>
                        </Box>}
                        {isEditing ? (
                            <BasicConfig
                                isMostRecent={isMostRecent}
                                setIsMostRecent={setIsMostRecent}
                                isIncremental={isIncremental}
                                setIsIncremental={setIsIncremental}
                                isMultipleLoads={isMultipleLoads}
                                setIsMultipleLoads={setIsMultipleLoads}
                                isTransactional={isTransactional}
                                workbookRange={workbookRange}
                                setWorkbookRange={setWorkbookRange}
                            />
                        ) : (
                            <AccordionConfig
                                isMostRecent={isMostRecent}
                                setIsMostRecent={setIsMostRecent}
                                isIncremental={isIncremental}
                                setIsIncremental={setIsIncremental}
                                isMultipleLoads={isMultipleLoads}
                                setIsMultipleLoads={setIsMultipleLoads}
                                isTransactional={isTransactional}
                                workbookRange={workbookRange}
                                setWorkbookRange={setWorkbookRange}
                                expanded={expanded}
                                setExpanded={setExpanded}
                            />
                        )}
                        {isEditing && goToAdvance &&
                            <Button onClick={goToAdvance} className={'button-grey small'}>
                                Advanced
                            </Button>
                        }
                    </Box>
                </DialogContent>
            </Dialog>
            <Toast alert={alert} onClose={() => setAlert(null)}/>
        </>
    )

}

const BasicConfig = ({
                         isMostRecent,
                         setIsMostRecent,
                         isIncremental,
                         setIsIncremental,
                         isMultipleLoads,
                         setIsMultipleLoads,
                         workbookRange,
                         setWorkbookRange,
                         isTransactional,
                         disableSpreadsheet
                     }) => {
    return (
        <Box sx={{display: 'flex', flexDirection: 'column', gap: '12px'}}>
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 600}}>
                    Most recent only
                </Typography>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <Box>
                        <Checkbox
                            checked={isMostRecent}
                            onClick={() => setIsMostRecent(!isMostRecent)}
                        />
                    </Box>
                    <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 400}}>
                        Scoop will retain only the data from the most
                        recently loaded report. Previous data is deleted.
                    </Typography>
                </Box>
            </Box>
            {!isTransactional &&
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 600}}>
                        Incremental
                    </Typography>
                    <Box sx={{display: 'flex', gap: '8px'}}>
                        <Box>
                            <Checkbox
                                checked={isIncremental}
                                onClick={() => setIsIncremental(!isIncremental)}
                            />
                        </Box>
                        <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 400}}>
                            Scoop will interpret each new report as only the changed rows in the snapshot.
                            It will incorporate these rows with all the unchanged rows
                            from the prior snapshot.
                        </Typography>
                    </Box>
                </Box>}
            <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 600}}>
                    Multiple loads per day
                </Typography>
                <Box sx={{display: 'flex', gap: '8px'}}>
                    <Box>
                        <Checkbox
                            checked={isMultipleLoads}
                            onClick={() => setIsMultipleLoads(!isMultipleLoads)}
                        />
                    </Box>
                    <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 400}}>
                        Normally, if two reports are processed on the same day,
                        Scoop retains only the most recent. This allows all reports on a day to be
                        retained - allowing different parts of a dataset to be sent in different
                        reports.
                    </Typography>
                </Box>
            </Box>
            {!disableSpreadsheet &&
                <Box sx={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                    <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 600}}>
                        Spreadsheet named range
                    </Typography>
                    <Typography sx={{color: '#201024', fontSize: '14px', fontWeight: 400}}>
                        If you are sending a spreadsheet to this dataset, it is a best practice to name the range
                        within the spreadsheet to direct Scoop to the appropriate data. If you are not loading a
                        spreadsheet into this dataset, then this is ignored.
                    </Typography>
                    <Input
                        padding={'0px'}
                        value={workbookRange}
                        fullWidth
                        size={'small'}
                        onChange={(e) => {
                            setWorkbookRange(e.target.value)
                        }}
                    />
                </Box>
            }
        </Box>
    );
}

export const AccordionConfig = ({
                                    isMostRecent,
                                    setIsMostRecent,
                                    isIncremental,
                                    setIsIncremental,
                                    isMultipleLoads,
                                    setIsMultipleLoads,
                                    isTransactional,
                                    workbookRange,
                                    setWorkbookRange,
                                    expanded,
                                    setExpanded,
                                    disableSpreadsheet
                                }) => {
    return (
        <Accordion expanded={expanded}
                   disableGutters
                   sx={{
                       border: 'none',
                       boxShadow: 'none',
                       '&:before': {
                           display: 'none',
                       },
                       '&.Mui-expanded': {
                           margin: 0,
                       }
                   }}
        >
            <AccordionSummary sx={{
                padding: '0',
                margin: '0',
                '&.MuiAccordionSummary-root': {margin: '0 !important', minHeight: 'auto'},
                '& .MuiAccordionSummary-content': {margin: '0 !important'}
            }}
            >
                <Box
                    sx={{display: 'flex', alignItems: 'center'}}
                    onClick={() => setExpanded(!expanded)}
                >
                    <Typography sx={{
                        color: '#201024',
                        fontSize: '14px',
                        fontWeight: 600,
                        flexGrow: 1
                    }}>
                        Extra Configuration
                    </Typography>
                    {expanded ? <ArrowDropDownRoundedIcon/> : <ArrowRightRoundedIcon/>}
                </Box>
            </AccordionSummary>
            <AccordionDetails sx={{height: '100%'}}>
                <BasicConfig
                    isMostRecent={isMostRecent}
                    setIsMostRecent={setIsMostRecent}
                    isIncremental={isIncremental}
                    setIsIncremental={setIsIncremental}
                    isMultipleLoads={isMultipleLoads}
                    setIsMultipleLoads={setIsMultipleLoads}
                    isTransactional={isTransactional}
                    disableSpreadsheet={disableSpreadsheet}
                    workbookRange={workbookRange}
                    setWorkbookRange={setWorkbookRange}
                />
            </AccordionDetails>
        </Accordion>
    )
}