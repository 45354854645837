import React, { useCallback, useEffect, useRef, useState } from "react";
import { Alert, Box, Menu, MenuItem, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useApi } from "../../../api/api";
import { CanvasGrid } from "../../CanvasGrid/CanvasGrid";
import ToolBar from "../../ToolBar/ToolBar";
import SubNav from "../../common/SubNav/SubNav";
import './Canvas.css';
import Button from "../../common/Button/Button";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import { ROUTES } from "../../../router/routes";
import Share from '../../../assets/icons/Share.svg';
import ProjectorScreen from '../../../assets/icons/ProjectorScreen.svg';
import Cursor from '../../../assets/icons/Cursor.svg';
import BoundingBox from '../../../assets/icons/BoundingBox.svg';
import CaretRight from '../../../assets/icons/CaretRight.svg';
import CaretDown from '../../../assets/icons/CaretDown.svg';
import {
    setActiveMode,
    setZoom,
    setBackgroundColor,
    setLastSaved,
    setPresentationID,
    setShowFrameDrawer
} from "../../../store/actions/uiActions";
import Dialog from "../../common/Dialog/Dialog";
import Input from "../../common/Input/Input";
import sha256 from 'crypto-js/sha256';
import { Server } from "../../../api/Server";
import { PromptDrawer } from "../../Objects/Prompt/PromptDrawer";
import { OBJECT_TYPES } from "../../Objects/types";

import { FrameDrawer } from "../../Objects/Frame/FrameDrawer";
import { setGuestPrompts } from "../../../store/actions/authActions";
import { ShareDialog } from "../../ShareDialog";
import {EditMenuDialog} from "../../common/Dialog/EditMenuDialog";
import {
    addArrow,
    addInsight,
    addKPI,
    addSheetlet,
    addTextEditor,
    addProcessDiagram,
    addImage,
    addVideo,
    addPrompt,
    addFrame
} from "../../../store/actions";
import {ScoopLoader} from "../../common/Spinner/ScoopLoader";

export const Canvas = () => {

    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const userID = useSelector((state) => state.auth.userID);
    const token = useSelector((state) => state.auth.token);
    const canvasName = useSelector((state) => state.auth.canvasName);
    const allowedCanvases = useSelector((state) => state.auth.canvases);
    const userFirstName = useSelector((state) => state.auth.userFirstName);
    const activeMode = useSelector((state) => state.ui.activeMode);
    const objects = useSelector(state => state.objects);
    const zoom = useSelector(state => state.ui.zoom);
    const presentationID = useSelector(state => state.ui.presentationID);
    const lastSaved = useSelector(state => state.ui.lastSaved);
    const selectedBackgroundColor = useSelector((state) => state.ui.backgroundColor || '#FFFFFF');
    const currentPrompt = useSelector(state => state.prompt.currentPrompt);
    const passcode = useSelector((state) => state.auth.token);
    const isGuestMode = useSelector((state) => state.auth.isGuestMode);
    const dispatch = useDispatch();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [workspaceMetadata, setWorkspaceMetadata] = useState(null)
    const apiPath = isGuestMode ? 'guest-canvasV2' : 'canvasV2';
    const { postData: postCanvasData } = useApi(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/${apiPath}`);
    const { canvasID } = useParams();
    const [optionsMenuOpen, setOptionsMenuOpen] = useState(false);
    const [renameDialogOpen, setRenameDialogOpen] = useState(false);
    const [reordering, setReordering] = useState(false);
    const [rename, setRename] = useState(canvasName);
    const [itemToAdd, setItemToAdd] = useState(null)
    const [initialCanvasName, setInitialCanvasName] = useState(canvasName)
    const optionsMenuRef = useRef();
    const [checksum, setChecksum] = useState(null) //checksum of the canvas objects
    const userIDRef = useRef(userID); // Ref to hold the current userID
    const checksumRef = useRef(checksum);  // Ref to hold the current checksum
    const fullScreenContainer = useRef();
    const [openShareDialog, setOpenShareDialog] = useState(false);
    const [lastActiveMode, setLastActiveMode] = useState('cursor');
    const [finishLoadObjects, setFinishLoadObjects] = useState(false);

    // if this is a restricted invite, only allow a specific logged user to see this canvas
    const [isGuestAccountRestricted, setIsGuestAccountRestricted] = useState(false);
    const [restrictedGuestAccount, setRestrictedGuestAccount] = useState(null);

    useEffect(() => {
        return () => dispatch(setShowFrameDrawer(false))
    }, [])

    useEffect(() => {
        const handleEscape = (e) => {
            if (e.key === 'Escape') {
                setItemToAdd(null)
            }
        }
        window.addEventListener('keydown', handleEscape)
        return () => window.removeEventListener('keydown', handleEscape)
    }, []);

    const loadObjects = async () => {
        if (userID && workspaceID) {
            if (allowedCanvases.length > 0 && state !== 'newCanvas') {
                if (!allowedCanvases.some( c => c.canvasID === canvasID)) navigate(ROUTES.CANVAS_DASHBOARD)
            } else {
                const action = {
                    "action": "listCanvases",
                    "workspaceID": workspaceID,
                    "userID": userID,
                    "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
                }
                postCanvasData(action).then((r) => {
                    if (!r.some( c => c.canvasID === canvasID)) navigate(ROUTES.CANVAS_DASHBOARD)
                })
            }
            const server = new Server(workspaceID, userID, token)
            if (workspaceMetadata === null) {
                await server.postData({
                    "action": "getWorkspaceMetadata",
                }, (result) => {
                    setWorkspaceMetadata(result);
                });
            }
            const result = await server.postData({
                "action": "loadCanvasWithThemes",
                "userID": userID,
                "workspaceID": workspaceID,
                "canvasID": canvasID,
            }, (result) => {
                if (result) {
                    if (result?.canvas?.canvasObjects.length > 0) {
                        // MAP to fix new prompt min height
                        const objects = result?.canvas?.canvasObjects.map(obj => {
                            if (obj.type === OBJECT_TYPES.PROMPT && obj.height < 100) {
                                return { ...obj, height: 100 }
                            } else {
                                return obj
                            }
                        })
                        if (result.themes?.length > 0) {

                            result.themes.forEach((theme, index) => {
                                let fontsUrl = 'https://fonts.googleapis.com/css2?'
                                theme.fonts.forEach((font, index) => {
                                    // this will need improvement for more complex fonts
                                    fontsUrl = fontsUrl + `family=${font.fontFamily.split(' ').join('+')}`
                                    if (font.variant) {
                                        if (font.variant.includes('italic')) fontsUrl = fontsUrl + ':ital'
                                        const weight = font.variant.replace(/\D/g,'')
                                        if (weight) fontsUrl = fontsUrl + (font.variant.includes('italic') ? ',wght@' : ':wght@') + weight
                                    }
                                    if (index + 1 < theme.fonts.length) fontsUrl = fontsUrl + '&'
                                })
                                const link = document.createElement('link');
                                link.rel = 'stylesheet'
                                link.href = fontsUrl
                                link.id = 'dynamic-loaded-fonts-' + index
                                document.head.appendChild(link)
                            });
                        }
                        dispatch({
                            type: 'APPLY_LOADED_OBJECTS_STATE',
                            payload: objects,
                        });
                    } else {
                        dispatch({
                            type: 'APPLY_LOADED_OBJECTS_STATE',
                            payload: [],
                        });
                    }
                    let newZoom = parseFloat(result.canvas?.zoom)
                    dispatch(setZoom(newZoom || 1))
                    dispatch(setBackgroundColor(result.canvas?.background || '#FFFFFF'))
                    dispatch(setLastSaved(result.canvas?.lastSaved))
                    dispatch(setPresentationID(result.canvas?.presentationID))
                }
                if (result.canvas?.canvasID) {
                    dispatch({
                        type: 'UPDATE_CANVAS',
                        canvasID: result.canvas.canvasID,
                        canvasName: result.canvas.canvasName || "Untitled Canvas",
                    });
                }
                setFinishLoadObjects(true)
            })
        }
    }

    useEffect(() => {
        loadObjects()
    }, [userID, workspaceID, canvasID, workspaceID]);

    useEffect(() => {
        if (userID && !userIDRef.current) {
            // console.log("setting userIDRef.current to ", userID)
            userIDRef.current = userID;
        }
    }, [userID]);

    useEffect(() => {
        const newChecksum = sha256(JSON.stringify(objects)).toString();
        setChecksum(newChecksum);
        checksumRef.current = newChecksum;  // Update the ref whenever checksum state changes
    }, [objects]);

    useEffect(() => {
        const handleEsc = () => {
            if (!document.fullscreenElement) dispatch(setActiveMode(lastActiveMode))
        }
        window.addEventListener('fullscreenchange', handleEsc)
        return () => window.removeEventListener('fullscreenchange', handleEsc)
    }, [lastActiveMode])

    const redeemSharedInvite = useCallback(async (passcode) => {
        const action = {
            "action": "redeemInvite",
            "userID": "guest",
            "passcode": passcode,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        }
        try {
            const response = await fetch('https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/SharedInvite', {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(action),
            });
            const result = await response.json();
            // validate the last logged user is allowed to see this canvas
            if (result.requireAccount) {
                const storedEmail = localStorage.getItem('userEmail');
                if (result.invitedUser !== storedEmail) {
                    setIsGuestAccountRestricted(true);
                    setRestrictedGuestAccount(result.invitedUser);
                }
            }
            return result
        } catch (e) {
            console.error("Error: ", e);
        }
    })

    useEffect(() => {
        if (isGuestMode) {
            redeemSharedInvite(passcode).then(r => {
                dispatch(setGuestPrompts(JSON.parse(r.promptData)))
            })
        }
    }, [isGuestMode]);

    const handleRename = () => {
        setOptionsMenuOpen(false)
        setRenameDialogOpen(true)
        setRename(canvasName)
        setInitialCanvasName(canvasName)
    }

    const handleSaveRename = () => {
        const action = {
            "action": "saveCanvas",
            "userID": userID,
            "workspaceID": workspaceID,
            "canvasID": canvasID,
            "canvasName": rename,
            "canvasObjects": objects,
            "zoom": zoom,
            "presentationID": presentationID,
            "lastSaved": lastSaved,
            "background": selectedBackgroundColor,
            "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
        };
        postCanvasData(action).then(() => loadObjects().then(() => {
            setRenameDialogOpen(false)
        }))
    }

    // probe the server every 20 secs if the user isn't active to see if there are any updates
    const probe = async () => {
        if (userIDRef.current && workspaceID) {
            const action = {
                "action": "probe",
                "userID": userIDRef.current,
                "workspaceID": workspaceID,
                "canvasID": canvasID,
                "checksum": checksumRef.current,
                "isDev": process.env.REACT_APP_SCOOP_ENV === 'dev'
            }
            const result = await postCanvasData(action);
            if (result?.status == 'changed') {
                if (result?.canvasObjects?.length > 0) {
                    window.location.reload()
                }
            }
        }

    }

    const idleIntervalRef = useRef(null);

    const resetIdleInterval = () => {
        clearInterval(idleIntervalRef.current);
        idleIntervalRef.current = setInterval(() => {
            probe(); // Call the probe function here
        }, 20000);
    };

    useEffect(() => {
        const handleUserActivity = () => {
            resetIdleInterval();
        };

        document.addEventListener("mousemove", handleUserActivity, { passive: true });
        document.addEventListener("keydown", handleUserActivity, { passive: true });

        resetIdleInterval();

        return () => {
            clearInterval(idleIntervalRef.current);
            document.removeEventListener("mousemove", handleUserActivity);
            document.removeEventListener("keydown", handleUserActivity);
        };
    }, [userID]);

    const handleShareDialogClose = () => {
        setOpenShareDialog(false);
    };

    const renameActions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={() => {
                setRenameDialogOpen(false)
            }} text={'Cancel'} />
            <Button onClick={handleSaveRename} text={'Save'} className={'button-purple'} disabled={rename === initialCanvasName} />
        </Box>
    )

    const handlePresentation = () => {
        const frames = objects.filter(obj => obj.type === OBJECT_TYPES.FRAME)
        if (frames.some(f => !f.hidden)) {
            setLastActiveMode(activeMode)
            dispatch(setActiveMode('presentation'))
            navigate(`/canvas/${canvasID}/presentation/0`)
            const intervalId = setInterval(() => {
                const elem = document.getElementById('slide-container');
                if (elem && elem.requestFullscreen) {
                    elem.requestFullscreen().catch(err => {
                        console.error("Fullscreen request failed:", err);
                    });
                    clearInterval(intervalId);
                }
            }, 100);
            setTimeout(() => {
                clearInterval(intervalId);
            }, 5000);
        }
    }

    const getGlobalWrapperStyle = () => {
        const result = objects.find(obj => obj?.wrapperStyles?.override === false)?.wrapperStyles;
        if (result) {
            const newStyle = { ...result };
            newStyle.override = true;
            return newStyle;
        }
    }

    const getCenter = (e, deltaX, deltaY) => {
        const rect = document.getElementById('scoop-canvas').getBoundingClientRect();
        const x = (e.clientX - rect.left) / zoom + (deltaX / zoom);
        const y = (e.clientY - rect.top) / zoom + (deltaY / zoom);
        return { x, y };
    };

    const handleClickCanvas = (e) => {
        const wrapperStyles = getGlobalWrapperStyle();
        setItemToAdd(null);
        let x, y;
        let deltaX, deltaY;

        switch (itemToAdd) {
            case 'KPI':
                ({ x, y } = getCenter(e, 0, 0));
                dispatch(addKPI({ zoom, x, y }));
                break;
            case 'Summary':
                deltaX = (700 * zoom) / 2;
                deltaY = (500 * zoom) / 2;
                ({ x, y } = getCenter(e, -deltaX, -deltaY));
                dispatch(addInsight({ zoom, x, y, wrapperStyles }));
                break;
            case 'Sheetlet':
                deltaX = (700 * zoom) / 2;
                deltaY = (500 * zoom) / 2;
                ({ x, y } = getCenter(e, -deltaX, -deltaY));
                dispatch(addSheetlet({ zoom, x, y, wrapperStyles }));
                break;
            case 'Image':
                deltaX = (800 * zoom) / 2;
                deltaY = (500 * zoom) / 2;
                ({ x, y } = getCenter(e, -deltaX, -deltaY));
                dispatch(addImage({ zoom, x, y }));
                break;
            case 'Video':
                deltaX = (800 * zoom) / 2;
                deltaY = (500 * zoom) / 2;
                ({ x, y } = getCenter(e, -deltaX, -deltaY));
                dispatch(addVideo({ zoom, x, y }));
                break;
            case 'Text Editor':
                deltaX = (150 * zoom) / 2;
                deltaY = (50 * zoom) / 2;
                ({ x, y } = getCenter(e, -deltaX, -deltaY));
                dispatch(addTextEditor({ zoom, x, y }));
                break;
            case 'Arrow':
                deltaX = (200 * zoom) / 2;
                deltaY = 0;
                ({ x, y } = getCenter(e, -deltaX, deltaY));
                dispatch(addArrow({ zoom, x, y }));
                break;
            case 'Process Diagram':
                deltaX = (800 * zoom) / 2;
                deltaY = (500 * zoom) / 2;
                ({ x, y } = getCenter(e, -deltaX, -deltaY));
                dispatch(addProcessDiagram({ zoom, wrapperStyles, x, y }));
                break;
            case 'Prompt':
                deltaX = (300 * zoom) / 2;
                deltaY = (100 * zoom) / 2;
                ({ x, y } = getCenter(e, -deltaX, -deltaY));
                dispatch(addPrompt({ zoom, x, y }));
                break;
            case 'Frame':
                deltaX = (1280 * zoom) / 2;
                deltaY = (720 * zoom) / 2;
                ({ x, y } = getCenter(e, -deltaX, -deltaY));
                dispatch(addFrame({ zoom, presentationIndex: objects.filter(obj => obj.type === OBJECT_TYPES.FRAME).length, x, y }));
                break;
        }
    };

    const logout = () => {
        navigate(ROUTES.LOGOUT)
    }

    const shouldRenderCanvas = isGuestMode ?
        objects.filter(o => o.type === OBJECT_TYPES.FRAME).length === 0 :
        (activeMode === 'edit' || activeMode === 'cursor')

    const shouldRenderPresentation = isGuestMode ? !shouldRenderCanvas : activeMode === 'presentation'

    return (
        <Box paddingTop={isGuestMode ? '' : '56px'} sx={{ display: 'flex', flexDirection: 'column', height: '100%', width: '100%' }}>
            {
                !isGuestMode &&
                <SubNav>
                    <Box className={'sub-nav-actions'} gap={'5px'}>
                        <Button className={`sub-nav-small-button ${activeMode === 'cursor' ? 'purple' : ''}`}
                            onClick={() => {
                                dispatch(setActiveMode('cursor'))
                            }}>
                            <img src={Cursor} alt={'Cursor'} />
                        </Button>
                        {!isGuestMode &&
                            <Button className={`sub-nav-small-button ${activeMode === 'edit' ? 'purple' : ''}`}
                                onClick={() => {
                                    dispatch(setActiveMode('edit'))
                                }}>
                                <img src={BoundingBox} alt={'Edit'} />
                            </Button>
                        }
                        <Button className={`sub-nav-small-button ${activeMode === 'presentation' ? 'purple' : ''}`}
                            onClick={handlePresentation}>
                            <img src={ProjectorScreen} alt={'ProjectorScreen'} />
                        </Button>
                        {/*<Button className={`sub-nav-small-button ${activeMode === 'comment' ? 'purple' : ''}`} onClick={() => {*/}
                        {/*    dispatch(setActiveMode('comment'))*/}
                        {/*}}>*/}
                        {/*    <img src={ChatText} alt={'ChatCircle'} />*/}
                        {/*</Button>*/}
                    </Box>
                    <Box className={'sub-nav-actions'}>
                        {!isGuestMode && (
                            <>
                                <Typography
                                    className={'inter sub-nav-canvas-name'}
                                    onClick={() => navigate(ROUTES.CANVAS_DASHBOARD)}
                                    sx={{ color: '#979099' }}
                                >
                                    Canvases
                                </Typography>
                                <img src={CaretRight} alt={'CaretRight'} />
                            </>
                        )}
                        <Typography
                            className={'inter sub-nav-canvas-name'}
                            sx={{ color: '#FCE7EE' }}
                            onClick={() => setOptionsMenuOpen(true)}
                            ref={optionsMenuRef}
                        >
                            {canvasName || 'Loading...'}
                        </Typography>
                        <img src={CaretDown} alt={'CaretDown'} />
                    </Box>
                    {!isGuestMode &&
                        <Box className={'sub-nav-actions'} gap={'16px'}>
                            <Button className={'sub-nav-button '} onClick={() => setOpenShareDialog(true)}>
                                <img src={Share} alt={'Share'} />
                                <Typography className={'inter'}>Share</Typography>
                            </Button>
                        </Box>
                    }
                </SubNav>
            }
            {
                isGuestAccountRestricted ? (
                    <Box display={"flex"} justifyContent={"center"} alignItems={"center"} style={{ height: '100%' }}
                    >
                        <Alert severity="warning" action={
                            <Button color="inherit" size="small" onClick={logout}></Button>
                        }>
                            You must be logged in as {restrictedGuestAccount} to view this Canvas. Please
                            <Button color="primary" onClick={logout}>log in</Button>
                            with (or create) the correct account.
                        </Alert>
                    </Box>
                ) :
                    <Box sx={{ height: isGuestMode ? '100%' : 'calc(100% - 59px)', width: '100%', mt: isGuestMode ? '' : '59px' }} ref={fullScreenContainer} id={'root-canvas-container'}>
                        {
                            shouldRenderCanvas &&
                            <>
                                <Box
                                    onClick={itemToAdd ? handleClickCanvas : () => setItemToAdd(null)}
                                    sx={{
                                        height: '100%',
                                        width: '100%',
                                        overflow: "scroll",
                                        backgroundColor: selectedBackgroundColor, // Only set the background color
                                    }}
                                    id={'canvas-scrollable'}
                                    className={'canvas-scrollable'}
                                >
                                    {
                                        reordering ?
                                        <Box sx={{
                                            height: '100%',
                                            width: '100%',
                                            display: 'grid',
                                            placeContent: 'center'
                                        }}>
                                            <ScoopLoader size={100} />
                                        </Box> :
                                        <CanvasGrid
                                            width={50000}
                                            height={50000}
                                            workspaceID={workspaceID}
                                            workspaceMetadata={workspaceMetadata}
                                            userID={userID}
                                            canvasID={canvasID}
                                            userFirstName={userFirstName}
                                            canvasName={canvasName}
                                            itemToAdd={itemToAdd}
                                            slides={objects?.filter(obj => obj.type === OBJECT_TYPES.FRAME)}
                                            readyToRedirect={finishLoadObjects}
                                        />
                                    }
                                    <PromptDrawer
                                        workspaceMetadata={workspaceMetadata}
                                        currentPromptObject={objects.filter(obj => {
                                            const promptId = currentPrompt ? parseFloat(currentPrompt.split('-')[1]) : ''
                                            return obj.id === promptId
                                        })[0]}
                                        workspaceID={workspaceID}
                                    />
                                    <FrameDrawer reordering={reordering} setReordering={setReordering} />
                                    <EditMenuDialog />
                                </Box>
                                {activeMode === 'edit' && <ToolBar itemToAdd={itemToAdd} setItemToAdd={setItemToAdd}/>}
                            </>
                        }
                    </Box>
            }
            {!isGuestMode &&
                <Menu onClose={() => setOptionsMenuOpen(false)} open={optionsMenuOpen} anchorEl={optionsMenuRef.current} sx={{ marginLeft: 1, marginTop: 1 }}>
                    <MenuItem onClick={handleRename}>Rename</MenuItem>
                </Menu>
            }
            <Dialog open={renameDialogOpen} title={'Rename canvas'} actions={renameActions} onClose={() => {
                setRenameDialogOpen(false)
            }}>
                <Input label={"New canvas name"} value={rename} onChange={(e) => setRename(e.target.value)} />
            </Dialog>
            {openShareDialog && <ShareDialog shouldOpen={openShareDialog} onClose={handleShareDialogClose} />}
        </Box >
    )
}
