import React, { useEffect, useState } from "react";
import './Frame.css';
// import { Modal, Dialog, DialogTitle, DialogContent, LinearProgress } from '@mui/material';
import Dialog from '../../common/Dialog/Dialog';
import DotsSixVerticalGrey from '../../../assets/icons/DotsSixVerticalGrey.svg';
import Eye from '../../../assets/icons/EyeBlack.svg';
import EyeClosed from '../../../assets/icons/EyeClosed.svg';
import RefreshIcon from '../../../assets/icons/SlidesDownloadSync.svg';
import CloudArrowUp from '../../../assets/icons/CloudArrowUp.svg';
import OpenInSlidesIcon from '../../../assets/icons/ArrowSquareOut.svg';
import { Box, Drawer, IconButton, Typography, LinearProgress } from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { useDispatch, useSelector } from "react-redux";
import CloseIcon from "../../../assets/icons/CloseIcon.svg";
import { setShowFrameDrawer } from "../../../store/actions/uiActions";
import { OBJECT_TYPES } from "../types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { APPLY_LOADED_OBJECTS_STATE, updateFrameHidden } from "../../../store/actions";
import { Server } from '../../../api/Server';
import { ScoopLoader } from "../../common/Spinner/ScoopLoader";
import { Switch } from "../../common/Switch/Switch";
import Checkbox from "../../common/Checkbox/Checkbox";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { FrameClass } from "./FrameClass";
import Button from "../../common/Button/Button";
import { isEqual } from "lodash";
import { useApi } from "../../../api/api";


const SUPPORTED_EXPORT_TYPES = ['InsightElement', 'SheetletElement', 'Worksheet', 'Prompt']

export const StrictModeDroppable = ({ children, ...props }) => {
    const [enabled, setEnabled] = useState(false)
    useEffect(() => {
        const animation = requestAnimationFrame(() => setEnabled(true))
        return () => {
            cancelAnimationFrame(animation);
            setEnabled(false);
        };
    }, []);
    if (!enabled) return null
    return <Droppable {...props}>{children}</Droppable>
};


export const FrameDrawer = ({ setReordering }) => {

    const dispatch = useDispatch();
    const objects = useSelector(state => state.objects);
    const presentationID = useSelector(state => state.ui.presentationID);
    const showFrameDrawer = useSelector(state => state.ui.showFrameDrawer);
    const workspaceID = useSelector(state => state.auth.workspaceID);
    const userID = useSelector(state => state.auth.userID);
    const token = useSelector(state => state.auth.token);
    const zoom = useSelector(state => state.ui.zoom);

    const [isLoading, setIsLoading] = useState(false);
    const [server, setServer] = React.useState(new Server(workspaceID, userID, token));
    const [selectedCheckboxes, setSelectedCheckboxes] = React.useState([]);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("warning");
    const [presentationFrames, setPresentationFrames] = useState([]);
    const [originalPresentationFrames, setOriginalPresentationFrames] = useState([]);
    const allSelected = selectedCheckboxes.length === presentationFrames.length;
    const [openSnackbar, setOpenSnackbar] = useState(false);

    const { postData: postThumbnailGenerator } = useApi(`https://pig8gecvvk.execute-api.us-west-2.amazonaws.com/corsair/thumbnailgenerator`);

    // infer canvasID from URL
    const [canvasID, setCanvasID] = useState(window.location.href.split('/canvas/')[1]);

    const [open, setOpen] = useState(false); // Modal visibility
    const [slideProgress, setSlideProgress] = useState(0); // Progress for "Slide"
    const [objectProgress, setObjectProgress] = useState(0); // Progress for "Object"
    const [currentSlide, setCurrentSlide] = useState(1); // Current slide index
    const [totalSlides, setTotalSlides] = useState(1); // Total slides
    const [currentObject, setCurrentObject] = useState(1); // Current object index
    const [totalObjects, setTotalObjects] = useState(1); // Total objects in current slide
    const [cancel, setCancel] = useState(false); // Cancel flag


    useEffect(() => {
        const frames = objects.filter(obj => obj.type === OBJECT_TYPES.FRAME).sort((a, b) => a.presentationIndex - b.presentationIndex)
        setPresentationFrames([...frames])
        setOriginalPresentationFrames([...frames])
    }, [objects]);

    useEffect(() => {
        setServer(new Server(workspaceID, userID, token));
    }, [userID, token, workspaceID]);

    const handleClose = () => {
        dispatch(setShowFrameDrawer(false))
    }

    const onDragEnd = (result) => {
        if (!result.destination) return
        const draggedFrame = presentationFrames.filter(frame => frame.id === parseFloat(result.draggableId.split('-')[1]))[0]
        const offset = result.destination.index - draggedFrame.presentationIndex
        setPresentationFrames((prevState) =>
            prevState.map(frame => {
                if (frame.id === draggedFrame.id) return { ...frame, presentationIndex: result.destination.index }
                if (offset > 0 && frame.presentationIndex > draggedFrame.presentationIndex && frame.presentationIndex <= result.destination.index)
                    return { ...frame, presentationIndex: frame.presentationIndex - 1 }
                if (offset < 0 && frame.presentationIndex < draggedFrame.presentationIndex && frame.presentationIndex >= result.destination.index)
                    return { ...frame, presentationIndex: frame.presentationIndex + 1 }
                return frame
            }).sort((a, b) => a.presentationIndex - b.presentationIndex)
        )
    }

    const handleOpenSlides = (presentationIndex) => {
        const googleSlidesURL = `https://docs.google.com/presentation/d/${presentationID}/edit#slide=id.p${presentationIndex}`
        window.open(googleSlidesURL, '_blank')
    }

    const getSlideIDFromFrameID = (frameID) => {
        if (!(typeof frameID === 'string'))
            return frameID;
        let index = frameID.indexOf(':');
        if (index > 0) {
            return frameID.substring(0, index);
        } else {
            return frameID;
        }
    }

    const doesFrameExist = (presentationIndex) => {
        if (objects.length === 0) return false;
        return (objects.find(obj => obj.type === OBJECT_TYPES.FRAME && obj.presentationIndex === presentationIndex) !== undefined);
    }

    const extractBackground = (content) => {
        const regex = /background:([^;]*)/;
        const match = content.match(regex);
        if (match) {
            const colorValue = match[1].trim();
            const endIndex = colorValue.indexOf("'");
            return endIndex !== -1 ? colorValue.substring(0, endIndex) : colorValue;
        }
        return null;
    };

    const handleSyncSlides = () => {
        if (selectedCheckboxes.length === 0) {
            showSnackbar("Please select at least one frame to sync", "warning");
            return;
        }
        const slidesIDs = selectedCheckboxes.map(frameID => getSlideIDFromFrameID(frameID));
        setIsLoading(true);

        server.postData({
            "action": "getUpdatedSlide",
            "presentationID": presentationID,
            "slideIDs": allSelected ? undefined : slidesIDs
        }, (result) => {
            if (result.error) {
                showSnackbar(result.error, "error");
                setIsLoading(false);
                return;
            }
            let newObjects = [...objects];
            let newFrames = [...objects.filter(obj => obj.type === OBJECT_TYPES.FRAME)];
            let objectsBySlideID = {};

            for (let obj of result.canvasObjects) {
                let objSlideID = Number(obj.id.substring(0, obj.id.indexOf(':')));
                if (!objectsBySlideID[objSlideID]) objectsBySlideID[objSlideID] = [];
                objectsBySlideID[objSlideID].push(obj);
            }

            Object.keys(objectsBySlideID).forEach(slideID => {
                objectsBySlideID[slideID].sort((a, b) => {
                    let aSlideID = Number(a.id.substring(a.id.indexOf(':') + 1));
                    let bSlideID = Number(b.id.substring(b.id.indexOf(':') + 1));
                    return aSlideID - bSlideID;
                });
            });

            Object.keys(objectsBySlideID).forEach(slideID => {
                if (!doesFrameExist(Number(slideID) - 1)) {
                    const object = objectsBySlideID[slideID][0];
                    const background = extractBackground(object.content);
                    newFrames.push(FrameClass.newFrame(
                        zoom,
                        Number(slideID) - 1,
                        '',
                        object.x,
                        object.y,
                        object.width,
                        object.height,
                        `Slide ${slideID}`,
                        background,
                        Number(slideID)
                    ));
                }
            });

            const nonImportedObjects =
                newObjects
                    .filter(obj => !result.canvasObjects
                        .some(canvasObj => canvasObj.id === obj.id));

            newObjects = [...nonImportedObjects, ...result.canvasObjects];
            newObjects = [...newFrames, ...newObjects.filter(obj => obj.type !== OBJECT_TYPES.FRAME)];

            dispatch({
                type: 'APPLY_LOADED_OBJECTS_STATE',
                payload: newObjects,
            });

            setSelectedCheckboxes([]);
            setIsLoading(false);
        }, undefined, () => setIsLoading(false));
    };




    const getFrameDimensions = (frame, screen) => {
        const frameWidth = frame.width
        const frameHeight = frame.height
        const screenWidth = screen.width
        const screenHeight = screen.height
        const frameAspectRatio = frameWidth / frameHeight
        const screenAspectRatio = screenWidth / screenHeight
        // screen width > height
        if (screenAspectRatio > 1) {
            if (frameAspectRatio > 1) {
                if ((screenHeight / frameHeight) * frameWidth > screenWidth) {
                    return { width: screenWidth, height: (screenWidth / frameWidth) * frameHeight }
                } else {
                    return { width: (screenHeight / frameHeight) * frameWidth, height: screenHeight }
                }
            } else if (frameAspectRatio < 1) {
                return { width: frameHeight / screenHeight * frameWidth, height: screenHeight }
            } else {
                return { width: frameHeight / screenHeight * frameWidth, height: screenHeight }
            }
            // screen height > width
        } else if (screenAspectRatio < 1) {
            if (frameAspectRatio > 1) {
                return { width: screenWidth, height: (screenWidth / frameWidth) * frameHeight }
            } else if (frameAspectRatio < 1) {
                if ((screenWidth / frameWidth) * frameHeight > screenHeight) {
                    return { width: (screenHeight / frameHeight) * frameWidth, height: screenHeight }
                } else {
                    return { width: screenWidth, height: (screenWidth / frameWidth) * frameHeight }
                }
            } else {
                return { width: screenWidth, height: (screenWidth / frameWidth) * frameHeight }
            }
            // square screen
        } else {
            if (frameAspectRatio > 1) {
                return { width: screenWidth, height: (screenWidth / frameWidth) * frameHeight }
            } else if (frameAspectRatio < 1) {
                return { width: (screenHeight / frameHeight) * frameWidth, height: screenHeight }
            } else {
                return { width: (screenHeight / frameHeight) * frameWidth, height: screenHeight }
            }
        }
    };

    const constructFrameSlides = (slidesIDs) => {
        // Filter only the frames that have their id included in the passed slidesIDs array
        const frames = objects?.filter(obj => obj.type === OBJECT_TYPES.FRAME && slidesIDs.includes(obj.id));
        const rest = objects?.filter(obj => obj.type !== OBJECT_TYPES.FRAME);

        return frames?.map(frame => {
            const slideObjects = [];
            rest.forEach(obj => {
                if (isIntersecting(frame, obj) && SUPPORTED_EXPORT_TYPES.includes(obj.type)) {
                    if (obj.type === OBJECT_TYPES.ARROW) {
                        slideObjects.push({
                            ...obj,
                            slideStartInitialPosition: {
                                x: obj.startInitialPosition.x - frame.x,
                                y: obj.startInitialPosition.y - frame.y
                            },
                            slideEndInitialPosition: {
                                x: obj.endInitialPosition.x - frame.x,
                                y: obj.endInitialPosition.y - frame.y
                            }
                        });
                    } else {
                        slideObjects.push({
                            ...obj,
                            slideX: obj.x - frame.x,
                            slideY: obj.y - frame.y
                        });
                    }
                }
            });

            // Get frame dimensions
            const frameDimensions = getFrameDimensions(frame, window.screen);

            return { frame, slideObjects, ...frameDimensions };
        })
            .sort((a, b) => a.frame.presentationIndex - b.frame.presentationIndex)  // Sort based on presentation index
            .filter(slide => !slide.frame.hidden);  // Filter out hidden frames
    };


    const generateThumbnail = (slideInfo, index, abortController) => {
        console.log("generateThumbnail called!");
        if (cancel) return;  // Prevent any further API calls if cancel is triggered

        const slideData = slideInfo.slideObjects[index];

        const action = {
            userID: userID,
            workspaceID: workspaceID,
            type: slideData.type === "SheetletElement" ? "sheetlet" : "chart",
            insightKey:
                slideData.type === "SheetletElement"
                    ? slideData.content?.worksheetID
                    : slideData.type === "InsightElement"
                        ? slideData.content?.insightKey
                        : slideData.id,
            insightID: `${slideData.id}`,
            canvasID: canvasID,
            canvaDesignID: canvasID,
            worksheetID: slideData.type === "SheetletElement" ? slideData.content?.worksheetID : null,
            isSlideScreenshot: true,
            frameID: `${slideInfo.frame.id}`,
            isDev: process.env.REACT_APP_SCOOP_ENV === "dev",
        };

        // Fire and forget: don't await this promise
        postThumbnailGenerator(action, {
            signal: abortController.signal,  // Pass abortController's signal to the fetch request
        }).then(result => {}).catch(error => {
            if (error.name === 'AbortError') {
                console.log("Request canceled");
            } else {
                console.error("Error:", error);
            }
        });
    };

    const handleUploadSlides = async () => {
        const slidesIDs = selectedCheckboxes.map((frameID) => getSlideIDFromFrameID(frameID));

        if (selectedCheckboxes.length === 0) {
            showSnackbar("Please select at least one frame to sync", "warning");
            return;
        }

        const slides = constructFrameSlides(slidesIDs);
        setTotalSlides(slides.length);
        setOpen(true); // Open the modal
        setCancel(false); // Reset cancel flag
        setIsLoading(true);

        const abortController = new AbortController(); // Instantiate AbortController here
        const promises = []; // To track the API calls

        let slideIndex = 0;
        let objectIndex = 0;

        const fireAPI = () => {
            if (cancel) {
                abortController.abort();  // Abort ongoing API calls if cancel is triggered
                return;
            }

            // If all slides and objects are processed, stop
            if (slideIndex >= slides.length) {
                setIsLoading(false);
                showSnackbar("All frames have been uploaded. Please wait a few minutes before opening Google Slides!", "success");
                setOpen(false); // Close the modal when done or canceled
                return;
            }

            const slide = slides[slideIndex];

            // Fire the API call for the current object in the slide
            if (objectIndex < slide.slideObjects.length) {
                setCurrentSlide(slideIndex + 1);
                setSlideProgress(((slideIndex + 1) / slides.length) * 100); // Update slide progress
                setTotalObjects(slide.slideObjects.length);
                setCurrentObject(objectIndex + 1);

                // Fire the API call
                generateThumbnail(slide, objectIndex, abortController); // No need to await this call

                setObjectProgress(((objectIndex + 1) / slide.slideObjects.length) * 100); // Update object progress

                objectIndex++; // Move to the next object
            } else {
                // If all objects for this slide are done, move to the next slide
                slideIndex++;
                objectIndex = 0;
            }
        };

        // Fire the first API call immediately
        fireAPI();

        // Set up an interval to fire the next API calls every 10 seconds
        const intervalId = setInterval(() => {
            // If we've processed all slides and objects, clear the interval
            if (slideIndex >= slides.length) {
                clearInterval(intervalId);  // Stop the interval
                setIsLoading(false);
                showSnackbar("All frames have been uploaded. Please wait a few minutes before opening Google Slides!", "success");
                setOpen(false); // Close the modal when done or canceled
                return;
            }

            // Otherwise, fire the next API call
            fireAPI();
        }, 5000); // Fire every 5 seconds to not overwhelm the browser
    };






    const handleCancel = () => {
        setCancel(true);  // Set the cancel flag to true to stop API calls
        setOpen(false);   // Close the modal
        setIsLoading(false)
        showSnackbar("Slide export canceled", "warning");
    };

    const handleSelectAll = () => {
        if (allSelected) {
            setSelectedCheckboxes([]);
        } else {
            setSelectedCheckboxes(presentationFrames.map(frame => frame.id));
        }
    }

    const isIntersecting = (frame, child) => {
        if (child.type === OBJECT_TYPES.ARROW) {
            const minX = Math.min(child.startInitialPosition.x, child.endInitialPosition.x);
            const minY = Math.min(child.startInitialPosition.y, child.endInitialPosition.y);
            const maxX = Math.max(child.startInitialPosition.x, child.endInitialPosition.x);
            const maxY = Math.max(child.startInitialPosition.y, child.endInitialPosition.y);
            const arrowAABB = { x: minX, y: minY, width: maxX - minX, height: maxY - minY };
            const horizontalOverlap = Math.max(0, Math.min(arrowAABB.x + arrowAABB.width, frame.x + frame.width) - Math.max(arrowAABB.x, frame.x));
            const verticalOverlap = Math.max(0, Math.min(arrowAABB.y + arrowAABB.height, frame.y + frame.height) - Math.max(arrowAABB.y, frame.y));
            return horizontalOverlap > 0 && verticalOverlap > 0;
        } else {
            const horizontalOverlap = Math.max(0, Math.min(child.x + child.width, frame.x + frame.width) - Math.max(child.x, frame.x));
            const verticalOverlap = Math.max(0, Math.min(child.y + child.height, frame.y + frame.height) - Math.max(child.y, frame.y));
            return horizontalOverlap > 0 && verticalOverlap > 0;
        }
    };

    const handleConfirmReorder = () => {
        dispatch(setShowFrameDrawer(false))
        setReordering(true)
        const orderedFrames = [...presentationFrames]
        const frames = objects.filter(obj => obj.type === OBJECT_TYPES.FRAME)
        const positions = frames.map(frame => ({ x: frame.x, y: frame.y })).sort((a, b) => a.y - b.y || a.x - b.x)
        const importedObjects = objects.filter(obj => obj.type !== OBJECT_TYPES.FRAME && typeof obj.id === 'string')
        const scoopObjects = objects.filter(obj => obj.type !== OBJECT_TYPES.FRAME && typeof obj.id !== 'string')
        let resultObjects = []
        // update all objects positions
        orderedFrames.forEach((frame, i) => {
            // update all imported objects positions
            const oldFramePosition = { x: frame.x, y: frame.y }
            const newFramePosition = { x: positions[i].x, y: positions[i].y }
            if (frame.id % 1 === 0) {
                const importedFrameObjects = importedObjects.filter(obj => parseInt(obj.id.split(':')[0]) === frame.id)
                const scoopFrameObjects = scoopObjects.filter(obj => isIntersecting(frame, obj))
                if (!isEqual(oldFramePosition, newFramePosition)) {
                    // update imported frame shapes
                    importedFrameObjects.forEach(o => {
                        if (!resultObjects.some(i => i.id === o.id)) {
                            const objOffsetX = o.x - oldFramePosition.x
                            const objOffsetY = o.y - oldFramePosition.y
                            resultObjects = [...resultObjects, {
                                ...o,
                                x: newFramePosition.x + objOffsetX,
                                y: newFramePosition.y + objOffsetY
                            }]
                        }
                    })
                    // update scoop objects
                    scoopFrameObjects.forEach(o => {
                        if (!resultObjects.some(i => i.id === o.id)) {
                            const objOffsetX = o.x - oldFramePosition.x
                            const objOffsetY = o.y - oldFramePosition.y
                            resultObjects = [...resultObjects, {
                                ...o,
                                x: newFramePosition.x + objOffsetX,
                                y: newFramePosition.y + objOffsetY
                            }]
                        }
                    })
                } else {
                    resultObjects = [...resultObjects, ...importedFrameObjects, ...scoopFrameObjects]
                }
            } else {
                const importedFrameObjects = importedObjects.filter(obj => isIntersecting(frame, obj))
                const scoopFrameObjects = scoopObjects.filter(obj => isIntersecting(frame, obj))
                if (!isEqual(oldFramePosition, newFramePosition)) {
                    // update imported frame shapes
                    importedFrameObjects.forEach(o => {
                        if (!resultObjects.some(i => i.id === o.id)) {
                            const objOffsetX = o.x - oldFramePosition.x
                            const objOffsetY = o.y - oldFramePosition.y
                            resultObjects = [...resultObjects, {
                                ...o,
                                x: newFramePosition.x + objOffsetX,
                                y: newFramePosition.y + objOffsetY
                            }]
                        }
                    })
                    // update scoop objects
                    scoopFrameObjects.forEach(o => {
                        if (!isEqual(oldFramePosition, newFramePosition)) {
                            const objOffsetX = o.x - oldFramePosition.x
                            const objOffsetY = o.y - oldFramePosition.y
                            resultObjects = [...resultObjects, {
                                ...o,
                                x: newFramePosition.x + objOffsetX,
                                y: newFramePosition.y + objOffsetY
                            }]
                        }
                    })
                } else {
                    resultObjects = [...resultObjects, ...importedFrameObjects, ...scoopFrameObjects]
                }
            }
            frame.x = newFramePosition.x
            frame.y = newFramePosition.y
        })
        //Add objects that are not in any frame
        const objectsNotInFrames = scoopObjects.filter(obj => !orderedFrames.some(frame => isIntersecting(frame, obj)))

        dispatch({
            type: APPLY_LOADED_OBJECTS_STATE,
            payload: [...presentationFrames, ...resultObjects, ...objectsNotInFrames]
        });
        setTimeout(() => setReordering(false), 1000)
    }

    const handleCancelReorder = () => {
        setPresentationFrames([...originalPresentationFrames])
    }

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') return;
        setOpenSnackbar(false);
    };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setOpenSnackbar(true);
    };

    const actions = (
        <Box display="flex" justifyContent="flex-end" width="100% " gap="8px">
            <Button onClick={handleCancel} className={'primary-button'} text={'Cancel'}>
            </Button>
        </Box>
    )


    return (
        <>
            <Dialog
                open={open}
                onClose={handleCancel}
                title="Uploading to Google Slides"
                actions={actions}
                disableBackdropClick={true}
                disableEscapeKeyDown={true}
                fullWidth
                style={{ cursor: 'wait' }}
            >
                <Typography>Slide {currentSlide} of {totalSlides}</Typography>
                <LinearProgress variant="determinate" value={slideProgress} style={{ marginTop: '10px' }} />
                <Typography sx={{ mt: 2 }}>Object {currentObject} of {totalObjects}</Typography>
                <LinearProgress variant="buffer" value={objectProgress} valueBuffer={objectProgress + 1} style={{ marginTop: '10px' }} />
                <ScoopLoader />
            </Dialog>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity} variant="filled">
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            {
                isLoading ?
                    <ScoopLoader /> :
                    <Typography className={'inter'}>Create</Typography>
            }
            <Drawer
                anchor={"right"}
                open={showFrameDrawer}
                variant={"persistent"}
                PaperProps={{ sx: { marginTop: '115px' } }}
            >
                <Box className={'frame-drawer-content'}>
                    <Box className={'frame-drawer-header'}>
                        <Typography className={'inter'} sx={{ fontSize: '20px', fontWeight: 600 }}>Frames</Typography>
                        <IconButton onClick={handleClose}>
                            <img src={CloseIcon} alt={'close'} />
                        </IconButton>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 0 }}>
                        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                            <Typography className={'inter'} sx={{ fontSize: '16px', fontWeight: 600 }}>
                                Sync from Slides
                            </Typography>
                            <Tooltip title="Sync changes in Google Slides back to Scoop">
                                <IconButton onClick={() => handleSyncSlides()}>
                                    <img src={RefreshIcon} alt={'refresh'} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                        <Box sx={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
                            <Typography className={'inter'} sx={{ fontSize: '16px', fontWeight: 600 }}>
                                Sync to Slides
                            </Typography>
                            <Tooltip title="Sync changes back to Google Slides">
                                <IconButton onClick={() => handleUploadSlides()}>
                                    <img src={CloudArrowUp} alt={'refresh'} />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box sx={{ display: 'flex', gap: '8px' }}>
                        <Typography className={'inter'} sx={{ fontSize: '12px', fontWeight: 600 }}>
                            Select All
                        </Typography>
                        <Switch
                            checked={allSelected}
                            onClick={handleSelectAll}
                        />
                    </Box>
                    <Box className={'frame-drawer-body'}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <StrictModeDroppable droppableId={"droppable"}>
                                {(provided, snapshot) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {presentationFrames.map((frame, index) => (
                                            <Draggable key={frame.id} draggableId={'draggable-' + frame.id} index={index}>
                                                {(provided, snapshot) => (
                                                    <Box
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        className={'frame-draggable'}
                                                        sx={{ boxShadow: snapshot.isDragging ? '0px 0px 7px 0px rgba(20, 9, 42, 0.25)' : '' }}
                                                    >
                                                        <img src={DotsSixVerticalGrey} alt={'drag'}
                                                            style={{ marginRight: '10px' }} />
                                                        <Box sx={{ flex: 1 }}>
                                                            <Typography className={'inter'} sx={{
                                                                width: 'auto',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap'
                                                            }}>
                                                                {(frame.presentationIndex !== undefined ? frame.presentationIndex + 1 + '. ' : '') + frame.title}
                                                            </Typography>
                                                        </Box>
                                                        {
                                                            isLoading && selectedCheckboxes.includes(frame.id) &&
                                                            <ScoopLoader color="#7442D7" size={36} />
                                                        }
                                                        {
                                                            presentationID && isLoading !== frame.id &&
                                                            <>
                                                                <Tooltip title="Edit original PowerPoint in Google Slides">
                                                                    <IconButton onClick={() => handleOpenSlides(frame.presentationIndex + 1)}>
                                                                        <img src={OpenInSlidesIcon} alt={'open in slides'} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Tooltip title="Hide / Un-hide this frame in Presentation Mode">
                                                                    <IconButton
                                                                        onClick={() => dispatch(updateFrameHidden(frame.id, !frame.hidden))}>
                                                                        <img src={frame.hidden ? EyeClosed : Eye}
                                                                            alt={'toggle visibility'} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                <Checkbox
                                                                    padding={'6px'}
                                                                    size={'small'}
                                                                    checked={selectedCheckboxes.includes(frame.id)}
                                                                    onClick={() => {
                                                                        if (selectedCheckboxes.includes(frame.id)) {
                                                                            setSelectedCheckboxes(selectedCheckboxes.filter((item) => item !== frame.id));
                                                                        } else {
                                                                            setSelectedCheckboxes([...selectedCheckboxes, frame.id]);
                                                                        }
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                    </Box>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </StrictModeDroppable>
                        </DragDropContext>
                    </Box>
                    {
                        !isEqual(presentationFrames, originalPresentationFrames) &&
                        <Box className={'frame-drawer-footer'}>
                            <Button className={'button-purple frame-drawer-button'} onClick={handleConfirmReorder}>
                                <Typography className={'inter'}>Apply</Typography>
                            </Button>
                            <Button onClick={handleCancelReorder} className={'button-grey frame-drawer-button'}>Cancel</Button>
                        </Box>
                    }
                </Box>
            </Drawer>
        </>
    )
}
