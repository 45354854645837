import {
    Box,
    Typography
} from "@mui/material";
import Button from "../../../common/Button/Button";
import React, {useEffect, useMemo, useState} from "react";
import {Server} from "../../../../api/Server";
import {useSelector} from "react-redux";
import {ServerContext} from "../../../Source/SheetState";
import {renderToString} from "react-dom/server";
import googleSheetsIcon from "../../../../assets/icons/GoogleSheets.svg";
import Sheetlet from "../../../Source/Sheetlet";
import BlendingModal from "../NewDatasetModal/Blending/BlendingModal";
import {useWorkspaceMetadata} from "../../../../hooks/useGetWorkspaceMetadata";
import {Switch} from "../../../common/Switch/Switch";

const EditCalculatedSource = ({
                                  inboxID,
                                  singleQuery,
                                  setSingleQuery
                              }) => {

    const workspaceID = useSelector((state) => state.auth.workspaceID);
    const userID = useSelector((state) => state.auth.userID);
    const token = useSelector((state) => state.auth.token);

    const {metadata} = useWorkspaceMetadata(userID, workspaceID, token);

    const [queries, setQueries] = useState([])
    const [worksheetID, setWorksheetID] = useState(null)
    const [isEditingQuery, setIsEditingQuery] = useState(false)
    const [save, setSave] = useState(false)
    const [errorQueries, setErrorQueries] = useState(null)
    const [errorSheetlet, setErrorSheetlet] = useState(false)
    const [indexEditingQuery, setIndexEditingQuery] = useState(null)
    const [isAdvanced, setIsAdvanced] = useState(false)
    const serverContext = new ServerContext(new Server(workspaceID, userID, token));
    const [triggerGetSheetCall, setTriggerGetSheetCall] = useState(false)
    const [sheetRange, setSheetRange] = useState({
        "sheetType": "CalculatedSource",
        "inboxID": inboxID,
        "advanced": isAdvanced,
    })

    useEffect(() => {
        setSheetRange({
            "sheetType": "CalculatedSource",
            "inboxID": inboxID,
            "advanced": isAdvanced,
        })
    }, [isAdvanced]);

    useEffect(() => {
        getOutputDefinition();
    }, [save]);

    const getOutputDefinition = async () => {
        try {
            await serverContext.server.sheetPostData({
                "action": "getCSWDefinition",
                "inboxID": inboxID,
            }, processOutputDefinition);
        } catch (e) {
            console.error(e)
        }
    }

    const processOutputDefinition = (results) => {
        setErrorSheetlet(false)
        setTriggerGetSheetCall(prev => !prev);
        if (results.queries.length === 0) setErrorQueries('No data available')
        setSingleQuery(results.queries.length === 1)
        setQueries(results.queries)
        setWorksheetID(results.worksheetID)
    }

    const handleSaveSheetlet = async () => {
        try {
            await serverContext.server.sheetPostData({
                "action": "flushWorksheetCache",
                "sheetRange": {
                    "inboxID": inboxID
                }
            });
        } catch (e) {
            console.error(e)
        }
    }

    const renderSheetlet = useMemo(() => {
        return (
            <Sheetlet
                key={triggerGetSheetCall}
                isBlending={true}
                serverContext={serverContext}
                sheetRange={sheetRange}
                locked={!isAdvanced}
                embeddedSizeProps={{height: 'auto'}}
                controlledHeight={true}
                cornerHTML={
                    '<a style="position:relative; z-index: 0 !important;" href="https://docs.google.com/spreadsheets/d/' + worksheetID + '" target="_blank">' +
                    renderToString(<img alt={'Google sheets'} src={googleSheetsIcon}/>) +
                    '</a>'
                }
                addNew={getOutputDefinition}
                setError={setErrorSheetlet}
            />
        )
    }, [worksheetID, save, sheetRange, queries]);

    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', height: '100%', width: '100%'}}>
            <Box sx={{minWidth: '100%'}}>
                <Box sx={{
                    padding: '8px',
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '16px'
                }}>
                    {!errorQueries &&
                        <Box sx={{width: `${singleQuery ? '50%' : '100%'}`, display: 'flex', gap: '16px'}}>
                            {queries.length > 0 && (
                                <>
                                    <Query
                                        handleEdit={() => {
                                            setIndexEditingQuery(0);
                                            setIsEditingQuery(true);
                                        }}
                                        query={queries[0]}
                                        index={1}
                                    />
                                    {!singleQuery && (
                                        <Query
                                            handleEdit={() => {
                                                setIndexEditingQuery(1);
                                                setIsEditingQuery(true);
                                            }}
                                            query={queries[1]}
                                            index={2}
                                        />
                                    )}
                                </>
                            )}
                        </Box>
                    }
                    {errorQueries &&
                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            width: '100%',
                            height: '100%'
                        }}>
                            <Typography sx={{color: 'text.secondary'}}>{errorQueries}</Typography>
                        </Box>
                    }
                    <Box>
                        {!errorSheetlet && !errorQueries ?
                            <Box sx={{display: 'flex', flexDirection: 'column', gap: '8px'}}>
                                <Box sx={{display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                    <Box sx={{display: 'flex', gap: '8px', alignItems: 'center'}}>
                                        <Typography sx={{color: 'text.secondary'}}>
                                            Advanced
                                        </Typography>
                                        <Switch
                                            checked={isAdvanced}
                                            onClick={() => setIsAdvanced(!isAdvanced)}
                                        />
                                    </Box>
                                    <Button
                                        sx={{alignSelf: 'flex-end'}}
                                        className={'button-purple small'}
                                        onClick={handleSaveSheetlet}
                                    >
                                        Save Blend
                                    </Button>
                                </Box>
                                {renderSheetlet}
                            </Box>
                            :
                            <Box>
                                <Typography sx={{color: 'text.secondary'}}>
                                    {errorSheetlet}
                                </Typography>
                            </Box>
                        }
                    </Box>
                </Box>
            </Box>
            {isEditingQuery && metadata &&
                <BlendingModal
                    metadata={metadata}
                    queryName={queries[indexEditingQuery]?.sheetName}
                    index={indexEditingQuery}
                    setStep={() => {
                    }}
                    goForward={() => {
                        setIsEditingQuery(false)
                        setSave(!save)
                    }}
                    isEditing={true}
                    isBlending={!singleQuery}
                    inboxID={inboxID}
                    worksheetID={worksheetID}
                    onClose={() => setIsEditingQuery(false)}
                />
            }
        </Box>
    )
}

export default EditCalculatedSource;

export const Query = ({query, handleEdit, index}) => {

    const columns = query?.columns.map(col => col.columnName).join(', ');

    return (
        <>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                gap: '16px',
                p: 3,
                boxShadow: '0 8px 8px 0 rgba(0,0,0,0.1), 2px 0 4px 0 rgba(0,0,0,0.1)',
                borderRadius: '5px',
            }}>
                <Box sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}>
                    <Typography sx={{
                        fontSize: '18px',
                        fontHeight: 30,
                        color: 'text.secondary'
                    }}>
                        {query ? query.sheetName : `Query ${index}`}
                    </Typography>
                    <Button
                        onClick={handleEdit}
                        className={'button-purple small'}
                    >
                        Edit
                    </Button>
                </Box>
                <Box>
                    <Typography sx={{fontHeight: 20, mb: 2, color: 'text.secondary'}}>
                        <span style={{fontWeight: 'bold'}}>Dataset: </span>{query ? query.inbox : 'N/A'}
                    </Typography>
                    <Typography sx={{fontHeight: 20, mb: 2, color: 'text.secondary'}}>
                        <span style={{fontWeight: 'bold'}}>Columns:</span> {columns ? columns : 'N/A'}
                    </Typography>
                </Box>
            </Box>
        </>
    )
}
